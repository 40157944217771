import { useEffect, useRef, useState, useMemo} from 'react';
import { withRouter } from "react-router-dom";
import './verify_pdf.scss';
import SimpleBar from "simplebar-react"
import axios from "axios";
import moment from 'moment'
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledAlert,
  CardText,
  Media,
  Badge,
  Collapse
} from "reactstrap"
import Header from "../../../components/DefaultLayout/Header/header";
import { FileInput } from "../../../helpers/ui/FileInput";
import { AvForm } from "availity-reactstrap-validation";
import animationData from "../../../assets/lotties/document_scan.json"
import Lottie from "react-lottie";
import { Document, Page } from 'react-pdf';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PerfectScrollbar from "react-perfect-scrollbar"
import VerifyPdfBloc from "./Blocs/VerifyPdfBloc";
import apiResponse from '../../../services/apiResponse';

function VerifyPdf(props)  {
  const verifyPdfBloc = useMemo(() => new VerifyPdfBloc(), []); 
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const hiddenFileInput = useRef(null);
  const [showResult, setShowResult] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isVerify, setIsVerify] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [isVerified, setIsVerified] = useState(false)
  const [showInformation, setShowInformation] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [verifiedDocument, setVerifiedDocument] = useState()
  const [activeCertificateIndex, setActiveCertificateIndex] = useState(0)

  useEffect(() => {
    const subscription = verifyPdfBloc.verifyPdfChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          console.log("success")
          setVerifiedDocument(result.data.response)
          setShowResult(true)
          setIsVerified(result.data.response["conclusion"] == "VALID")
          break;
        case apiResponse.ERROR:
          reset()
          console.log("error")
          break;
        default:
          break;
      }
    });

    return () => {
      subscription.unsubscribe()
    };
  }, []);  

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleUploadFile = event => {
    const file = event.target.files[0];
    setSelectedFile(file)
    convertToBase64(file)
  }

  const convertToBase64 = file => {
    setIsUploading(true)
    if (file) {
      const reader = new FileReader();
      
      reader.onloadend = () => {
        verifyDocument(reader.result.split(',')[1])
      };

      reader.readAsDataURL(file);
    }
  }

  const verifyDocument = base64Data => {
    setIsUploading(false)
    setIsVerify(true)

    verifyPdfBloc.fetchVerify({file: base64Data})

  }

  const reset = () => {
    setIsUploading(false)
    setIsVerify(false)
    setIsVerified(false)
    setShowResult(false)
    setShowInformation(false)
    setSelectedFile(null)
    setActiveIndex(0)
    setActiveCertificateIndex(0)
    setVerifiedDocument(null)
  }
  

  const showSignatures = () => {
    return (
      <Card className="chat-leftsidebar">
        <SimpleBar
          style={{ height: "500px" }}
          data-simplebar
        >
          <div className="p-4 border-top" >
              <div>
                  <h5 className="font-size-16 mb-3"><i className="bx bx-pencil me-1"></i> Tanda tangan</h5>
                  <ul className="list-unstyled chat-list">        
                    {
                      verifiedDocument["signatureInformations"].map((signature, index) => {
                        return (
                            <li key={0} className={0 == activeIndex ? "active" : ""}>
                              <Link
                                  to="#"
                                  onClick={() => {
                                    
                                  }}
                              >
                                  <Media className="d-flex">
                                      <Media className="flex-1 overflow-hidden" body>
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            Tanda Tangan #{index+1}
                                          </h5>
                                      </Media>
                                  </Media>
                              </Link>
                            </li>
                        )
                      }
                    )}                  
                  </ul>
              </div>
          </div>
        </SimpleBar>
      </Card>
      
    )
  }

  const showSignatureDetail = () => {
    return (
      <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
        <Card>
            <div className="px-lg-2">
                <div className="chat-conversation p-3">
                    <PerfectScrollbar style={{ height: "500px" }} className="list-unstyled mb-0" >
                      <h5 className="font-size-16 mb-3">Tanda tangan #{activeIndex+1}</h5>
                      <Card>
                          <div className="px-lg-2">
                              <div className="chat-conversation p-3">
                                <h5 className="font-size-16 mb-3">Status Tanda Tangan</h5>
                                <Row className="mb-3">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-4 col-form-label"
                                  >
                                    Ditandatangani oleh
                                    <div className='float-end'>
                                      :
                                    </div>
                                  </label>
                                  <div className="col-md-8 col-form-label">
                                    {verifiedDocument["signatureInformations"][activeIndex]["signerName"]}
                                  </div>
                                </Row>
                                <Row className="mb-3">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-4 col-form-label"
                                  >
                                    Lokasi
                                    <div className='float-end'>
                                      :
                                    </div>
                                  </label>
                                  <div className="col-md-8 col-form-label">
                                    {verifiedDocument["signatureInformations"][activeIndex]["location"]}
                                  </div>
                                </Row>
                                <Row className="mb-3">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-4 col-form-label"
                                  >
                                    Alasan
                                    <div className='float-end'>
                                      :
                                    </div>
                                  </label>
                                  <div className="col-md-8 col-form-label">
                                    {verifiedDocument["signatureInformations"][activeIndex]["reason"]}
                                  </div>
                                </Row>
                                <Row className="mb-3">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-4 col-form-label"
                                  >
                                    Ditandatangani pada
                                    <div className='float-end'>
                                      :
                                    </div>
                                  </label>
                                  <div className="col-md-8 col-form-label">
                                    {moment(verifiedDocument["signatureInformations"][activeIndex]["signatureDate"]).format('DD-MM-YYYY hh:mm')}
                                  </div>
                                </Row>
                                <Row className="mb-3">
                                  <label
                                    htmlFor="example-text-input"
                                    className="col-md-4 col-form-label"
                                  >
                                    Stempel waktu
                                    <div className='float-end'>
                                      :
                                    </div>
                                  </label>
                                  <div className="col-md-8 col-form-label">
                                    {moment(verifiedDocument["signatureInformations"][activeIndex]["timestampInfomation"]["timestampDate"]).format('DD-MM-YYYY hh:mm')}
                                    <br/>
                                      <Badge className="bg-primary">
                                        {verifiedDocument["signatureInformations"][activeIndex]["timestampInfomation"]["id"]}
                                      </Badge>{" "}
                                      <Badge className="bg-primary">
                                        {verifiedDocument["signatureInformations"][activeIndex]["timestampInfomation"]["signerName"]}
                                      </Badge>{" "}
                                  </div>
                                </Row>
                                <Card>
                                  <div className="px-lg-2">
                                      <div className="chat-conversation p-3">
                                        <h5 className="font-size-16 mb-3">Sertifikat</h5>
                                        <div className="accordion" id="accordionExample">
                                          {verifiedDocument["signatureInformations"][activeIndex]["certificateDetails"].map((certificate, cIndex) => {
                                            return (
                                              <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                  <button className="accordion-button" type="button"
                                                    onClick={() => {
                                                      setActiveCertificateIndex(cIndex)
                                                    }}
                                                    style={{ cursor: "pointer" }}>
                                                    Sertifikat #{cIndex + 1}
                                                  </button>
                                                </h2>
                                                <Collapse id="collapseOne" className={activeCertificateIndex == cIndex ? "accordion-collapse show" : "accordion-collapse"}
                                                  isOpen={cIndex == activeCertificateIndex}>
                                                  <div className="accordion-body">
                                                    <Row className="mb-3">
                                                      <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                      >
                                                        Common Name
                                                        <div className='float-end'>
                                                          :
                                                        </div>
                                                      </label>
                                                      <div className="col-md-8 col-form-label">
                                                        {certificate["commonName"]}
                                                      </div>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                      >
                                                        Issuer
                                                        <div className='float-end'>
                                                          :
                                                        </div>
                                                      </label>
                                                      <div className="col-md-8 col-form-label">
                                                        {certificate["issuerName"]}
                                                      </div>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                      >
                                                        Serial
                                                        <div className='float-end'>
                                                          :
                                                        </div>
                                                      </label>
                                                      <div className="col-md-8 col-form-label">
                                                        {certificate["serialNumber"]}
                                                      </div>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                      >
                                                        Algoritma
                                                        <div className='float-end'>
                                                          :
                                                        </div>
                                                      </label>
                                                      <div className="col-md-8 col-form-label">
                                                        {certificate["signatureAlgoritm"]}
                                                      </div>
                                                    </Row>
                                                    <Row className="mb-3">
                                                      <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                      >
                                                        Validiti
                                                        <div className='float-end'>
                                                          :
                                                        </div>
                                                      </label>
                                                      <div className="col-md-8 col-form-label">
                                                        {moment(certificate["notBeforeDate"]).format('DD-MM-YYYY hh:mm')} - {moment(certificate["notAfterDate"]).format('DD-MM-YYYY hh:mm')}
                                                      </div>
                                                    </Row>
                                                  </div>
                                                </Collapse>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                  </div>
                              </Card>
                              </div>
                          </div>
                      </Card>
                    </PerfectScrollbar>
                </div>
            </div>
        </Card>
      </div>
    )
  }

  return (
    <>
      <div
        className="main"
        style={{
          background: "white",
          position: "relative",
          minHeight: "100vh",
        }}
      >
       
        <Header/>
        

        <div className="container" style={{ padding: "9rem 2rem 6rem 2rem", zIndex: "99999" }}>
          {showResult == false ? (
            <div style={{ border: "1px dashed #D3D3D3", borderRadius: "10px", padding: "4rem 2rem 4rem 2rem", background: "white" }}>
              <input
                type="file"
                onChange={handleUploadFile}
                ref={hiddenFileInput}
                accept='.pdf'
                style={{display: 'none'}} // Make the file input element invisible
              />
              <Row className="centered">
                <Col md="auto">
                  <Lottie options={{ 
                      loop: true,
                      autoplay: true, 
                      animationData: animationData
                    }}
                      isClickToPauseDisabled={true}
                      height="300px"
                      width="300px"
                      isStopped={false}
                      isPaused={false}/>
                  
                </Col>
                <Col>
                  <h1 style={{ fontWeight: "bold" }}>Verify PDF</h1>
                  <h3>Verifikasi dokumen PDF anda!</h3>
                  <button onClick={handleClick} type="button" className="btn btn-primary p-3 col-12" disabled={ isUploading || isVerify}>
                    <h2 style={{ color: "white" }}>
                      {isUploading ? "Mengunggah..." : isVerify ? "Verifikasi..." : (
                        <>
                          <span className="uil uil-cloud-upload"></span> Unggah Dokumen PDF
                        </>
                      )}
                    </h2>
                  </button>
                </Col>
              </Row>
            </div>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2>Informasi Dokumen</h2>
                <button onClick={reset} type="button" className="btn btn-danger">
                  <Icon icon="icomoon-free:cross" width={10} style={{ color: "white" }} />
                </button>
              </div>
              <hr/>
              {isVerified == true && (
                <UncontrolledAlert
                  color="secondary"
                  className="alert-border alert-border-success alert-dismissible fade show"
                  role="alert"
                  toggle={false}
                >
                  <i className="uil uil-check font-size-16 text-success me-2"></i> Dokumen ini memiliki tanda tangan digital <button onClick={() => {setShowInformation(true)}} type="button" className="btn btn-warning">Tampilkan</button>
                </UncontrolledAlert>
              )}
              {isVerified == false && (
                <UncontrolledAlert
                  color="secondary"
                  className="alert-border alert-border-danger alert-dismissible fade show"
                  role="alert"
                  toggle={false}
                >
                  <i className="uil uil-exclamation-octagon font-size-16 text-danger me-2"></i> Dokumen ini tidak memiliki tanda tangan digital
                </UncontrolledAlert>
              )}
              <div style={{ position: 'relative', width:'100%', height:'100%' }}>
                {!showInformation ? (
                  <Worker>
                      <div style={{height: "92vh"}}>
                          <Viewer fileUrl={URL.createObjectURL(selectedFile)}/>
                      </div>
                  </Worker>
                ) : (
                  <div className="d-lg-flex">
                    {showSignatures()}
                    {showSignatureDetail()}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      
    </>
  );
}

export default withRouter(VerifyPdf);

import React from "react";
import FollowLinks from "../FollowLinks";
import { Row, Col } from "reactstrap";
import bsreLogo from "../../../../../assets/images/bsre.png"

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer
          style={{ borderTop: "2px solid var(--The-Blue, #3E70D1)" }}
        >
          <div
            className="footer-bottom pt-4 pb-4"
            style={{
              background: "var(--The-Blue, #3E70D1)",
              border: "2x",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: 20, paddingRight: 20
            }}
          >
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col">
                  <p className="copyright-text pb-0 mb-0" style={{ color: "white" }}>
                    Copyrights © 2024.
                    <a href="/#" style={{ color: "white", fontWeight: 600 }}>
                      {" "}
                      KEMENTERIAN INVESTASI DAN HILIRISASI/BKPM
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <img src={bsreLogo} width={100} height={50} />
          </div>
        </footer>

        {/* <footer>
          <div
            className="footer-bottom pt-4 pb-4"
            style={{
              background: "white",
              border: "2x",
            }}
          >
            <div className="container">
              <div className="row text-center">
                <div
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <p
                    className="copyright-text pb-0 mb-0"
                    style={{ color: "#AAA" }}
                  >
                    Copyrights © 2022. All rights reserved by
                    <a
                      href="/#"
                      style={{ color: "#243C92", fontWeight: 600 }}
                    >
                      {" "}
                      MINISTRY OF INVESMENT/BKPM
                    </a>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </footer> */}
      </React.Fragment>
    );
  }
}

export default Footer;

import axios from "axios";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Button, Row, Col, Label, Input } from "reactstrap"
import {
  AvForm
} from "availity-reactstrap-validation";
import {
  FileInput,
  generateFormData
} from "../../../../helpers/ui/FileInput";
import Select from "react-select"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import listenGif from "../../../../assets/videos/listening.gif"
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { Link, Redirect } from "react-router-dom";
import reviewIcon from '../../../../assets/icons/icon_review_gedung.png';
import Lottie from "react-lottie";
import animationData from "../../../../assets/lotties/nlp.json"

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function BookingRoomView(props) {

  const baseUrl = process.env.REACT_APP_INVENTORY_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_INVENTORY_SERVICE_BASE_APPCODE;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState([])
  const [rooms, setRooms] = useState([])
  const [leaders, setleaders] = useState([])
  const [currentAccessToken, setCurrentAccessToken] = useState()
  const questions = ["Silakan pilih gedung yang akan dipakai", "Silakan pilih lantai", "Silakan pilih ruang rapat", "Kapan tanggal dimulainya?", "Berapa jumlah pesertanya?", "Siapa peserta rapatnya?", "Siapa pemimpin rapatnya?", "Apa agenda rapatnya?", "Ada konsumsi?", "Silakan upload undangan rapat"];
  const questionTotal = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [availibility, setAvailibility] = useState(true)
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()

  const [selectedUploadFile, setSelectedUploadFile] = useState({ label: "Manual Upload", value: "1" },)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedBuildingUuid, setSelectedBuildingUuid] = useState(null)
  const [selectedFloorUuid, setSelectedFloorUuid] = useState(null)
  const [selectedRoomUuid, setSelectedRoomUuid] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [agenda, setAgenda] = useState(null)
  const [selectedIsWithConsumption, setSelectedIsWithConsumption] = useState(null)
  const [totalParticipant, setTotalParticipant] = useState(null)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [selectedLeader, setSelectedLeader] = useState(null)
  const [letterNumber, setLetterNumber] = useState(null)

  const [confirm, setConfirm] = useState()
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [failed, setFaield] = useState()
  const [redirect, setRedirect] = useState()

  const optionGroup = [
    { label: "Manual Upload", value: "1" },
    { label: "Upload Persuratan", value: "2" },
  ]

  const listOptPemakaian = [
    { label: "(08:00 sd 10:00)", value: "4" },
    { label: "(10:00 sd 12:00)", value: "5" },
    { label: "(12:00 sd 14:00)", value: "6" },
    { label: "(14:00 sd 16:00)", value: "7" },
    { label: "Half day sesi 1 (07:00 sd 12:00)", value: "1" },
    { label: "Half day sesi 2 (12:00 sd 18:00)", value: "2" },
    { label: "Full day (07:00 sd 18:00)", value: "3" }
  ];

  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const startListen = () => {
    SpeechRecognition.startListening({ language: 'id'})
  }

  useEffect(() => {
    // Function to fetch data using Axios

    const fetchAccessToken = () => {
      try {
        axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCode,
            email: userData.employee_email
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentAccessToken(accessToken)
          fetchBuilding(accessToken)
          fetchLeader(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchBuilding = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}prosespeminjaman/ruangrapat/listmastermbuilding/json`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });
        setBuildings(response.data.response.result)
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchLeader = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}prosespeminjaman/ruangrapat/listmasteremployee/json`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });  
        if (response.data.status == true) {
          setleaders(response.data.response.result.map((leader) => {
            return {
              label: leader.empl_with_position,
              value: leader.sync_employee_uuid,
            };
          }),)  
        } else {
          setleaders([]);
        }
        
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchAccessToken()
  }, []); 

  const handleBuildingClick = async (event) => {
    const buildingUuid = event.target.value;
    setSelectedBuildingUuid(buildingUuid)
    
    try {
      const response = await axios.get(`${baseUrl}prosespeminjaman/ruangrapat/listmastermfloor/json?builduuid=${buildingUuid}` , {
        headers: {
          appCode: appCode,
          email: userData.employee_email,
          accessToken: currentAccessToken
        },
      });
      setFloors(response.data.response.result)
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setCurrentQuestion(currentQuestion + 1)
  };

  const handleFloorClick = async (event) => {
    const floorUuid = event.target.value;
    setSelectedFloorUuid(floorUuid)
    
    try {
      const response = await axios.get(`${baseUrl}prosespeminjaman/ruangrapat/listmastermroom/json?flooruuid=${floorUuid}`, {
        headers: {
          appCode: appCode,
          email: userData.employee_email,
          accessToken: currentAccessToken
        },
      });
      setRooms(response.data.response.result)
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setCurrentQuestion(currentQuestion + 1)
  };

  const handleRoomClick = async (event) => {
    const roomUuid = event.target.value;
    setSelectedRoomUuid(roomUuid)
    setCurrentQuestion(currentQuestion + 1)
  };

  const isAvailable = () => {
    return new Promise(async (resolve, reject) => {
      const response = await axios.get(`${baseUrl}prosespeminjaman/ruangrapat/meetingRoomAvailable/json?mroom_loan_meeting_room_uuid=${selectedRoomUuid}&mroom_loan_meeting_date=${selectedDate}&waktu_pemakaian=${selectedTime.value}`, {
        headers: {
          appCode: appCode,
          email: userData.employee_email,
          accessToken: currentAccessToken
        },
      });
  
      const query = response.data.response.result.query
    
      resolve(query == null)
    })
  }

  const submitData = async () => {
    isAvailable().then(async (available) => {
      if(available) {
        const dataForm = {
          "mroomloan_employee_uuid": userData.employee_uuid,
          "sync_employee_fullname": userData.employee_name,
          "sync_employee_current_position": userData.employee_current_position_name,
          "sync_employee_current_unit_work": userData.employee_current_work_unit_name,
          "mroomloan_contactapplicant": userData.employee_phone_number,
          "mroomloan_building_uuid":selectedBuildingUuid,
          "mroomloan_floor_uuid":selectedFloorUuid,
          "mroomloan_rmeeting_uuid":selectedRoomUuid,
          "mroomloan_dateofuse":selectedDate,
          "waktu_pemakaian":selectedTime.value,
          "mroomloan_agendaname":agenda,
          "mroomloan_opt_consumtion":selectedIsWithConsumption,
          "uploadType": selectedUploadFile.value,
          "typeUploadUndangan": selectedUploadFile.value,
          "mroomloan_total_participant": totalParticipant,
          "mroomloan_meetingleader_uuid": selectedLeader.value,
          "nomorSurat": letterNumber,
          "peserta_rapat_custom": selectedParticipant,
          "surat_undangan[]": selectedFile != null ? selectedFile[0] : null
        }
    
        const formData = generateFormData(dataForm, {
          field: "documents",
          name: "inventory/booking_mroom/attachment",
        });
    
        const response = await axios.post('https://inventory-dev.bkpm.go.id/svc-core/prosespeminjaman/ruangrapat/create/json', formData, {
          headers: {
            appCode: "8eaf57c8-d03f-4932-adf4",
            email: userData.employee_email,
            accessToken: currentAccessToken
          },
        });
    
        if (response.data.status == true) {
          setSuccess(true)
          const url = `https://persuratan-dev.bkpm.go.id/outbox/daftardraft.html?create&autoinput.inv={"mroomloan_uuid":"${selectedRoomUuid}","starHour":"${startTime}","endHour":"${endTime}","date":"${selectedDate}","location":"${rooms.find(item => item.meeting_room_uuid === selectedRoomUuid).mroom_name}","agenda":"${agenda}"}`
          window.open(url, '_blank', 'noreferrer');
          
        } else {
          setFaield(true)
        }
        setLoading(false)
      } else {
        setLoading(false)
        setAvailibility(false)
      }
    })
  }

  useEffect(() => {
    setAgenda(transcript)
  }, [transcript]);

  const confirmResponse = (response) => {
    switch (response) {
      case "cancel":
        setConfirm(false)
        break;
      case "confirm":
        setLoading(true)
        setConfirm(false)        
        submitData();
        break;
      case "success":
        setSuccess(false)
        setRedirect(true)
        break;
      case "failed":
        setFaield(false)
        break;
      default:
    }
  };

  const confirmResponseAvailibility = (response) => {
    setAvailibility(true);
  };

  const showButton = (showNextButton) => {
    return (
      <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", bottom: 0, right: 0, zIndex: 99 }}>
          {currentQuestion > 1 && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              Kembali
            </Button> 
          )}

          {showNextButton == true && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Selanjutnya
            </Button> 
          )}

          {currentQuestion == questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Review
          </Button> 
          )}

          {currentQuestion > questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setConfirm(true)}
          >
            Submit
          </Button> 
          )}
        </div>
    )
  }
  
  const showLottie = () => {
    return <Lottie options={{ 
      loop: true,
      autoplay: true, 
      animationData: animationData
     }}
      isClickToPauseDisabled={true}
      height="100%"
      width="100%"
      isStopped={false}
      isPaused={false}/>
  }

  const handleTime = (value) => {
    console.log(value)
    switch (value) {
      case "1":
        setStartTime("07:00")
        setEndTime("12:00")
        break;
      case "2":
        setStartTime("12:00")
        setEndTime("18:00")
        break;
      case "3":
        setStartTime("07:00")
        setEndTime("18:00")
        break;
      case "4":
        setStartTime("08:00")
        setEndTime("10:00")
        break;
      case "5":
        setStartTime("10:00")
        setEndTime("12:00")
        break;
      case "6":
        setStartTime("12:00")
        setEndTime("14:00")
        break;
      case "7":
        setStartTime("14:00")
        setEndTime("16:00")
        break;
      default:
        break;
    }

    console.log(startTime)
    
  }

  return (
    <>
      {currentQuestion <= questionTotal && (
        <div className="mb-4" style={{ position: "fixed", top: 0, width: "100%" }}>
          <Progress color="info" value={currentQuestion / questionTotal * 100} />
        </div>
      )}
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {currentQuestion <= questionTotal ? (
          <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99 }}>
            Pertanyaan {currentQuestion} dari {questionTotal}
          </div>
        ) : <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
        <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
      </div>}

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px"}} >
        { currentQuestion == 1 && ( 
          <Row className="centered-vertical">

            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[0]}</p>

              {buildings.length > 0 ? buildings.map((building, index) => (
                  <Button
                    key={index}
                    color="primary"
                    value={building.building_uuid}
                    className="waves-effect waves-light"
                    onClick={handleBuildingClick}
                    style={{
                      margin: "5px", 
                      borderRadius: "20px",
                      borderColor: "#3FC057",
                      background: selectedBuildingUuid == building.building_uuid ? "#3FC057" : "rgba(63, 192, 87, 0.05)", 
                      color: selectedBuildingUuid == building.building_uuid ? 'white' : '#3FC057'
                    }}
                  >
                    {building.building_name}
                  </Button>
                )) : <i>Data kosong</i>
              }  
              {showButton(selectedBuildingUuid != null)}
            </div> 
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          )
        }

        { currentQuestion == 2 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[1]}</p>
            
              { floors.length > 0 ? floors.map((floor, index) => (
                  <Button
                    key={index}
                    color="primary"
                    outline
                    className="waves-effect waves-light"
                    value={floor.building_floor_uuid}
                    onClick={handleFloorClick}
                    style={{
                      margin: "5px", 
                      borderRadius: "20px",
                      borderColor: "#3FC057",
                      background: selectedFloorUuid == floor.building_floor_uuid ? "#3FC057" : "rgba(63, 192, 87, 0.05)", 
                      color: selectedFloorUuid == floor.building_floor_uuid ? 'white' : '#3FC057'
                    }}
                  >
                    {floor.building_floor_name}
                  </Button>
                )) : <i>Data kosong</i>
              }  
              {showButton(selectedFloorUuid != null)}
            </div> 
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>)
        }

        { currentQuestion == 3 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[2]}</p>
            
              { rooms.length > 0 ? rooms.map((room, index) => (
                  <Button
                    key={index}
                    color="primary"
                    outline
                    value={room.meeting_room_uuid}
                    className="waves-effect waves-light"
                    onClick={handleRoomClick}
                    style={{
                      margin: "5px", 
                      borderRadius: "20px",
                      borderColor: "#3FC057",
                      background: selectedRoomUuid == room.meeting_room_uuid ? "#3FC057" : "rgba(63, 192, 87, 0.05)", 
                      color: selectedRoomUuid == room.meeting_room_uuid ? 'white' : '#3FC057'
                    }}
                  >
                    {room.mroom_name}
                  </Button>
                )) : <i>Data kosong</i>
              }  
              <div className="nlp-sub-content">{showButton(selectedRoomUuid != null)}</div>
            </div> 
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          ) 
        }

        { currentQuestion == 4 && (
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[3]}</p>
              
              <div>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Tanggal Pemakaian</Label>
                      <input
                        className="form-control nlp-sub-content"
                        type="date"
                        value={selectedDate}
                        onChange={(event) => {
                          setSelectedDate(event.target.value)
                          if (selectedTime != null) {
                            setCurrentQuestion(currentQuestion + 1)
                          }
                        }}
                        id="example-date-input"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Jam Pemakaian</Label>
                      <Select
                        className="nlp-sub-content"
                        onChange={(event) => {
                          setSelectedTime(event)
                          handleTime(event.value)
                          console.log(event.value)
                          if (selectedDate != null) {
                            setCurrentQuestion(currentQuestion + 1)
                          }
                        }}
                        value={selectedTime}
                        options={listOptPemakaian}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              {showButton(selectedDate != null && selectedTime != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 5 && ( 
          <Row className="centered-vertical">

            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[4]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC"  }}>
                <input
                  className="nlp-sub-content"
                  type="number"
                  value={totalParticipant}
                  style={{fontSize: "18px", width: "100%",  border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingLeft: "20px", paddingRight: "20px"}}
                  onChange={(event) => {
                    setTotalParticipant(event.target.value)
                  }}
                />
              </div>
              {showButton(totalParticipant != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
         ) }

        { currentQuestion == 6 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[5]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  className="nlp-sub-content"
                  type="text"
                  value={selectedParticipant}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setSelectedParticipant(event.target.value)
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              {showLottie()}
              {showButton(true)}
            </div>
          </Row>
         ) }

        { currentQuestion == 7 && (
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[6]}</p>
              
              <div>
                <div className="mb-3">
                  <Select
                    className="nlp-sub-content"
                    onChange={(event) => {
                      setSelectedLeader(event)
                      setCurrentQuestion(currentQuestion + 1)
                    }}
                    value={selectedLeader}
                    options={leaders}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>
              {showButton(selectedLeader != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 8 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[7]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  className="nlp-sub-content"
                  type="text"
                  value={agenda}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setAgenda(event.target.value)
                  }}
                />
                <i className="uil-microphone" onClick={startListen} 
                  style={{ 
                    fontSize: '24px',  
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    cursor: 'pointer',}}/>
              </div>
            </div>
            <div className="col-md-6">
              {listening ? (
                <div className="centered-horizontal">
                  <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
                </div>
              ) :
              showLottie()}
              {showButton(agenda != null)}
            </div>
          </Row>
         ) }

        { currentQuestion == 9 && ( 
          <Row className="centered-vertical">

            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[8]}</p>
            
              <Button
                color="primary"
                outline
                className="waves-effect waves-light"
                style={{margin: "5px", paddingLeft: "15px", paddingRight: "15px", background: selectedIsWithConsumption == 1 ? "dodgerblue" : "white", color: selectedIsWithConsumption == 1 ? 'white' : 'dodgerblue'}}
                onClick={() => {
                  setSelectedIsWithConsumption(1) 
                  setCurrentQuestion(currentQuestion + 1)
                }}
              >
                <div className="nlp-sub-content">Ya</div>
              </Button>

              <Button
                color="primary"
                outline
                className="waves-effect waves-light"
                style={{margin: "5px", paddingLeft: "15px", paddingRight: "15px", background: selectedIsWithConsumption == 2 ? "dodgerblue" : "white", color: selectedIsWithConsumption == 2 ? 'white' : 'dodgerblue'}}
                onClick={() => {
                  setSelectedIsWithConsumption(2)
                  setCurrentQuestion(currentQuestion + 1)
                }}
              >
                <div className="nlp-sub-content">Tidak</div>
              </Button>
              {showButton(selectedIsWithConsumption != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
         ) }

        { currentQuestion == 10 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
              <p className="nlp-content">{questions[9]}</p>
{/*             
              <Select
                  onChange={(event) => {
                    setSelectedUploadFile(event)
                  }}
                  options={optionGroup}
                  value={selectedUploadFile}
                  classNamePrefix="select2-selection"
                /> */}

              <div className="form-group" style={{ marginTop: "10px" }}>
                {selectedUploadFile.value == "1" ? (
                  <>
                    <FileInput
                      name="documents"
                      accept=".pdf"
                      isMultiple={false}
                      selectedFiles={selectedFile}
                      onChange={(event) => {
                        setSelectedFile(event) 
                      }}
                    />
                    <p style={{ fontWeight: 500 }} className="text-secondary">
                      Format file yang diijinkan: .pdf
                    </p>
                  </>  
                ): (
                  <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC"  }}>
                    <input
                      className="nlp-sub-content"
                      type="text"
                      placeholder="Contoh 3/B.2/A.10/2023"
                      value={letterNumber}
                      onChange={(event) => {
                        setLetterNumber(event.target.value)
                      }}
                      style={{fontSize: "18px", width: "95%",  border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingLeft: "20px", paddingRight: "20px"}}
                    />
                  </div>
                )}
                
              </div>
              {showButton(false)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
         ) }

        { currentQuestion == questionTotal + 1 && ( 
          <div className="col-md-12" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h5 className="nlp-content">Resume</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>Pengajuan Peminjaman Ruang Rapat</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "5vw" }}>
              <Row className="centered-vertical">
                <Col md={4}>
                  <div style={{ textAlign: "center" }}> 
                    <div className="centered-horizontal" style={{ marginBottom: "10px" }}>
                      <img src={reviewIcon}/>
                      {/* <div style={{ width: "130px", height: "130px", background: "#EFF4F7", borderRadius: "20px",  }}>

                      </div> */}
                    </div>
                    <Label className="form-label centered-horizontal nlp-sub-content" htmlFor="formrow-email-input">{buildings.find(item => item.building_uuid === selectedBuildingUuid).building_name}, {floors.find(item => item.building_floor_uuid === selectedFloorUuid).building_floor_name}</Label>                    
                    <Label className="form-label centered-horizontal nlp-sub-content" htmlFor="formrow-email-input">{rooms.find(item => item.meeting_room_uuid === selectedRoomUuid).mroom_name}</Label>
                  </div>
                </Col>
                <Col md ={8}>
                  <Row>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Waktu Pemakaian</Label>
                        <p className="nlp-sub-content">{selectedDate} <br /> {selectedTime.label}</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Agenda</Label>
                        <p className="nlp-sub-content">{agenda}</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Ada Konsumsi?</Label>
                        <p className="nlp-sub-content">{selectedIsWithConsumption == 1 ? "Ada" : "Tidak"}</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">File Undangan</Label>
                        <p className="nlp-sub-content">{selectedFile != null ? selectedFile[0].name : "-"}</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Total Partisipan</Label>
                        <p className="nlp-sub-content">{totalParticipant}</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Pemimpin Rapat</Label>
                        <p className="nlp-sub-content">{selectedLeader.label}</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Peserta Rapat</Label>
                        <p className="nlp-sub-content">{selectedParticipant ?? "-"}</p>  
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <div> 
                    <div className="centered-horizontal" style={{ marginBottom: "10px" }}>
                      <div style={{ width: "130px", height: "130px", background: "#EFF4F7", borderRadius: "20px",  }}>

                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Label className="form-label" htmlFor="formrow-email-input">Waktu Pemakaian</Label>
                    <p>{selectedDate}</p>  
                    <p>{listOptPemakaian.find(item => item.value === selectedTime).label}</p>  
                  </div>
                </Col>
                <Col>
                  <div>
                    <Label className="form-label" htmlFor="formrow-email-input">Agenda</Label>
                    <p>{agenda}</p>  
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="form-label centered-horizontal" htmlFor="formrow-email-input">{buildings.find(item => item.building_uuid === selectedBuildingUuid).building_name}, {floors.find(item => item.building_floor_uuid === selectedFloorUuid).building_floor_name}</Label>                    
                  <Label className="form-label centered-horizontal" htmlFor="formrow-email-input">{rooms.find(item => item.meeting_room_uuid === selectedRoomUuid).mroom_name}</Label>
                </Col>
                <Col>
                  <div>
                    <Label className="form-label" htmlFor="formrow-email-input">Ada Konsumsi?</Label>
                    <p>{selectedIsWithConsumption == 1 ? "Ada" : "Tidak"}</p>  
                  </div>
                </Col>
                <Col>
                  <div>
                    <Label className="form-label" htmlFor="formrow-email-input">File Undangan</Label>
                    <p>27 September 2023 (13:00 - 15:00)</p>  
                  </div>
                </Col>
              </Row>
                 */}
            </div>
            {showButton(false)}
          </div>
         ) }
        </AvForm>
      </div>
      <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={confirm}
          showLoading={loading}
          showSuccess={success}
          showFailed={failed}
          response={confirmResponse}
        />

        <ConfirmAlert
          failedTitle="Gagal!"
          failedInfo="Waktu rapat yang anda ajukan sudah digunakan, harap gunakan waktu yang lainnya!"
          showFailed={!availibility}
          response={confirmResponseAvailibility}
        />
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default BookingRoomView;

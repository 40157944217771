import { Icon } from "@iconify/react";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import apiResponse from "../../../../../services/apiResponse";
import MinioClient from "../../../../../services/minioClient";
import { cancelToken } from "../../../../../services/adapters/base";
import AnnouncementBloc from "../../Blocs/AnnouncementBloc";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "./style.scss";
import Wysiwyg from "../../../Announcement/Components/Wysiwyg";


class AnnouncementPopup extends Component {

  source;
  announcementBloc = new AnnouncementBloc();
  minio = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {
      announcement: null,
      showModal: true,
    };
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    /* kirim ke server */
    const query = {
    };
    await this.announcementBloc.fetchPopupAnnouncement(query, this.source.token);
  };

  fetchAttachment = async (path) => {
    const attachment = await this.minio.get(path).then(
      (res) => res,
      (err) => err
    );

    this.setState({
      announcement: {
        ...this.state.announcement,
        attachment_uri: attachment,
      },
    });
  };

  componentDidMount() {
    this.setTokenAPI();

    this.announcementBloc.popupChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;

          this.setState({
            announcement: response.result,
            loadingTable: false,
          });

          this.fetchAttachment(response.result.attachment_path);

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.announcementBloc.popupChannel.unsubscribe();
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
    localStorage.setItem('hidePopup', "true");
  };

  isJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }
  
  render() {
    return this.state.announcement && (
      <Modal
        toggle={this.toggleModal}
        isOpen={this.state.showModal}
        centered={true}
        size="lg"
      >
        <ModalBody style={{ padding: "0.15rem" }}>
          <div className="close-btn" onClick={this.toggleModal}>
            <Icon icon="icomoon-free:cross" width={20} />
          </div>
          <div className="text-container content">
            {this.isJSON(this.state.announcement.announcement_content) ? (
                <Wysiwyg
                  readOnly={true}
                  init={JSON.parse(this.state.announcement.announcement_content)}
                />
              ) : (
                <p style={{ border: "none", margin: 0, padding: 0 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.announcement.announcement_content != null
                        ? this.state.announcement.announcement_content
                        : this.state.announcement.announcement_title,
                    }}
                    style={{ border: "none" }}
                  />
                </p>
              )}
          </div>
          <hr />
          {this.state.announcement.attachment_uri ? (
            <a
              className="announcement-attachment"
              style={{
                display: "flex",
                columnGap: "1rem",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontWeight: "500",
                margin: 10
              }}
              href={this.state.announcement.attachment_uri}
            >
              <Icon icon="bx:bxs-file-pdf" color="#CD1818" width={24} />
              <span>{this.state.announcement.attachment_name}</span>
            </a>
          ) : (
            <div
              className="no-attachment-container"
              style={{
                display: "flex",
                columnGap: "0.5rem",
                alignItems: "center",
                margin: 10
              }}
            >
              <Icon
                icon="uit:exclamation-triangle"
                color="#888"
                width={16}
              />
              <span style={{ fontStyle: "italic", color: "#888" }}>
                Tidak ada lampiran
              </span>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default AnnouncementPopup;

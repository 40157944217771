import React, {
  Component,
  useState,
  Fragment,
  useEffect,
  useCallback,
} from "react";
import { Row, Col, Card } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import Dropzone, { useDropzone } from "react-dropzone";
import { isArray } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import MinioClient from "../../services/minioClient";

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const FileInput = (props) => {
  const [selectedFiles, setSelectedFiles] = useState(props.selectedFiles);
  const [isError, setIsError] = useState();
  const [errorAlertMessage, setErrorAlertMessage] = useState(
    "Hanya dapat memilih 1 file."
  );
  const {
    name,
    validate,
    placeholder = "Drop files here or click to upload.",
    errorMessage = "Harap pilih file",
    accept,
    style,
    onChange = null,
    isMultiple = false,
    isAvForm = true,
    defaultValue = null,
    fileName = "documents_name",
    filePath = "documents_path",
    fileMimeType = "documents_mime_type",
    fileSize = "documents_file_size",
    maxFiles = 1,
  } = props;

  const onDrop = useCallback((acceptedFiles) => {
    if (isMultiple) {
      insertMultipleFiles(acceptedFiles);
    } else {
      insertFiles(acceptedFiles);
    }
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleAcceptedFiles = (files) => {
    if (isMultiple) {
      insertMultipleFiles(files);
    } else {
      insertFiles(files);
    }

    if (onChange) {
      onChange(files);
    }
  };

  const insertFiles = (files) => {
    if (files.length > 1) {
      setIsError(true);
    } else {
      let newArray = [];
      if (isArray(selectedFiles)) {
        newArray = selectedFiles.slice();
      }
      let isValid = true;
      const validFiles = files.map((file) => {
        Object.assign(file, {
          id: Math.random().toString(36).substring(2),
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });

        if (isArray(selectedFiles)) {
          newArray.push(file);
        }

        return file.size > 5242880;
      });

      validFiles.forEach((i) => {
        if (i) isValid = false;
      });

      if (isValid) {
        setSelectedFiles(isArray(selectedFiles) ? newArray : files);
      } else {
        setErrorAlertMessage("Ukuran maksimal file adalah 5 MB (Mega Bytes)!");
        setIsError(true);
      }
    }
  };

  const insertMultipleFiles = (files) => {
    let newArray = [];
    if (isArray(selectedFiles)) {
      newArray = selectedFiles.slice();
    }

    if (files.length <= maxFiles) {
      let isValid = true;
      const validFiles = files.map((file) => {
        Object.assign(file, {
          id: Math.random().toString(36).substring(2),
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });

        if (isArray(selectedFiles)) {
          newArray.push(file);
        }

        return file.size > 5242880;
      });

      validFiles.forEach((i) => {
        if (i) isValid = false;
      });

      if (isValid) {
        setSelectedFiles(isArray(selectedFiles) ? newArray : files);
      } else {
        setErrorAlertMessage("Ukuran maksimal file adalah 5 MB (Mega Bytes)!");
        setIsError(true);
      }
    } else {
      setErrorAlertMessage(`Hanya dapat ${maxFiles} memilih file!`);
      setIsError(true);
    }
  };

  const removeFiles = (file) => {
    file = atob(file).split(",");

    const files = selectedFiles.slice();
    const id = file[0];

    let obj = files.find((x) => x.id === id);

    files.splice(files.indexOf(obj), 1);
    setSelectedFiles(files);
  };

  return (
    <Fragment>
      <Dropzone
        onDrop={(acceptedFiles) => {
          handleAcceptedFiles(acceptedFiles);
        }}
        style={style}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            {selectedFiles?.length > 0 ? (
              <Row
                className="dropzone-previews"
                id="file-previews"
                style={{ padding: 12 }}
              >
                {selectedFiles?.map((f, i) => {
                  return (
                    <Col md="4" key={i}>
                      <Card
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                          padding: 10,
                        }}
                      >
                        <Row
                          className="align-items-center"
                          style={{ width: "-webkit-fill-available" }}
                        >
                          <Col md="12" style={{ padding: 10 }}>
                            {f.type.includes("image") && (
                              <img
                                data-dz-thumbnail=""
                                style={{
                                  maxHeight: 300,
                                  width: "inherit",
                                  objectFit: "cover",
                                }}
                                className="rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            )}
                            {f.type.includes("pdf") && (
                              <i
                                className="uil uil-document-info avatar-sm rounded"
                                style={{ fontSize: 80 }}
                              ></i>
                            )}
                            {!f.type.includes("image") &&
                              !f.type.includes("pdf") && (
                                <i
                                  className="uil uil-document-info avatar-sm rounded"
                                  style={{ fontSize: 80 }}
                                ></i>
                              )}
                          </Col>
                          <Col md="12">
                            <a
                              href={f.preview}
                              target="_blank"
                              className="text-muted font-weight-bold"
                              style={{ fontSize: 14 }}
                            >
                              {f.name}
                            </a>
                            <br />
                            <label
                              className="text-muted"
                              style={{ fontSize: 12 }}
                            >
                              {f.formattedSize}
                            </label>
                          </Col>
                          <Col
                            md="12"
                            className="mt-3"
                            style={{ textAlign: "right" }}
                          >
                            <i
                              onClick={() => removeFiles(btoa(f.id))}
                              className="uil uil-x btn btn-sm btn-default btn-outline-secondary"
                              style={{ fontSize: "20px", cursor: "pointer" }}
                            ></i>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} accept={accept} />
                <div className="mb-3">
                  <i className="display-4 text-muted uil uil-cloud-upload" />
                </div>
                <h4>{placeholder}</h4>
              </div>
            )}
          </div>
        )}
      </Dropzone>
      {/* <div className="input-group mt-1" {...getRootProps()}>
				<input {...getInputProps()} accept={ accept } />
				<input readOnly className="form-control" placeholder={placeholder} style={{ background: 'white' }} />
				<div class="btn btn-primary btn-file" tabindex="500">
					<i class="bi-folder2-open"></i>   
					<span class="hidden-xs">Browse …</span>
					<input 
						multiple="" type="file" class="file" accept={accept}
						style={{ top: 0, left: 0, minWidth: '100%', minHeight: '100%', 
								textAlign: 'right', opacity: 0, background: 'none repeat scroll 0 0 transparent', 
								cursor: 'pointer', display: 'block', position: 'absolute' 
							}}
					/>
				</div>
			</div> */}
      {isAvForm && (
        <AvField
          name={name}
          validate={validate}
          type="hidden"
          value={selectedFiles}
          errorMessage={errorMessage}
        />
      )}
      {isError && (
        <SweetAlert
          title={"Gagal!"}
          showCloseButton={false}
          showConfirm={true}
          confirmBtnBsStyle="danger"
          error
          onConfirm={() => {
            setIsError(false);
          }}
        >
          {errorAlertMessage}
        </SweetAlert>
      )}
    </Fragment>
  );
};

/* export const generateFormData = (query, fileField = 'file') => {
  var formData = new FormData();
  for ( var key in query ) {
    if (isArray(fileField)) {
      fileField?.forEach(name => {
        if (key == name && query[key].length) {
          query[key].forEach((value, index) => {
            formData.append(`${key}[]`, value)
          });
        }
      });
    } else if (key == fileField) {
      if (query[key] != "") {
        query[key]?.forEach((value, index) => {
          formData.append(`${key}[]`, value)
        });
      }
      } else {
          formData.append(key, query[key]);
      }
  }

  return formData;
}; */

export const generateFormData = (query, fileField = "file") => {
  var formData = new FormData();
  fileField = !isArray(fileField) ? [fileField] : fileField;
  var fileFields = [];
  fileField.map((item) => {
    fileFields.push(typeof item === "object" ? item.field : item);
  });

  for (var key in query) {
    if (fileFields.includes(key)) {
      fileField.forEach((file) => {
        var name = typeof file === "object" ? file.field : file;
        if (key === name && query[key].length) {
          query[key].forEach((value, index) => {
            if (typeof file === "object") {
              Object.assign(value, {
                customName: file.name !== "" ? file.name : value.name,
              });
            }
            formData.append(`${key}[]`, value);
          });
        }
      });
    } else {
      formData.append(key, query[key]);
    }
  }

  return formData;
};

/* export const Preview = (props) => {
  const { 
    value, 
    col = 3,
    fileName = 'documents_name',
    filePath = 'documents_path',
    fileMimeType = 'documents_mime_type',
    fileSize = 'documents_file_size'
  } = props;

  return (
    <Fragment>
      {value?.length > 0 ? 
        <Row className="dropzone-previews" id="file-previews">
          {value?.map((f, i) => {
            return (
              <Col md={col}>
                <Card style={{ alignItems: 'center', textAlign: 'center', padding: 10 }}>
                  <Row className="align-items-center" style={{ width: '-webkit-fill-available', }}>
                    <Col md="12" style={{ padding: 10 }}>
                      { f[fileMimeType] ?
                        <>
                          { f[fileMimeType]?.includes('image') &&
                            <img
                              data-dz-thumbnail=""
                              style={{ maxHeight: 300, width: 'inherit', objectFit: 'cover' }}
                              className="rounded bg-light"
                              alt={f[fileName]}
                              src={f[filePath]}
                            />
                          }
                          {f[fileMimeType]?.includes('pdf') &&
                            <i className="uil uil-document-info avatar-sm rounded" style={{ fontSize: 80 }} ></i>
                          }
                          {!f[fileMimeType]?.includes('image') && !f[fileMimeType].includes('pdf') &&
                            <i className="uil uil-document-info avatar-sm rounded" style={{ fontSize: 80 }} ></i>
                          }
                        </>
                        :
                          <i className="uil uil-document-info avatar-sm rounded" style={{ fontSize: 80 }} ></i>
                      }

                    </Col>
                    <Col md="12">
                      <a
                        href={f[filePath]}
                        target="_blank"
                        className="text-muted font-weight-bold"
                      >
                        {f[fileName]}
                      </a>
                      <br/>
                      <label className="text-muted" style={{ fontSize: 12 }}>{formatBytes(f[fileSize])}</label>
                    </Col>
                    <Col md="12" className="mt-3" style={{ textAlign: 'right' }}>
                      <a 
                        href={f[filePath]}
                        className="btn btn-sm btn-default btn-outline-secondary"
                        target="_blank"	
                      >
                        <i className="uil uil-eye" style={{ fontSize: '20px', cursor: 'pointer', }} ></i>
                      </a>
                    </Col>
                  </Row>
                </Card>
              </Col>	
            )
          })}
        </Row>
        :
        <Fragment>
          { value && 
            <Row className="dropzone-previews" id="file-previews">
              <Card style={{ alignItems: 'center', textAlign: 'center', padding: 10 }} className="col-md-4 col-form-label">
                <Row className="align-items-center" style={{ width: '-webkit-fill-available', }}>
                  <Col md="12" style={{ padding: 10 }}>
                    { value && fileMimeType in value ?
                      <>
                        {value[fileMimeType].includes('image') &&
                          <img
                            data-dz-thumbnail=""
                            style={{ maxHeight: 300, width: 'inherit', objectFit: 'cover' }}
                            className="rounded bg-light"
                            alt={value[fileName]}
                            src={value[filePath]}
                          />
                        }
                        {value[fileMimeType].includes('pdf') &&
                          <i className="uil uil-document-info avatar-sm rounded" style={{ fontSize: 80 }} ></i>
                        }
                        {!value[fileMimeType].includes('image') && !value[fileMimeType].includes('pdf') &&
                          <i className="uil uil-document-info avatar-sm rounded" style={{ fontSize: 80 }} ></i>
                        }
                      </>
                      :
                      <i className="uil uil-document-info avatar-sm rounded" style={{ fontSize: 80 }}></i>
                    }
                  </Col>
                  <Col md="12">
                    <a
                      href={value[filePath]}
                      target="_blank"
                      className="text-muted font-weight-bold"
                    >
                      {value[fileName]}
                    </a>
                    <br/>
                    <label className="text-muted" style={{ fontSize: 12 }}>{formatBytes(value[fileSize])}</label>
                  </Col>
                  <Col md="12" className="mt-3" style={{ textAlign: 'right' }}>
                    <a 
                      href={value[filePath]}
                      className="btn btn-sm btn-default btn-outline-secondary"
                      target="_blank"	
                    >
                      <i className="uil uil-eye" style={{ fontSize: '20px', cursor: 'pointer', }} ></i>
                    </a>
                  </Col>
                </Row>
              </Card>
            </Row>
          }
        </Fragment>
      }
    </Fragment>
  )
} */

export class Preview extends Component {
  minioClient = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {
      url: {},
    };
  }

  getAllUrl = () => {
    this.props.value?.map((f, i) => {
      if (f[this.props.fileSource] === "minio" || this.props.attachmentSource == "minio") {
        this.minioClient.get(f[this.props.filePath]).then((url) => {
          this.setState({
            url: {
              ...this.state.url,
              [i]: url,
            },
          });
        });
      } else {
        this.setState({
          url: {
            ...this.state.url,
            [i]: f[this.props.filePath],
          },
        });
      }
    });
  };

  componentDidMount() {
    this.getAllUrl();
  }

  render() {
    return (
      <Fragment>
        {this.props.value?.length > 0 ? (
          <Row className="dropzone-previews" id="file-previews">
            {this.props.value?.map((f, i) => {
              return this.state.url[i] ? (
                <Col key={i} md={this.props.col}>
                  <Card
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                      padding: 10,
                    }}
                  >
                    <Row
                      className="align-items-center"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <Col md="12" style={{ padding: 10 }}>
                        {f[this.props.fileMimeType] ? (
                          <>
                            {f[this.props.fileMimeType]?.includes("image") && (
                              <img
                                data-dz-thumbnail=""
                                style={{
                                  maxHeight: 300,
                                  width: "inherit",
                                  objectFit: "cover",
                                }}
                                className="rounded bg-light"
                                alt={f[this.props.fileName]}
                                src={this.state.url[i]}
                              />
                            )}
                            {f[this.props.fileMimeType]?.includes("pdf") && (
                              <i
                                className="uil uil-document-info avatar-sm rounded"
                                style={{ fontSize: 80 }}
                              ></i>
                            )}
                            {!f[this.props.fileMimeType]?.includes("image") &&
                              !f[this.props.fileMimeType].includes("pdf") && (
                                <i
                                  className="uil uil-document-info avatar-sm rounded"
                                  style={{ fontSize: 80 }}
                                ></i>
                              )}
                          </>
                        ) : (
                          <i
                            className="uil uil-document-info avatar-sm rounded"
                            style={{ fontSize: 80 }}
                          ></i>
                        )}
                      </Col>
                      <Col md="12">
                        {f[this.props.fileName]}
                        <br />
                        <label className="text-muted" style={{ fontSize: 12 }}>
                          {formatBytes(f[this.props.fileSize])}
                        </label>
                      </Col>
                      <Col
                        md="12"
                        className="mt-3"
                        style={{ textAlign: "right" }}
                      >
                        <a
                          href={this.state.url[i]}
                          className="btn btn-sm btn-default btn-outline-secondary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="uil uil-eye"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          ></i>
                        </a>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ) : null;
            })}
          </Row>
        ) : (
          <Fragment>
            <Row className="dropzone-previews" id="file-previews">
              <Col md={this.props.col}>
                <Card
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  <Row
                    className="align-items-center"
                    style={{ width: "-webkit-fill-available" }}
                  >
                    <Col md="12" style={{ padding: 10 }}>
                      <i
                        className="uil uil-document-info avatar-sm rounded"
                        style={{ fontSize: 80 }}
                      ></i>
                    </Col>
                    <Col md="12">
                      Tidak ada File
                      <br />
                      <label className="text-muted" style={{ fontSize: 12 }}>
                        -
                      </label>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
Preview.defaultProps = {
  value: [],
  col: 3,
  fileName: "attachment_name",
  filePath: "attachment_path",
  fileMimeType: "attachment_type",
  fileSize: "attachment_size",
  fileSource: "attachment_source",
};

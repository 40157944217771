import React, { Component } from "react";
import Header from "../../../components/DefaultLayout/Header/header";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { application } from "../../../data";
import { cancelToken } from "../../../services/adapters/base";
import apiResponse from "../../../services/apiResponse";
import AgendaBloc from "./Blocs/AgendaBloc";
import PortalServiceBloc from "./Blocs/PortalServiceBloc";
import HelpdeskBloc from "./Blocs/HelpdeskBloc";
import AgendaSection from "./Components/AgendaSection";
import AnnouncementPopup from "./Components/AnnouncementPopup";
import HelpDesk from "./Components/HelpDesk";
import AppPopup from "./Components/AppPopup";
import FooterSection from "./Components/Footer";
import HeroSection from "./Components/HeroSection";
import PanduanSection from "./Components/PanduanSection";
import PengumumanSection from "./Components/PengumumanSection";
import MinioClient from "../../../services/minioClient";
import PortalSection from "./Components/PortalSection";
import "./style.scss";

class IndexView extends Component {
  static contextType = GlobalContext;
  source;
  agendaBloc = new AgendaBloc();
  portalServiceBloc = new PortalServiceBloc();
  helpdeskBloc = new HelpdeskBloc();
  minioClient = new MinioClient();
  userData = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {},
      portalServices: null,
      portalServicesIcon: [],
      showAppModal: false,
      showAgendaModal: false,
      modalContent: null,
      filter: sessionStorage.getItem(this.historyTable)
        ? JSON.parse(sessionStorage.getItem(this.historyTable))
        : {
          filter_value: "",
          page_number: 1,
          page_size: this.defaultSize,
          sort_order: {
            column: this.defaultOrder,
            order: this.defaultSort,
          },
        },
    };
  }

  submitData = async () => {
    const { dataForm } = this.context;
    await this.agendaBloc.fetchCreate(dataForm);
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;

    e.preventDefault();
    setDataForm({
      ...dataForm,
      ...values,
    });
    this.setState({
      confirm: true,
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    /* kirim ke server */
    const query = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      nip: this.userData.employee_nip,
      status: 1,
    };

    const queryPortalService = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: "portal_service_id",
      sort: "asc",
    };

    const queryHelpdeskService = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: "helpdesk_service_id",
      sort: "asc",
    };

    await this.portalServiceBloc.fetchList(queryPortalService, this.source.token);
    await this.agendaBloc.fetchList(query, this.source.token);
    await this.helpdeskBloc.fetchList(queryHelpdeskService, this.source.token);
  };

  componentDidMount() {
    const { setAgendas } = this.context;

    this.setTokenAPI();

    this.agendaBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;

          this.setState({
            agendas: response.result,
            loadingTable: false,
          });

          setAgendas(response.result);

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.portalServiceBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            portalServices: response.result,
            loadingTable: false,
          });

          response.result.map((service, index) => {
            this.setServiceIcon(service, index)
          })

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.helpdeskBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            helpdeskServices: response.result,
            loadingTable: false,
          });

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { setAgendas } = this.context;
    setAgendas(this.state.agendas);
  }

  componentWillUnmount() {
    this.agendaBloc.listChannel.unsubscribe();
    this.portalServiceBloc.listChannel.unsubscribe();
    this.helpdeskBloc.listChannel.unsubscribe();
  }

  setServiceIcon = (service, index) => {
    this.minioClient.get(service.attachment_path).then((url) => {
      this.setState({
        portalServicesIcon: [...this.state.portalServicesIcon, {[service.portal_service_id]: url}]
      });
    });
  }

  toggleModalAgenda = () => {
    this.setState({
      showModalAgenda: !this.state.showAgendaModal,
    });
  };

  toggleAppModal = (id) => {
    if (id) this.setModalContent(id);

    this.setState({
      showAppModal: !this.state.showAppModal,
    });
  };

  setModalContent = (id) => {
    this.setState({
      modalContent: this.state.portalServices.filter((item) => item.portal_service_id === id)[0],
    });
  };

  render() {
    return (
      <div style={{ overflowX: "hidden" }}>
        <AnnouncementPopup />
        <div className="main">
          <Header />
          <HeroSection />
          {(this.state.portalServices && this.state.portalServicesIcon.length === this.state.portalServices.length) && (
            <>
              <PortalSection
                applications={this.state.portalServices}
                icons={this.state.portalServicesIcon}
                toggleAppModal={this.toggleAppModal}
              />
            </>
          )}
          <div className="row announcement-content" style={{ backgroundColor: "#F8F9FC"}}>
            <div className="col-md-6">
              <>
                <PengumumanSection />
              </>
            </div>
            <div className="col-md-6">
              {this.state.agendas && (
                <>
                  <AgendaSection
                    agendas={this.state.agendas}
                    token={this.setTokenAPI}
                  />
                </>
              )}
            </div>

          </div>
          {/* <>
            <PanduanSection />
          </> */}
          
          {(this.state.helpdeskServices) && (
            <>
              <HelpDesk 
                services={this.state.helpdeskServices}
              />
            </>
          )}

          <AppPopup
            show={this.state.showAppModal}
            toggle={this.toggleAppModal}
            application={this.state.modalContent}
          />
        </div>
        <FooterSection noSubscription={true} />
      </div>
    );
  }
}

export default IndexView;

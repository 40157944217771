import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { Icon } from "@iconify/react";

import "./style.scss";

const AppPopup = ({ show, toggle, application }) => {
  return (
    <div>
      <Modal isOpen={show} toggle={toggle} centered={true} size="lg">
        <ModalBody className="app-modal-body">
          <div className="container">
            <h5 className="mb-5">{application ? application.title : null}</h5>
            <div className="app-modal">
              {application
                ? application.features.map((item, idx) => {
                  return (
                    <a
                      className="container bg-white modal-container"
                      title={item.service_feature_title}
                      href={item.service_feature_link || null}
                      target="_blank"
                      rel="noreferrer"
                      key={idx + 1}
                    >
                      <div
                        style={{
                          display: "flex",
                          columnGap: ".5rem",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Icon
                          icon={item.service_feature_icon}
                          width="16"
                          color={application.service_features_color}
                        />
                        <span>{item.service_feature_title}</span>
                        {item.alert == 1 && (
                          <Icon
                            icon="eva:alert-circle-fill"
                            width="16"
                            style={{ position: "absolute", right: 0 }}
                            color="#4eb6e2"
                          />
                        )}
                      </div>
                    </a>
                  );
                })
                : null}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AppPopup;

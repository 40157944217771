import { useContext, useState } from "react";
import { GlobalContext } from "../../../../../contexts/GlobalContext.jsx";
import Service from "../Service/Service.jsx";
import { Waypoint } from "react-waypoint";

import "./style.scss";

const PortalSection = ({ applications, icons, toggleAppModal }) => {
  const [delay] = useState(0);
  const { setActiveNav } = useContext(GlobalContext);

  const hanldeWaypointEnter = () => {
    setActiveNav("Portal");
  };

  return (
    <section id="portal">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "auto",
          marginTop: "3rem",
          fontSize: "28px",
          rowGap: "2rem",
          marginBottom: "5rem",
        }}
        className="container col-md-12"
      >
        <h2 style={{ fontWeight: "bold", color: "#0F0465" }}>
          <span data-aos="fade-up">Daftar Layanan</span>
        </h2>

        <Waypoint scrollableAncestor={window} onEnter={hanldeWaypointEnter} />
        <div
          className="services-container grid"
          style={{ rowGap: ".8rem", columnGap: ".8rem" }}
          data-aos="fade-up"
          data-aos-duration="500"
        >
          {applications.map((item, idx) => {
            return (
              <Service
                serviceName={item.service_title}
                active={item.is_active == 1 ? true : false}
                icon={icons.find(x => x[item.portal_service_id] != null)[item.portal_service_id]}
                color={item.service_color}
                serviceLink={item.service_link || null}
                onClick={() => {
                  if (item.features) toggleAppModal(item.portal_service_id);
                }}
                delay={delay}
                key={idx + 1}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PortalSection;

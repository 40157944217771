import {
  apiRequest,
  ticketReply,
  ticketReplies,
} from "../../../../services/adapters/base";

class ReplyRepository {
  fetchReply = async (query) => {
    return await apiRequest(
      "post",
      ticketReply,
      {
        body: query,
      },
      true
    );
  };

  fetchReplies = async (query) => {
    return await apiRequest(
      "get",
      ticketReplies,
      {
        params: query,
      },
      true
    );
  };
}

export default ReplyRepository;

import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Button, Row, Col, Label, Input } from "reactstrap"
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  FileInput,
  generateFormData
} from "../../../../helpers/ui/FileInput";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import listenGif from "../../../../assets/videos/listening.gif"
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { Link, Redirect } from "react-router-dom";
import CustomSelect from "../../../../helpers/ui/CustomSelect"
import reviewIcon from '../../../../assets/icons/icon_review_summary.png';
import DatePicker from '../../../../helpers/ui/DatePicker'
import Lottie from "react-lottie";
import animationData from "../../../../assets/lotties/nlp.json"
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function PeminjamanKendaraanDinasView(props) {

  const baseUrl = process.env.REACT_APP_INVENTORY_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_INVENTORY_SERVICE_BASE_APPCODE;
  const title = "Pengajuan Peminjaman Kendaraan Dinas";
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentAccessToken, setCurrentAccessToken] = useState()
  const questions = ["Silakan pilih jenis peminjaman", "Untuk kepentingan apa?", "Silakan Pilih tanggal keberangkatan & pengembalian", "Silakan tulis jumlah penumpang", "Silakan tulis tujuan peminjaman"];
  const questionTotal = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const usages = [
    {
      label: "Drop Saja",
      value: 1
    },
    {
      label: "Full Standby",
      value: 2
    }
  ]

  const interests = [
    {
      label: "Luar Kota",
      value: 1
    },
    {
      label: "Dalam Kota",
      value: 2
    }
  ]

  const [selectedStartTime, setSelectedStartTime] = useState()
  const [selectedStartDate, setSelectedStartDate] = useState()
  const [selectedEndDate, setSelectedEndDate] = useState()
  const [selectedTotalPassenger, setSelectedTotalPassenger] = useState()
  const [selectedDestination, setSelectedDestination] = useState()
  const [selectedInterest, setSelectedInterest] = useState({})
  const [selectedUsage, setSelectedUsage] = useState({})

  const [confirm, setConfirm] = useState()
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [failed, setFaield] = useState()
  const [redirect, setRedirect] = useState()

  const {
    transcript,
    listening,
  } = useSpeechRecognition();

  const startListen = () => {
    SpeechRecognition.startListening({ language: 'id'})
  }

  useEffect(() => {
    setSelectedDestination(transcript)
  }, [transcript]);

  useEffect(() => {
    const fetchAccessToken = () => {
      try {
        axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCode,
            email: userData.employee_email
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentAccessToken(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAccessToken()
  }, []); 


  const submitData = async () => {
    const dataForm = {
      loanvehicle_departuretime: selectedStartTime,
      loanvehicle_employee_uuid: userData.employee_uuid,
      sync_employee_fullname: userData.employee_name,
      sync_employee_current_position: userData.employee_current_position_name,
      sync_employee_current_work_unit: userData.employee_current_work_unit_name,
      loanvehicle_contactapplicant: userData.employee_phone_number,
      loanvehicle_dateofuse: selectedStartDate,
      loanvehicle_ttl_passenger: selectedTotalPassenger,
      loanvehicle_destination: selectedDestination,
      vehicle_loan_interest_type: selectedInterest.value,
      vehicle_loan_date_of_return: selectedEndDate,
      vehicle_loan_usage_type: selectedUsage.value
    }

    const response = await axios.post(`${baseUrl}prosespeminjaman/kendaraandinas/create/json`, dataForm, {
      headers: {
        appCode: appCode,
        email: userData.employee_email,
        accessToken: currentAccessToken
      },
    });

    if (response.data.status == true) {
      setSuccess(true)
    } else {
      setFaield(true)
    }
    setLoading(false)
  }

  const confirmResponse = (response) => {
    switch (response) {
      case "cancel":
        setConfirm(false)
        break;
      case "confirm":
        setLoading(true)
        setConfirm(false)        
        submitData();
        break;
      case "success":
        setSuccess(false)
        setRedirect(true)
        break;
      case "failed":
        setFaield(false)
        break;
      default:
    }
  };

  const showButton = (showNextButton) => {
    return (
      <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", bottom: 0, right: 0, zIndex: 99 }}>
          {currentQuestion > 1 && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              Kembali
            </Button> 
          )}

          {showNextButton == true && currentQuestion != questionTotal && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Selanjutnya
            </Button> 
          )}

          {showNextButton == true && currentQuestion == questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Review
          </Button> 
          )}

          {currentQuestion > questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setConfirm(true)}
          >
            Submit
          </Button> 
          )}
        </div>
    )
  }

  const showLottie = () => {
    return <Lottie options={{ 
      loop: true,
      autoplay: true, 
      animationData: animationData
     }}
      isClickToPauseDisabled={true}
      height="100%"
      width="100%"
      isStopped={false}
      isPaused={false}/>
  }
  
  return (
    <>
      {currentQuestion <= questionTotal && (
        <div className="mb-4" style={{ position: "fixed", top: 0, width: "100%" }}>
          <Progress color="info" value={currentQuestion / questionTotal * 100} />
        </div>
      )}
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {currentQuestion <= questionTotal ? (
          <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99 }}>
            Pertanyaan {currentQuestion} dari {questionTotal}
          </div>
        ) : <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
        <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
      </div>}

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px" }} >
        
        { currentQuestion == 1 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[0]}</p>

                {usages.map((usage) => (
                  <Button
                    color="primary"
                    value={usage.value}
                    outline
                    className="waves-effect waves-light"
                    onClick={(event) => {
                      setSelectedUsage(usage)
                      setCurrentQuestion(currentQuestion + 1)
                    }}
                    style={{
                      margin: "5px", 
                      borderRadius: "20px",
                      borderColor: "#3FC057",
                      background: selectedUsage.value == usage.value ? "#3FC057" : "rgba(63, 192, 87, 0.05)", 
                      color: selectedUsage.value == usage.value ? 'white' : '#3FC057'
                    }}
                  >
                    {usage.label}
                  </Button>
                ))}
              {showButton(selectedUsage.value != null)}
            </div> 
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          ) 
        }

        { currentQuestion == 2 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[1]}</p>
            
                {interests.map((interest) => (
                  <Button
                    color="primary"
                    value={interest.value}
                    outline
                    className="waves-effect waves-light"
                    onClick={(event) => {
                      setSelectedInterest(interest)
                      setCurrentQuestion(currentQuestion + 1)
                    }}
                    style={{
                      margin: "5px", 
                      borderRadius: "20px",
                      borderColor: "#3FC057",
                      background: selectedInterest.value == interest.value ? "#3FC057" : "rgba(63, 192, 87, 0.05)", 
                      color: selectedInterest.value == interest.value ? 'white' : '#3FC057'
                    }}
                  >
                    {interest.label}
                  </Button>
                ))}
              {showButton(selectedInterest.value != null)}
            </div> 
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          ) 
        }

        { currentQuestion == 3 && (
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[2]}</p>
              <div>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="start-date-input">Tanggal Keberangkatan</Label>
                    <input
                      className="form-control"
                      type="date"
                      value={selectedStartDate}
                      onChange={(event) => {
                        setSelectedStartDate(event.target.value);
                        if (selectedEndDate != null && selectedStartTime) {
                          setCurrentQuestion(currentQuestion + 1)
                        }
                      }}
                      id="end-date-input"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="start-date-input">Jam Keberangkatan</Label>
                    <AvField
                      name="agenda_end"
                      type="time"
                      errorMessage="Masukkan jam keberangkatan"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="agenda_end"
                      value={selectedStartTime}
                      onChange={(event) => {
                        setSelectedStartTime(event.target.value)
                        if (selectedStartDate != null && selectedEndDate) {
                          setCurrentQuestion(currentQuestion + 1)
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="end-date-input">Tanggal Pengembalian</Label>
                    <input
                      className="form-control"
                      type="date"
                      value={selectedEndDate}
                      onChange={(event) => {
                        setSelectedEndDate(event.target.value);
                        if (selectedStartDate != null && selectedStartTime != null) {
                          setCurrentQuestion(currentQuestion + 1)
                        }
                      }}
                      id="end-date-input"
                    />
                  </div>
                </Col>
              </Row>
              </div>
              {showButton(selectedStartDate != null && selectedEndDate != null && selectedStartTime != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 4 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[3]}</p>
                
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC"  }}>
                <input
                  type="number"
                  value={selectedTotalPassenger}
                  style={{fontSize: "18px", width: "100%",  border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingLeft: "20px", paddingRight: "20px"}}
                  onChange={(event) => {
                    setSelectedTotalPassenger(event.target.value)
                  }}
                />
              </div>
              {showButton(selectedTotalPassenger != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 5 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[4]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  type="text"
                  value={selectedDestination}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setSelectedDestination(event.target.value)
                  }}
                />
                <i className="uil-microphone" onClick={startListen}  style={{ 
                    fontSize: '24px',  
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    cursor: 'pointer',}}/>
              </div>
            </div>
            <div className="col-md-6">
              {listening ? (
                <div className="centered-horizontal">
                  <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
                </div>
              ) : showLottie()}
            {showButton(selectedDestination != null)}
            </div>
          </Row>
        )}

        { currentQuestion == questionTotal + 1 && ( 
          <div className="col-md-12" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h5 className="nlp-content">Resume</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "5vw" }}>
              <Row>
                <Col md="auto" style={{ marginRight: "50px" }}>
                  <div> 
                    <div className="centered-horizontal">
                      <img src={reviewIcon}/>
                    </div>
                  </div>
                </Col>
                <Col md ={8}>
                  <Row>

                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Tipe Peminjaman</Label>
                        <p className="nlp-sub-content">{selectedUsage.label}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Untuk Kepentingan</Label>
                        <p className="nlp-sub-content">{selectedInterest.label}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Jumlah Penumpang</Label>
                        <p className="nlp-sub-content">{selectedTotalPassenger} orang</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Tanggal Keberangkatan</Label>
                        <p className="nlp-sub-content">{`${moment(selectedStartDate).format('LL')}`}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Jam Keberangkatan</Label>
                        <p className="nlp-sub-content">{selectedStartTime}</p>  
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Tanggal Pengembalian</Label>
                        <p className="nlp-sub-content">{`${moment(selectedEndDate).format('LL')}`}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Tujuan Peminjaman</Label>
                        <p className="nlp-sub-content">{selectedDestination}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {showButton(false)}
          </div>
         ) }
        </AvForm>
      </div>
      <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={confirm}
          showLoading={loading}
          showSuccess={success}
          showFailed={failed}
          response={confirmResponse}
        />
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default PeminjamanKendaraanDinasView;

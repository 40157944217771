import React, { Fragment } from "react"
import DataTableComponent from './Components/DataTableComponent'

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    // Button
} from "reactstrap"


const IndexView = (props) => {

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>Notifikasi</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Daftar notifikasi aplikasi, data yang bertanda bold merupakan data notifikasi yang belum pernah dibuka.
                                </CardSubtitle>
                                <hr />
                                <DataTableComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default IndexView
import {
	apiRequest,
	announcementList,
	announcementActive,
	announcementInactive,
} from "../../../../services/adapters/base";

class DataTableRepository {
	fetchRowTable = async (query, cancelToken) => {
		return await apiRequest(
			"get",
			announcementList,
			{
				cancelToken: cancelToken,
				params: query,
			},
			true
		);
	};

	fetchInactive = async (query) => {
		return await apiRequest(
			"post",
			announcementInactive,
			{
				body: query,
			},
			true
		);
	};

	fetchActive = async (query) => {
		return await apiRequest(
			"post",
			announcementActive,
			{
				body: query,
			},
			true
		);
	};
}

export default DataTableRepository;

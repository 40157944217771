import {
  apiRequest,
  agendaList,
  agendaCreate,
  agendaDetail,
  agendaNotulensi,
} from "../../../../services/adapters/base";

class AgendaRepository {
  fetchList = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      agendaList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      agendaDetail,
      {
        params: query,
      },
      true
    );
  };

  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      agendaCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchNotulensi = async (query) => {
    return await apiRequest(
      "post",
      agendaNotulensi,
      {
        body: query,
      },
      true
    );
  };
}

export default AgendaRepository;

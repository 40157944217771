import axios from "axios";
import { authContext } from "./adalConfig";

import MinioClient from "./minioClient";

export const apiCancel = () => {
  return axios.CancelToken.source();
};
export const apiWrapper = async (
  baseUrl,
  initial,
  appCode,
  outputType,
  method,
  route,
  data = { params: null, body: null, cancelToken: null }
) => {
  const onSuccess = (response) => {
    if (response.data.tokenExpire) {
      localStorage.setItem(
        initial + "accessExpired",
        response.data.tokenExpire
      );
    }
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response || error.message);
  };

  if (data.body !== null) {
    if (data.body instanceof FormData) {
      var newBody = new FormData();
      const isFile = (input) => "File" in window && input instanceof File;

      var numberField = {};
      for (var pair of data.body.entries()) {
        if (!isFile(pair[1])) {
          newBody.append(pair[0], pair[1]);
        } else {
          if (typeof pair[1].customName != "undefined") {
            const minioClient = new MinioClient();
            var currentField = pair[0].replace("[]", "");
            numberField[currentField] = !numberField[currentField]
              ? 0
              : numberField[currentField];
            var filePathName =
              pair[1].customName +
              "_" +
              new Date().getTime() +
              "." +
              pair[1].name.split(".").pop();
            var fileName = filePathName.split("/").pop();

            await minioClient.put(filePathName, pair[1]);

            newBody.append(
              currentField + "[" + numberField[currentField] + "][name]",
              fileName
            );
            newBody.append(
              currentField + "[" + numberField[currentField] + "][path]",
              filePathName
            );
            newBody.append(
              currentField + "[" + numberField[currentField] + "][size]",
              pair[1].size
            );
            newBody.append(
              currentField + "[" + numberField[currentField] + "][mime]",
              pair[1].type
            );
            newBody.append(
              currentField + "[" + numberField[currentField] + "][original]",
              pair[1].name
            );
            newBody.append(
              currentField + "[" + numberField[currentField] + "][ext]",
              pair[1].name.split(".").pop()
            );
            ++numberField[currentField];
          } else {
            newBody.append(pair[0], pair[1]);
          }
        }
      }
      data.body = newBody;
    }
  }

  return axios
    .create({
      baseURL: baseUrl,
      headers: {
        initial: initial,
        appCode: appCode,
        email: authContext.getCachedUser() != null ? authContext.getCachedUser().userName : null,
        accessToken: localStorage != null ? localStorage.getItem(initial + "accessToken") : null,
        tokenId: localStorage != null ? localStorage.getItem("tokenId") : null,
        accessId: localStorage != null ? localStorage.getItem("currentRoleId") : null,
      },
    })({
      method,
      url: route + outputType,
      params: data.params,
      data: data.body,
      cancelToken: data.cancelToken,
    })
    .then(onSuccess)
    .catch(onError);
};


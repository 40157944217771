import PropTypes from 'prop-types'
import React, { Component } from "react"
import IdleTimer from 'react-idle-timer'
import ErrorIdle from '../Error/Content/Idle'
import SweetAlert from "react-bootstrap-sweetalert"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Breadcrumbs from "../../components/Common/Breadcrumb"

class AdminLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      remainingIdle: 1000,
      intervalId: null,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isIdle: false
    }
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this)

    this.idleTimer = null
    //this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  handleOnActive(event) {
    document.getElementById("preloader").style.display = "none"
  }

  handleInterval = () => {
    this.setState({
      remainingIdle: this.idleTimer !== null ? (this.idleTimer.getRemainingTime() / 1000).toFixed() : 1000
    })
    let intervalId = setTimeout(() => {
      this.handleInterval()
    }, 1000)
    this.setState({ intervalId: intervalId })
  }

  handleOnIdle(event) {
    document.getElementById("preloader").style.display = "block"
  }


  componentDidMount() {
    clearInterval(this.state.intervalId)
    this.handleInterval()
    document.getElementById("preloader").style.display = "none"
    // Scroll Top to 0
    window.scrollTo(0, 0)
    //let currentage = this.capitalizeFirstLetter(this.props.location.pathname)
    //document.title = currentage + " | Keuangan - Badan Koordinasi Penanaman Modal"

    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme)
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth)
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType)
    }

    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme)
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile)
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile)
    }
  }

  render() {
    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 15}
          onIdle={this.handleOnIdle}
          getRemainingTime={(m) => { console.log(m) }}
        />
        {this.state.remainingIdle < 61 && this.state.remainingIdle > 0 ?
          <SweetAlert
            title="Tidak ada aktifitas"
            warning
            showCloseButton={false}
            showConfirm={false}
            //customIcon={loadingIcon}
            onConfirm={() => { }}
          >
            {"Akan tertutup dalam " + this.state.remainingIdle + " detik"}
          </SweetAlert>
          : null
        }
        <div id="preloader" style={{ background: 'rgba(255,255,255,.9)' }}>
          <ErrorIdle handleOnActive={this.handleOnActive} />
        </div>
        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          />
          <div className="main-content" style={{ paddingBottom: 70, paddingLeft: 10, paddingRight: 10 }}>
            <Breadcrumbs />
            {this.props.children}
          </div>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

AdminLayout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

const mapStatetoProps = state => {
  return {
    ...state.Layout
  }
}
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth
})(withRouter(AdminLayout))

import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Row, Col, Label } from "reactstrap"
import { Redirect, Link } from "react-router-dom";
import {
  AvForm
} from "availity-reactstrap-validation";
import reviewIcon from '../../../../assets/icons/icon_review_summary.png';
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function SaldoCutiView(props) {

  const baseUrl = process.env.REACT_APP_SIMPEG_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_SIMPEG_SERVICE_BASE_APPCODE;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [saldoCuti, setSaldoCuti] = useState({})
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    const fetchAccessToken = () => {
      try {
        axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCode,
            email: userData.employee_email
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          fetchSaldoCuti(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchSaldoCuti = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}Kepegawaian/cuti/getYearQuota/json?uuid=${userData.employee_uuid}`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });
        setSaldoCuti(response.data.response)  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAccessToken()
  }, []); 
  
  return (
    <>
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >

        <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
          <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
        </div>

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px" }} >
          <div className="col-md-12">
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "40px" }}>
              <Row>
                <h2>{userData.employee_name}</h2>
                <h2><div style={{ 
                  display: "inline-flex",
                  padding: "10px",
                  width: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  borderRadius: "6px",
                  fontSize: "12px",
                  color: "#3E70D1",
                  background: "rgba(62, 112, 209, 0.10)",
                  marginTop: "10px",
                  marginBottom: "10px",
                }} >
                  {userData.employee_current_position_name}
                </div></h2>
              </Row>
              <Row>
                <Col md={3}>
                  <div>
                    <p>Jabatan</p>
                    <Label className="form-label">{userData.employee_current_position_name}</Label>
                  </div>
                </Col>
                <Col md={3}>
                  <div>
                    <p>NIP</p>
                    <Label className="form-label">{userData.employee_nip}</Label>
                  </div>
                </Col>
                <Col md={3}>
                  <div>
                    <p>Saldo</p>
                    <Label className="form-label">{saldoCuti.sisa} hari</Label>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </AvForm>
      </div>
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default SaldoCutiView;

import {
  apiRequest,
  attendanceExt,
} from "../../../services/adapters/base";

class AttendanceRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      attendanceExt,
      {
        body: query,
      },
      true
    );
  };
}

export default AttendanceRepository;

import React, { Component } from "react";
import { config } from "../../../../../helpers/config_global";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import TicketBloc from "../../Blocs/TicketBloc";
import { Icon } from "@iconify/react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import TicketPopup from "../TicketPopup";

import "./style.scss";

class HelpDeskMessages extends Component {
  ticketBloc = new TicketBloc();

  userData = JSON.parse(localStorage.getItem("userdata"));

  source;
  historyTable = "historyTicketTableActive";
  defaultOrder = "created_at";
  defaultSize = 10;
  defaultSort = "desc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      showTicketModal: false,
      ticketToShow: {},
      dropdownOpen: false,
      tooltip: false,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      loadingTable: true,
      filter: sessionStorage.getItem(this.historyTable)
        ? JSON.parse(sessionStorage.getItem(this.historyTable))
        : {
            filter_value: "",
            page_number: 1,
            page_size: this.defaultSize,
            sort_order: {
              column: this.defaultOrder,
              order: this.defaultSort,
            },
          },
    };
  }

  toggleTicketModal = (e) => {
    // setTimeout(() => {
    this.setState({
      ...this.state,
      ticketShowUuid: e.currentTarget
        ? e.currentTarget.id
        : this.state.ticketToShow,
      showTicketModal: !this.state.showTicketModal,
    });
    // }, 100);
  };

  toggleDropDown = () => {
    this.setState({
      ...this.state,
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  renderTicketModal = (ticket_uuid) => {
    return (
      <TicketPopup
        show={this.state.showTicketModal}
        toggle={this.toggleTicketModal}
        uuid={this.state.ticketShowUuid}
      />
    );
  };

  toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

  loadData = async () => {
    /* kirim ke server */
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: 0,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      status: [0, 1, 2, 3, 4],
      email: this.userData.employee_email_internal,
    };
    await this.ticketBloc.fetchList(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  closeTicket = async () => {
    await this.ticketBloc.fetchCloseTicket({
      ticket_uuid: this.state.inactiveUuid,
    });
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.closeTicket();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.setTokenAPI();

    this.ticketBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            totalRow: response.total,
            rows: response.result,
            loadingTable: false,
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.ticketBloc.closeTicketChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState(
            {
              loading: false,
              success: true,
              loadingTable: true,
            },
            function () {
              this.setTokenAPI();
              this.props.handleInactiveSuccess();
            }
          );
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.ticketBloc.listChannel.unsubscribe();
    this.ticketBloc.closeTicketChannel.unsubscribe();
  }

  render() {
    return (
      <>
        <ButtonDropdown
          isOpen={this.state.dropdownOpen}
          toggle={this.toggleDropDown}
          className="mr-3"
        >
          <DropdownToggle className="helpdesk-message-btn">
            <div className="icon-container" style={{ position: "relative" }}>
              {this.state.totalRow > 0 && (
                <div
                  className="badge-counter"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: -2,
                    background: "#F01111",
                    width: "0.90rem",
                    height: "0.90rem",
                    borderRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{ fontWeight: 700, fontSize: "7pt", lineHeight: 0 }}
                  >
                    {this.state.totalRow}
                  </span>
                </div>
              )}

              <Icon icon="clarity:envelope-line" color="#333" width={22} />
            </div>
          </DropdownToggle>
          <DropdownMenu style={{ maxHeight: "16rem", overflowY: "auto" }}>
            <DropdownItem header>Tiket Saya</DropdownItem>
            <DropdownItem divider />
            {this.state.rows && this.state.rows.length > 0 ? (
              <>
                {this.state.rows.map((item, idx) => {
                  let className = "";
                  let text = "";

                  switch (item.ticket_status) {
                    case 0:
                      className = "text-danger";
                      text = "New";
                      break;

                    case 1:
                      className = "text-success";
                      text = "Open";
                      break;

                    case 2:
                      className = "text-warning";
                      text = "Menunggu balasan";
                      break;

                    case 3:
                      className = "text-primary";
                      text = "Dibalas";
                      break;

                    case 4:
                      className = "text-purple";
                      text = "Closed";
                      break;

                    default:
                      text = "-";
                      break;
                  }
                  return (
                    <>
                      <DropdownItem
                        id={item.ticket_uuid}
                        onClick={this.toggleTicketModal}
                        toggle={false}
                      >
                        <div>
                          <ul
                            style={{ display: "flex", flexDirection: "column" }}
                            className="ticket-props"
                            title="Lihat Tiket"
                          >
                            <li
                              style={{ fontWeight: 500 }}
                              key="ticket_subject"
                            >
                              {item.ticket_subject}
                            </li>
                            <li key="ticket_content">{`${item.ticket_content.slice(
                              0,
                              30
                            )}...`}</li>
                            <hr className="my-1" />
                            <li
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key="ticket_status"
                            >
                              <span className="text-secondary">
                                {moment(item.created_at).format("DD/MM/YYY")}
                              </span>
                              <span className={className}>{text}</span>
                            </li>
                          </ul>
                        </div>
                      </DropdownItem>
                      <DropdownItem divider className="mb-0" />
                    </>
                  );
                })}
                <DropdownItem>
                  <Link
                    to="?ticket="
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Lihat Semua
                  </Link>
                </DropdownItem>
              </>
            ) : (
              <DropdownItem>
                <span
                  style={{ fontStyle: "italic", fontWeight: 300 }}
                  className="text-secondary"
                >
                  Tidak ada tiket.
                </span>
              </DropdownItem>
            )}
          </DropdownMenu>
        </ButtonDropdown>
        {this.state.showTicketModal &&
          this.renderTicketModal(this.ticketShowUuid)}
      </>
    );
  }
}

export default HelpDeskMessages;

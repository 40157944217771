import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Shimmer from "react-shimmer-effect";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

class ServerTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      menu: false,
      serverTime: "00:00:00",
      serverDate: "0000-00-00",
    };
  }

  az = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };

  timeInterval = () => {
    var startedServer = localStorage.getItem("serverTime");
    var startedLocal = localStorage.getItem("localTime");
    var currentTime = new Date().getTime();
    var excecution_time = parseInt((currentTime - startedLocal) / 1000);

    var s = new Date((parseInt(startedServer) + excecution_time) * 1000);
    this.setState({
      serverTime:
        this.az(s.getHours()) +
        ":" +
        this.az(s.getMinutes()) +
        ":" +
        this.az(s.getSeconds()),
      serverDate:
        this.az(s.getDate()) +
        "-" +
        this.az(s.getMonth() + 1) +
        "-" +
        this.az(s.getFullYear()),
    });

    /* let intervalId = setInterval(() => {
            var s = new Date((parseInt(startedServer) + excecution_time) * 1000)
            this.setState({
                serverTime: this.az(s.getHours()) + ':' + this.az(s.getMinutes()) + ':' + this.az(s.getSeconds()),
                serverDate: this.az(s.getFullYear()) + '-' + this.az(s.getMonth() + 1) + '-' + this.az(s.getDate())
            })
            ++excecution_time
        }, 1000) */
    let intervalId = setTimeout(() => {
      this.timeInterval();
    }, 1000);
    this.setState({ intervalId: intervalId });
  };

  componentDidMount() {
    clearInterval(this.state.intervalId);
    this.timeInterval();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <>
        <Dropdown
          isOpen={this.state.menu}
          toggle={() => {
            this.setState({ menu: !this.state.menu });
          }}
          className="d-none d-lg-inline-block ms-1"
        >
          <div style={{marginTop: "10px", marginLeft: "10px", marginRight: "10px"}}
          >
            <small className="text-dark">
              {this.state.serverTime === "00:00:00" ? (
                <>
                  <Shimmer>
                    <div
                      className="shimmer"
                      style={{ width: 68, height: 15 }}
                    ></div>
                  </Shimmer>
                </>
              ) : (
                this.state.serverDate
              )}
            </small>
            <div
              className="text-dark"
              style={{ fontWeight: "bold", fontSize: 16}}
            >
              {this.state.serverTime === "00:00:00" ? (
                <>
                  <Shimmer>
                    <span className="shimmer">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Shimmer>
                  :
                  <Shimmer>
                    <span className="shimmer">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Shimmer>
                  :
                  <Shimmer>
                    <span className="shimmer">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </Shimmer>
                </>
              ) : (
                this.state.serverTime
              )}
            </div>
          </div>
          <DropdownMenu
            className="dropdown-menu-end"
            style={{
              background: "transparent",
              boxShadow: "none",
              marginTop: -20,
            }}
          >
            <Flatpickr
              style={{ height: 0, padding: 0, margin: 0, border: "none" }}
              placeholder="dd M,yyyy"
              onChange={() => {}}
              options={{
                inline: true,
                altInput: false,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
              }}
            />
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }
}

export default ServerTime;
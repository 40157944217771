import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";

// import MinioClient from "../../../../services/minioClient";
import "./style.scss";

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    // const minio = new MinioClient();
    const timestamp = new Date();

    // const res = minio.put(`attachments/attachment_${timestamp}`, file);

    // if (res) {
    // resolve(res);
    // } else {
    // reject(res);
    // }

    // mini.put()

    // const xhr = new XMLHttpRequest();
    // xhr.open("POST", "https://api.imgur.com/3/image");
    // xhr.setRequestHeader("Authorization", "Client-ID 8d26ccd12712fca");
    // const data = new FormData();
    // data.append("image", file);
    // xhr.send(data);
    // xhr.addEventListener("load", () => {
    //   const response = JSON.parse(xhr.responseText);
    //   resolve(response);
    // });
    // xhr.addEventListener("error", () => {
    //   const error = JSON.parse(xhr.responseText);
    //   reject(error);
    // });
  });
}

const Wysiwyg = ({ changeValue, init = undefined, readOnly = false }) => {
  const [editorState, setEditorState] = useState(null);
  const [rawContent, setRawContent] = useState("");

  const readOnlyStyle = {
    border: "none",
    padding: 0,
  };

  useEffect(() => {
    const content = init;
    if (content) {
      setEditorState(EditorState.createWithContent(convertFromRaw(content)));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const handleContentChange = (state) => {
    if (!readOnly) {
      const content = state.getCurrentContent();
      setEditorState(state);
      setRawContent(convertToRaw(content));

      // state update untuk component parent menggunakan callback
      changeValue(JSON.stringify(rawContent));
    }
  };

  return (
    <div className="rdw-storybook-root">
      <Editor
        toolbarClassName={`rdw-storybook-toolbar ${readOnly ? "hidden" : ""}`}
        wrapperClassName="rdw-storybook-wrapper"
        editorClassName={`rdw-storybook-editor ${
          readOnly ? "readonly" : "h-24 px-2"
        } border-1-gray`}
        onEditorStateChange={handleContentChange}
        editorState={editorState}
        readOnly={readOnly}
        editorStyle={readOnly ? readOnlyStyle : null}
        wrapperStyle={readOnly ? readOnlyStyle : null}
      />
    </div>
  );
};

export default Wysiwyg;

import { Component } from "react";
import {
  Modal,
  ModalBody,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import ReactTimeAgo from "react-time-ago";
import { Link, Redirect } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Icon } from "@iconify/react";
import apiResponse from "../../../../../services/apiResponse";
import TicketBloc from "../../Blocs/TicketBloc";
import Shimmer from "react-shimmer-effect";
import { ConfirmAlert } from "../../../../../helpers/ui/alert";

class TicketPopup extends Component {
  ticketBloc = new TicketBloc();
  userdata = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        ticket_reply_sender_uuid: this.userdata.employee_uuid,
      },
    };
  }

  handlerOnSubmit = (e, values) => {
    e.preventDefault();

    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ticket_reply_ticket_uuid: this.props.uuid,
      },
    });

    this.setState({
      ...this.dataForm,
      ...values,
    });

    this.setState({
      confirm: true,
    });
  };

  handleInputChange = (e) => {
    let val, field;
    if (e.currentTarget) {
      val = e.currentTarget.value;
      field = e.currentTarget.name;
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          [field]: val,
        },
      });
    } else if (e.value && e.label) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          ticket_priority: e.value,
        },
      });
    }
  };

  submitData = async () => {
    await this.ticketBloc.fetchReply(this.state.dataForm);
  };

  componentDidMount() {
    this.ticketBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: {
                ...response,
                ticket_priority_color:
                  response.ticket_priority === 0 ||
                  response.ticket_priority === 1
                    ? "secondary"
                    : response.ticket_priority === 2
                    ? "success"
                    : response.ticket_priority === 3
                    ? "warning"
                    : "danger",
                ticket_priority_text:
                  response.ticket_priority === 0
                    ? "No Prior"
                    : response.ticket_priority === 1
                    ? "Low"
                    : response.ticket_priority === 2
                    ? "Medium"
                    : response.ticket_priority === 3
                    ? "High"
                    : "Critical",
              },
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    this.ticketBloc.fetchDetail({ uuid: this.props.uuid });

    this.ticketBloc.repliesChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: {
                ...this.state.detail,
                ticket_replies: response,
              },
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    this.ticketBloc.fetchReplies({ ticket_uuid: this.props.uuid });

    this.ticketBloc.replyChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
            dataForm: {
              ...this.state.dataForm,
              ticket_reply_content: "",
            },
          });
          this.ticketBloc.fetchReplies({ ticket_uuid: this.props.uuid });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.ticketBloc.detailChannel.unsubscribe();
  }

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.toggle}
        centered={true}
        size="lg"
      >
        <ModalBody
          className="p-3"
          style={{ maxHeight: "85vh", overflowY: "auto" }}
        >
          <Container fluid>
            <Row>
              <Col className="col-12">
                <CardTitle>Detail Ticket</CardTitle>
                <CardSubtitle className="mb-3">
                  Keterangan detil mengenai ticket
                </CardSubtitle>
                <hr />
                <Row className="mb-3">
                  <label className="col-md-3">Nama Pengadu</label>
                  <Col md="9">
                    {this.state.detail ? (
                      <>
                        {this.state.detail.ticket_init_name !== ""
                          ? this.state.detail.ticket_init_name
                          : "-"}
                      </>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "70%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3">Email Pengadu</label>
                  <Col md="9">
                    {this.state.detail ? (
                      <Link
                        to="#"
                        onClick={(e) => {
                          window.location = `mailto:${this.state.detail.ticket_init_email}`;
                          e.preventDefault();
                        }}
                      >
                        {this.state.detail.ticket_init_email !== ""
                          ? this.state.detail.ticket_init_email
                          : "-"}
                      </Link>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "25%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3">Subjek</label>
                  <Col md="9">
                    {this.state.detail ? (
                      <>
                        {this.state.detail.ticket_subject !== ""
                          ? this.state.detail.ticket_subject
                          : "-"}
                      </>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "35%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3">Layanan</label>
                  <Col md="9">
                    {this.state.detail ? (
                      <>
                        {this.state.detail.ticket_service !== ""
                          ? this.state.detail.ticket_service
                          : "-"}
                      </>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "35%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3">Prioritas</label>
                  <Col md="9">
                    {this.state.detail ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: ".5rem",
                        }}
                      >
                        <Icon
                          icon="gg:overflow"
                          className={`text-${this.state.detail.ticket_priority_color}`}
                        />
                        <span
                          className={`text-${this.state.detail.ticket_priority_color}`}
                        >
                          <strong>
                            {this.state.detail.ticket_priority !== ""
                              ? this.state.detail.ticket_priority_text
                              : "-"}
                          </strong>
                        </span>
                      </div>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "20%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3">Pesan</label>
                  <Col md="9">
                    {this.state.detail ? (
                      <div
                        className="container rounded py-3"
                        style={{ backgroundColor: "#DEE9F477" }}
                      >
                        <h5>{this.state.detail.ticket_init_name}</h5>
                        <hr className="my-1" />
                        <p>
                          {this.state.detail.ticket_content !== ""
                            ? this.state.detail.ticket_content
                            : "-"}
                        </p>
                        <p
                          className="p-0 m-0"
                          style={{ fontWeight: "500", color: "#999" }}
                        >
                          {this.state.detail.created_at ? (
                            <ReactTimeAgo
                              date={new Date(this.state.detail.created_at)}
                              locale="id-ID"
                            />
                          ) : null}
                        </p>
                      </div>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "90%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <label className="col-md-3">Balasan</label>
                  <Col md="9">
                    <div
                      className="d-flex"
                      style={{ flexDirection: "column", rowGap: "1rem" }}
                    >
                      {this.state.detail ? (
                        this.state.detail.ticket_replies ? (
                          this.state.detail.ticket_replies.length > 0 ? (
                            this.state.detail.ticket_replies.map((reply) => {
                              return (
                                <div
                                  className="container rounded py-3"
                                  style={{ backgroundColor: "#F4E4E977" }}
                                >
                                  <h5>{reply.ticket_reply_sender_name}</h5>
                                  <hr className="my-1" />
                                  <p>{reply.ticket_reply_content}</p>
                                  <p
                                    className="p-0 m-0"
                                    style={{
                                      fontWeight: "500",
                                      color: "#999",
                                    }}
                                  >
                                    <ReactTimeAgo
                                      date={new Date(reply.created_at)}
                                      locale="id-ID"
                                    />
                                  </p>
                                </div>
                              );
                            })
                          ) : (
                            <span>
                              <i>Belum ada balasan.</i>
                            </span>
                          )
                        ) : (
                          <Shimmer>
                            <div style={{ width: "100%", height: 45 }}></div>
                          </Shimmer>
                        )
                      ) : (
                        <Shimmer>
                          <div style={{ width: "100%", height: 45 }}></div>
                        </Shimmer>
                      )}
                    </div>
                  </Col>
                </Row>
                {this.state.detail ? (
                  <Row className="mb-3">
                    {this.state.detail.ticket_status !== 4 && (
                      <>
                        <label className="col-md-3">Balas Pesan</label>
                        <Col md="9">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={(e, values) =>
                              this.handlerOnSubmit(e, values)
                            }
                            onKeyPress={this.preventEnter}
                          >
                            <AvField
                              name="ticket_reply_content"
                              placeholder="Balas pesan..."
                              type="textarea"
                              errorMessage="Masukkan Keterangan"
                              className="form-control"
                              rows={6}
                              id="ticket_reply_content"
                              style={{ position: "relative" }}
                              onChange={this.handleInputChange}
                              value={this.state.dataForm.ticket_reply_content}
                            />
                            <Button
                              color="primary"
                              style={{
                                position: "absolute",
                                borderRadius: "50%",
                                right: "1.75rem",
                                bottom: "2rem",
                                boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              title="Balas Pesan"
                              type="submit"
                            >
                              <Icon icon="mdi:email-send-outline" />
                            </Button>
                          </AvForm>
                        </Col>
                      </>
                    )}
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Container>
          <ConfirmAlert
            confirmTitle="Konfirmasi!"
            confirmInfo="Apakah anda yakin akan menyimpan data ini?"
            loadingTitle="Mengirim data..."
            loadingInfo="Tunggu beberapa saat"
            successTitle="Berhasil!"
            successInfo="Data berhasil disimpan"
            failedTitle="Gagal!"
            failedInfo="Data gagal disimpan"
            showConfirm={this.state.confirm}
            showLoading={this.state.loading}
            showSuccess={this.state.success}
            showFailed={this.state.failed}
            response={this.confirmResponse}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default TicketPopup;

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import TimeAgo from "react-timeago";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import idStrings from "react-timeago/lib/language-strings/id";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const NotificationDropdown = (props) => {
  const formatter = buildFormatter(idStrings);

  const notifCount = parseInt(props.notificationCount);
  const notifList = props.notificationList;
  const [menu, setMenu] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState("auto");
  const baseUrl = window.location.href;
  const url = baseUrl.includes("admin") ? "" : "admin/";

  // Update dropdown width based on screen size
  useEffect(() => {
    const updateDropdownWidth = () => {
      if (window.innerWidth <= 576) {
        setDropdownWidth("100vw");
      } else if (window.innerWidth <= 992) { // Adjusted width for laptop size
        setDropdownWidth("50vw");
      } else {
        setDropdownWidth("30vw");
      }
    };
    updateDropdownWidth(); // Set initial width
    window.addEventListener("resize", updateDropdownWidth); // Listen to window resize
    return () => window.removeEventListener("resize", updateDropdownWidth); // Cleanup on unmount
  }, []);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block text-dark"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell text-dark"></i>
          {notifCount > 0 ? (
            <span className="badge bg-danger rounded-pill">{notifCount}</span>
          ) : null}
        </DropdownToggle>

        <DropdownMenu
          className="dropdown-menu-lg dropdown-menu-end p-0"
          style={{
            width: dropdownWidth,
            position: window.innerWidth <= 576 ? "fixed" : "absolute",
            top: "auto",
            left: window.innerWidth <= 576 ? "50%" : "auto",
            right: window.innerWidth <= 576 ? "auto" : "0",
            transform: window.innerWidth <= 576 ? "translateX(-90%)" : "none",
            
          }}
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16 text-color-dark">Notifikasi</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar>
            {notifList.length > 0 ? (
              notifList.map((notif) => (
                <Fragment key={notif.notification_id}>
                  <a
                    href={url + notif.notification_link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-reset notification-item"
                  >
                    <div className="d-flex align-items-start">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="uil-align-center-v"></i>
                        </span>
                      </div>
                      <div className="flex-1">
                        <h6 className="mt-0 mb-1 text-color-dark">
                          {notif.notification_title}
                        </h6>
                        <div className="font-size-12 text-muted text-color-dark">
                          <p className="mb-1 text-color-dark">
                            {notif.notification_description}
                          </p>
                          <p className="mb-0 text-color-dark">
                            <i className="mdi mdi-clock-outline text-color-dark"></i>{" "}
                            <TimeAgo
                              className="text-color-dark"
                              date={notif.notification_date}
                              formatter={formatter}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </Fragment>
              ))
            ) : (
              <></>
            )}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <a
              className="btn btn-sm btn-link font-size-14 text-center text-color-dark"
              href={url + "notification.html"}
              target="_blank"
              rel="noreferrer"
            >
              <i className="uil-arrow-circle-right me-1"></i>
              Lihat Semua Notifikasi
            </a>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

NotificationDropdown.propTypes = {
  notificationCount: PropTypes.number,
  notificationList: PropTypes.any,
};
const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    ...state.Notification,
  };
};
export default withRouter(connect(mapStatetoProps, {})(NotificationDropdown));

import {
  apiRequest,
  ticketCreate,
  ticketList,
  ticketClose,
  ticketDetail,
  ticketReply,
  ticketReplies,
} from "../../../../services/adapters/base";

class TicketRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      ticketCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchList = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      ticketList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchCloseTicket = async (query) => {
    return await apiRequest(
      "post",
      ticketClose,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      ticketDetail,
      {
        params: query,
      },
      true
    );
  };

  fetchReply = async (query) => {
    return await apiRequest(
      "post",
      ticketReply,
      {
        body: query,
      },
      true
    );
  };

  fetchReplies = async (query) => {
    return await apiRequest(
      "get",
      ticketReplies,
      {
        params: query,
      },
      true
    );
  };
}

export default TicketRepository;

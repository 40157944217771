import { apiRequest, meetingRegistrationCreate, meetingRegistrationList, searchEmployee } from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      meetingRegistrationCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchList = async (query) => {
    return await apiRequest(
      "get",
      meetingRegistrationList,
      {
        body: query,
      },
      true
    );
  };

  fetchSearch = async (query, cancelToken) => {
		return await apiRequest(
			"get",
			searchEmployee,
			{
				cancelToken: cancelToken,
				params: query,
			},
			true
		);
	};
}

export default CreateRepository;

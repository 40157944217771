import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import { Component } from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { ConfirmAlert } from "../../../helpers/ui/alert";
import apiResponse from "../../../services/apiResponse";
import EmployeeSelect from "../Components/EmployeeSelect";
import { AgendaContext } from "../Context/AgendaContext";
import EmployeeBloc from "../Create/Blocs/EmployeeBloc";
import DetailBloc from "../Detail/Blocs/DetailBloc";
import UpdateBloc from "./Blocs/UpdateBloc";

class IndexView extends Component {
  static contextType = AgendaContext;

  updateBloc = new UpdateBloc();
  employeeBloc = new EmployeeBloc();
  detailBloc = new DetailBloc();

  constructor(props) {
    super(props);

    this.state = {
      dataForm: {},
      detail: {},
      participants: [],
    };
  }

  submitData = async () => {
    const { dataForm } = this.context;
    await this.updateBloc.fetchUpdate(dataForm);
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;
    const queryParams = new URLSearchParams(this.props.location.search);

    e.preventDefault();
    setDataForm({
      agenda_uuid: queryParams.get("uuid"),
      ...dataForm,
      ...values,
    });

    this.setState({
      confirm: true,
    });
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.employeeBloc.employeeChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.updateBloc.updateChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: response,
              participants: response.agenda_participants.map((participant) => {
                return {
                  label: participant.employee_name,
                  value: participant.employee_nip,
                };
              }),
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ uuid: queryParams.get("uuid") });
  }

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Ubah Agenda</CardTitle>
                  <CardSubtitle className="mb-3">
                    Ubah agenda dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) =>
                      this.handlerOnSubmit(e, values)
                    }
                  >
                    <Row className="mb-3">
                      <label
                        htmlFor="agenda_name"
                        className="col-md-3 col-form-label"
                      >
                        Nama <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        {this.state.detail.agenda_uuid ? (
                          <AvField
                            name="agenda_name"
                            placeholder="Nama agenda"
                            type="text"
                            errorMessage="Masukkan nama agenda"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="agenda_name"
                            value={this.state.detail.agenda_name}
                          />
                        ) : (
                          <Shimmer>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            ></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="agenda_start"
                        className="col-md-3 col-form-label"
                      >
                        Mulai <span className="text-danger">*</span>
                      </label>
                      <Col md="4">
                        {this.state.detail.agenda_uuid ? (
                          <AvField
                            name="agenda_start"
                            type="datetime-local"
                            errorMessage="Masukkan waktu mulai"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="agenda_start"
                            value={
                              `${this.state.detail.agenda_start_date}T${this.state.detail.agenda_start_time}` ||
                              ""
                            }
                          />
                        ) : (
                          <Shimmer>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            ></div>
                          </Shimmer>
                        )}
                      </Col>
                      <label
                        htmlFor="agenda_end"
                        className="col-md-1 col-form-label"
                      >
                        Selesai <span className="text-danger">*</span>
                      </label>
                      <Col md="4">
                        {this.state.detail.agenda_uuid ? (
                          <AvField
                            name="agenda_end"
                            type="datetime-local"
                            errorMessage="Masukkan waktu selesai"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="agenda_end"
                            value={
                              `${this.state.detail.agenda_end_date}T${this.state.detail.agenda_end_time}` ||
                              ""
                            }
                          />
                        ) : (
                          <Shimmer>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            ></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="agenda_description"
                        className="col-md-3 col-form-label"
                      >
                        Keterangan
                      </label>
                      <Col md="9">
                        {this.state.detail.agenda_uuid ? (
                          <AvField
                            name="agenda_description"
                            placeholder="Keterangan agenda..."
                            type="textarea"
                            errorMessage="Masukkan Keterangan"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="agenda_description"
                            value={this.state.detail.agenda_description}
                          />
                        ) : (
                          <Shimmer>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            ></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="agenda_location"
                        className="col-md-3 col-form-label"
                      >
                        Lokasi / URL <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        {this.state.detail.agenda_uuid ? (
                          <AvField
                            name="agenda_location"
                            placeholder="Lokasi / URL agenda"
                            type="text"
                            errorMessage="Masukkan lokasi agenda"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="agenda_location"
                            value={this.state.detail.agenda_location}
                          />
                        ) : (
                          <Shimmer>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            ></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="agenda_location"
                        className="col-md-3 col-form-label"
                      >
                        Partisipan
                      </label>
                      <Col md="9">
                        {this.state.detail.agenda_uuid ? (
                          <EmployeeSelect
                            defaultValue={this.state.participants}
                          />
                        ) : (
                          <Shimmer>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            ></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <div className="form-check form-switch mb-3">
                          <AvCheckboxGroup
                            name="checkboxExample"
                            required
                            errorMessage="Agreement harus di centang"
                          >
                            <AvCheckbox
                              label="Saya yakin dan bertanggung jawab atas data ini"
                              value="1"
                            />
                          </AvCheckboxGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Link to="agenda.html">
                          <Button color="danger">
                            <i className="uil-arrow-left"></i> Kembali
                          </Button>
                        </Link>{" "}
                        <Button color="success" type="submit">
                          <i className="uil-save"></i> Simpan Perubahan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.redirect ? <Redirect to="/admin/agenda.html" /> : null}
      </>
    );
  }
}

export default withRouter(IndexView);

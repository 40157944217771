import { accessRole } from "../../helpers/access_role";
import ErrorAccess from "../../components/Error/Content/Access";

import IndexView from "./Index/View";
import CreateView from "./Create/View";
import DetailView from "./Detail/View";
import UpdateView from "./Update/View";
import { MeetingProvider } from "./Context/MeetingContext";

const RouteView = (props) => {
  const queryParams = new URLSearchParams(props.location.search);
  const _create = queryParams.get("create");
  const _detail = queryParams.get("detail");
  const _update = queryParams.get("update");
  const _delete = queryParams.get("delete");

  const renderWithProvider = (element) => {
    return <MeetingProvider>{element}</MeetingProvider>;
  };

  if (_create != null) {
    return accessRole("create") ? renderWithProvider(<CreateView />) : <ErrorAccess />;
  } else if (_update != null) {
    return accessRole("update") ? renderWithProvider(<UpdateView />) : <ErrorAccess />;
  }else if (_detail != null) {
    return accessRole("read") ? renderWithProvider(<DetailView />) : <ErrorAccess />;
  } else if (_delete != null) {
    return null;
  }
  return accessRole("read") ? renderWithProvider(<IndexView />) : <ErrorAccess />;
};
export default RouteView;

import "./Service.scss";

const Service = ({
  serviceName,
  icon,
  color,
  serviceLink,
  onClick,
  delay,
  active,
}) => {
  return (
    <div data-aos="fade-up" data-aos-duration="1100" data-aos-delay={500}>
      {serviceLink && active ? (
        <a
          className="container service-container"
          style={{ cursor: "pointer", position: "relative" }}
          href={serviceLink}
          target="_blank"
          rel="noreferrer"
        >
          <div className="header-service" style={{ backgroundColor: color }}>
            <img src={icon} alt="" />
          </div>
          <div className="service-name">
            <span>{serviceName}</span>
          </div>
          {!active && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.5)",
                zIndex: 10,
                top: 0,
                backdropFilter: "blur(2px)",
              }}
            ></div>
          )}
        </a>
      ) : (
        <div
          className="container service-container"
          onClick={active ? onClick : null}
          style={{ cursor: "pointer", position: "relative" }}
        >
          <div className="header-service" style={{ backgroundColor: color }}>
            <img src={icon} alt="" />
          </div>
          <div className="service-name">
            <span>{serviceName}</span>
          </div>
          {!active && (
            <div className="coming-soon">
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  textShadow: "0 1px 2px rgba(0,0,0,0.5)",
                  fontSize: "18pt",
                }}
              >
                COMING SOON
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Service;

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import React, { Component } from "react";
import Lottie from "react-lottie";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Modal,
  Row,
} from "reactstrap";
import dataLoad from "../../../../assets/lotties/spinner.json";
import { config } from "../../../../helpers/config_global";
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { cancelToken } from "../../../../services/adapters/base";
import apiResponse from "../../../../services/apiResponse";
import EmployeeSelect from "../../Components/EmployeeSelect";
import DataTableBloc from "../Blocs/DataTableBloc";
import CreateBloc from "../../Create/Blocs/CreateBloc";
import UpdateBloc from "../../Update/Blocs/UpdateBloc";
import DetailBloc from "../../Detail/Blocs/DetailBloc";
import { AgendaContext } from "../../Context/AgendaContext";
import "./CalendarView.scss";

class CalendarView extends Component {
  static contextType = AgendaContext;

  dataTableBloc = new DataTableBloc();
  createBloc = new CreateBloc();
  updateBloc = new UpdateBloc();
  detailBloc = new DetailBloc();

  source;
  defaultOrder = "agenda_start_date";
  defaultSize = 10;
  defaultSort = "desc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      loadingAgendas: true,
      filter: {
        filter_value: "",
        page_number: 1,
        page_size: this.defaultSize,
        sort_order: {
          column: this.defaultOrder,
          order: this.defaultSort,
        },
      },
      showModalCreate: false,
      showModalPreview: false,
      agendas: [],
      dataForm: {},
      inputStart: "",
      inputEnd: "",
      detail: {},
      submitType: "",
    };
  }

  submitData = async () => {
    const { dataForm } = this.context;
    if (this.state.submitType === "create") {
      await this.createBloc.fetchCreate(dataForm);
    } else if (this.state.submitType === "update") {
      await this.updateBloc.fetchUpdate(dataForm);
    }
  };

  handlerOnSubmit = (e, values) => {
    if (this.state.submitType === "create") {
      const { dataForm, setDataForm } = this.context;

      e.preventDefault();
      setDataForm({
        ...dataForm,
        ...values,
      });
      this.setState({
        confirm: true,
      });
    } else if (this.state.submitType === "update") {
      const { dataForm, setDataForm } = this.context;

      e.preventDefault();
      setDataForm({
        agenda_uuid: this.state.detail.agenda_uuid,
        ...dataForm,
        ...values,
      });

      this.setState({
        confirm: true,
      });
    }
  };

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      status: 1,
    };
    await this.dataTableBloc.fetchRowTable(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.setTokenAPI();
    this.dataTableBloc.rowtableChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingAgendas: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingAgendas: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            totalAgendas: response.total,
            agendas: response.result,
            calendarAgendas: response.result.map((agenda) => {
              return {
                id: agenda.agenda_uuid,
                title: agenda.agenda_name,
                start: `${agenda.agenda_start_date}T${agenda.agenda_start_time}`,
                end: `${agenda.agenda_end_date}T${agenda.agenda_end_time}`,
              };
            }),
            loadingAgendas: false,
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.updateBloc.updateChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: response,
              participants: response.agenda_participants.map((participant) => {
                return {
                  label: participant.employee_name,
                  value: participant.employee_nip,
                };
              }),
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.dataTableBloc.rowtableChannel.unsubscribe();
    this.createBloc.createChannel.unsubscribe();
    this.updateBloc.updateChannel.unsubscribe();
    this.detailBloc.detailChannel.unsubscribe();
  }

  handleEventClick = (arg) => {
    this.toggleModalPreview();
    this.loadAgenda(arg.event.id);
  };

  handleDateSelect = (arg) => {
    this.setState({
      inputStart: arg.startStr,
      inputEnd: arg.endStr,
    });
    this.toggleModalCreate();
  };

  loadAgenda = (agenda_uuid) => {
    this.detailBloc.fetchDetail({ uuid: agenda_uuid });
  };

  toggleModalCreate = () => {
    this.setState({
      showModalCreate: !this.state.showModalCreate,
      submitType: !this.state.showModalCreate ? "create" : "",
    });
  };

  toggleModalPreview = () => {
    this.setState({
      showModalPreview: !this.state.showModalPreview,
      submitType: !this.state.showModalPreview ? "update" : "",
    });
  };

  renderCardForm = (dateStart, dateEnd) => {
    let fixedEnd = dateEnd.split("-");
    fixedEnd[2] = (parseInt(fixedEnd[2]) - 1).toString();
    fixedEnd.forEach((i, idx) => {
      if (i.length < 2) fixedEnd[idx] = "0" + fixedEnd[idx];
    });
    fixedEnd = fixedEnd.join("-");
    return (
      <Card className="m-0">
        <CardBody>
          <CardTitle>Tambah Agenda</CardTitle>
          <CardSubtitle className="mb-3">
            Tambah agenda baru dengan mengisi form di bawah ini
          </CardSubtitle>
          <hr />
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
            onKeyPress={this.preventEnter}
          >
            <Row className="mb-3">
              <label htmlFor="agenda_name" className="col-md-3 col-form-label">
                Nama <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <AvField
                  name="agenda_name"
                  placeholder="Nama agenda"
                  type="text"
                  errorMessage="Masukkan nama agenda"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="agenda_name"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label htmlFor="agenda_start" className="col-md-3 col-form-label">
                Mulai <span className="text-danger">*</span>
              </label>
              <Col md="4">
                <AvField
                  name="agenda_start"
                  type="datetime-local"
                  errorMessage="Masukkan waktu mulai"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  value={`${dateStart}T00:00`}
                  id="agenda_start"
                />
              </Col>
              <label htmlFor="agenda_end" className="col-md-1 col-form-label">
                Selesai <span className="text-danger">*</span>
              </label>
              <Col md="4">
                <AvField
                  name="agenda_end"
                  type="datetime-local"
                  errorMessage="Masukkan waktu selesai"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  value={`${fixedEnd}T00:00`}
                  id="agenda_end"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_description"
                className="col-md-3 col-form-label"
              >
                Keterangan
              </label>
              <Col md="9">
                <AvField
                  name="agenda_description"
                  placeholder="Keterangan agenda..."
                  type="textarea"
                  errorMessage="Masukkan Keterangan"
                  className="form-control"
                  id="agenda_description"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_location"
                className="col-md-3 col-form-label"
              >
                Lokasi / URL <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <AvField
                  name="agenda_location"
                  placeholder="Lokasi / URL agenda"
                  type="text"
                  errorMessage="Masukkan lokasi agenda"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="agenda_location"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_location"
                className="col-md-3 col-form-label"
              >
                Partisipan
              </label>
              <Col md="9">
                <EmployeeSelect />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <div className="form-check form-switch mb-3">
                  <AvCheckboxGroup
                    name="checkboxExample"
                    required
                    errorMessage="Agreement harus di centang"
                  >
                    <AvCheckbox
                      label="Saya yakin dan bertanggung jawab atas data ini"
                      value="1"
                    />
                  </AvCheckboxGroup>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <Button color="danger" onClick={this.toggleModalCreate}>
                  <i className="uil-arrow-left"></i> Kembali
                </Button>{" "}
                <Button color="success" type="submit">
                  <i className="uil-save"></i> Simpan
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  };

  renderCardPreview = () => {
    return (
      <Card className="m-0">
        <CardBody>
          <CardTitle>Ubah Agenda</CardTitle>
          <CardSubtitle className="mb-3">
            Ubah agenda dengan mengisi form di bawah ini
          </CardSubtitle>
          <hr />
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
          >
            <Row className="mb-3">
              <label htmlFor="agenda_name" className="col-md-3 col-form-label">
                Nama <span className="text-danger">*</span>
              </label>
              <Col md="9">
                {this.state.detail.agenda_uuid ? (
                  <AvField
                    name="agenda_name"
                    placeholder="Nama agenda"
                    type="text"
                    errorMessage="Masukkan nama agenda"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="agenda_name"
                    value={this.state.detail.agenda_name}
                  />
                ) : (
                  <Shimmer>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label htmlFor="agenda_start" className="col-md-3 col-form-label">
                Mulai <span className="text-danger">*</span>
              </label>
              <Col md="4">
                {this.state.detail.agenda_uuid ? (
                  <AvField
                    name="agenda_start"
                    type="datetime-local"
                    errorMessage="Masukkan waktu mulai"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="agenda_start"
                    value={
                      `${this.state.detail.agenda_start_date}T${this.state.detail.agenda_start_time}` ||
                      ""
                    }
                  />
                ) : (
                  <Shimmer>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </Shimmer>
                )}
              </Col>
              <label htmlFor="agenda_end" className="col-md-1 col-form-label">
                Selesai <span className="text-danger">*</span>
              </label>
              <Col md="4">
                {this.state.detail.agenda_uuid ? (
                  <AvField
                    name="agenda_end"
                    type="datetime-local"
                    errorMessage="Masukkan waktu selesai"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="agenda_end"
                    value={
                      `${this.state.detail.agenda_end_date}T${this.state.detail.agenda_end_time}` ||
                      ""
                    }
                  />
                ) : (
                  <Shimmer>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_description"
                className="col-md-3 col-form-label"
              >
                Keterangan
              </label>
              <Col md="9">
                {this.state.detail.agenda_uuid ? (
                  <AvField
                    name="agenda_description"
                    placeholder="Keterangan agenda..."
                    type="textarea"
                    errorMessage="Masukkan Keterangan"
                    className="form-control"
                    id="agenda_description"
                    value={this.state.detail.agenda_description}
                  />
                ) : (
                  <Shimmer>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_location"
                className="col-md-3 col-form-label"
              >
                Lokasi / URL <span className="text-danger">*</span>
              </label>
              <Col md="9">
                {this.state.detail.agenda_uuid ? (
                  <AvField
                    name="agenda_location"
                    placeholder="Lokasi / URL agenda"
                    type="text"
                    errorMessage="Masukkan lokasi agenda"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="agenda_location"
                    value={this.state.detail.agenda_location}
                  />
                ) : (
                  <Shimmer>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_location"
                className="col-md-3 col-form-label"
              >
                Partisipan
              </label>
              <Col md="9">
                {this.state.detail.agenda_uuid ? (
                  <EmployeeSelect defaultValue={this.state.participants} />
                ) : (
                  <Shimmer>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <div className="form-check form-switch mb-3">
                  <AvCheckboxGroup
                    name="checkboxExample"
                    required
                    errorMessage="Agreement harus di centang"
                  >
                    <AvCheckbox
                      label="Saya yakin dan bertanggung jawab atas data ini"
                      value="1"
                    />
                  </AvCheckboxGroup>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <Button color="danger" onClick={this.toggleModalPreview}>
                  <i className="uil-arrow-left"></i> Kembali
                </Button>{" "}
                <Button color="success" type="submit">
                  <i className="uil-save"></i> Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  };

  renderLoading = () => {
    return (
      <div
        style={{
          minWidth: "100%",
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "65vh",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: dataLoad,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={120}
            width={120}
          />
          <span>Sedang mengambil data...</span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.calendarAgendas ? (
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            dateClick={this.handleDateClick}
            events={this.state.calendarAgendas}
            eventClick={this.handleEventClick}
            select={this.handleDateSelect}
            selectable={true}
          />
        ) : (
          this.renderLoading()
        )}

        <Modal
          centered
          size="xl"
          toggle={this.toggleModalCreate}
          isOpen={this.state.showModalCreate}
          backdrop="static"
        >
          {this.state.showModalCreate
            ? this.renderCardForm(this.state.inputStart, this.state.inputEnd)
            : this.renderLoading()}
        </Modal>

        <Modal
          centered
          size="xl"
          toggle={this.toggleModalPreview}
          isOpen={this.state.showModalPreview}
          backdrop={true}
        >
          {this.state.showModalPreview
            ? this.renderCardPreview()
            : this.renderLoading()}
        </Modal>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
      </>
    );
  }
}

export default CalendarView;

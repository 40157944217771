import {
  AvField,
  AvForm,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import { ConfirmAlert } from "../../../helpers/ui/alert";
import { Link } from "react-router-dom";
import apiResponse from "../../../services/apiResponse";
import CreateBloc from "./Blocs/CreateBloc";
import Wysiwyg from "../Detail/Components/Wysiwyg";
import { FileInput, generateFormData } from "../../../helpers/ui/FileInput";
import CustomSelect from "../../../helpers/ui/CustomSelect"

class IndexView extends Component {
  createBloc = new CreateBloc();

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        announcement_created_by: JSON.parse(localStorage.getItem("userdata"))
          .employee_uuid,
      },
      optPopupAnnouncement: [
        {
          label: "Ya",
          value: "1"
        },
        {
          label: "Tidak",
          value: "0"
        }
      ],
    };
  }

  submitData = async () => {
    const formData = generateFormData(this.state.dataForm, {
      field: "attachment_detail",
      name: "announcementAttachments/attachment",
    });
    await this.createBloc.fetchCreate(formData);
  };

  handlerOnSubmit = (e, values) => {
    e.preventDefault();

    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ...values,
      },
    });
    this.setState({
      confirm: true,
    });
  };

  handleContentChange = (value, valueHtml) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        announcement_content: value,
        announcement_content_html: valueHtml,
      },
    });
  };

  handleTitleChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        [e.currentTarget.name]: e.currentTarget.value,
      },
    });
  };

  preventEnter = (e) => {
    if (e.which === 13 && !e.target.getAttribute("role")) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  renderCardForm = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>Tambah Pengmuman</CardTitle>
          <CardSubtitle className="mb-3">
            Tambah pengumuman baru dengan mengisi form di bawah ini
          </CardSubtitle>
          <hr />
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, values) => {
              this.handlerOnSubmit(e, values);
            }}
            onKeyPress={this.preventEnter}
            ref={this.formRef}
          >
            <Row className="mb-3">
              <label
                htmlFor="announcement_title"
                className="col-md-3 col-form-label"
              >
                Judul <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <AvField
                  name="announcement_title"
                  placeholder="Masukkan judul pengumuman..."
                  type="text"
                  errorMessage="Judul tidak boleh kosong."
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="announcement_title"
                  onChange={this.handleTitleChange}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="announcement_content"
                className="col-md-3 col-form-label"
              >
                Isi pengumuman <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <Container
                  style={{
                    overflow: "hidden",
                    minHeight: "18rem",
                    padding: "0",
                  }}
                >
                  <Wysiwyg changeValue={this.handleContentChange} />
                  <AvField
                    name="announcement_content"
                    type="hidden"
                    value={this.state.dataForm.announcement_content}
                  />
                </Container>
              </Col>
            </Row>

            <Row className="mb-3">
              <label
                htmlFor="is_popup_announcement"
                className="col-md-3 col-form-label"
              >
                Set sebagai popup <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <CustomSelect
                  name="is_popup_announcement"
                  id="is_popup_announcement"
                  options={this.state.optPopupAnnouncement}
                  validate={{ required: { value: true } }}
                  defaultValue="0"
                  placeholder="Set sebagai popup" />
              </Col>
            </Row>

            <Row className="mb-3">
              <label className="col-md-3 col-form-label">Lampiran</label>
              <Col md={9}>
                <FileInput
                  name="attachment_detail"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <div className="form-check form-switch mb-3">
                  <AvCheckboxGroup
                    name="checkboxAgreement"
                    required
                    errorMessage="Agreement harus di centang"
                  >
                    <AvCheckbox
                      label="Saya yakin dan bertanggung jawab atas data ini"
                      value="1"
                    />
                  </AvCheckboxGroup>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <Link to="pengumuman.html">
                  <Button color="danger">
                    <i className="uil-arrow-left"></i> Kembali
                  </Button>
                </Link>{" "}
                <Button color="success" type="submit">
                  <i className="uil-save"></i> Simpan
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  };

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col className="col-12">{this.renderCardForm()}</Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.redirect ? <Redirect to="/admin/pengumuman.html" /> : null}
      </>
    );
  }
}

export default IndexView;

import { Icon } from "@iconify/react";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import React from "react";
import LinesEllipsis from 'react-lines-ellipsis';
import Lottie from "react-lottie";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Waypoint } from "react-waypoint";
import { Card, CardBody, CardText, Col, Modal, ModalBody } from "reactstrap";
import dataLoad from "../../../../../assets/lotties/spinner.json";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import MinioClient from "../../../../../services/minioClient";
import GuideBloc from "../../Blocs/GuideBloc";
import { Document, Page, pdfjs } from 'react-pdf';
import VideoThumbnail from 'react-video-thumbnail';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import "./index.scss";
class PanduanSection extends React.Component {
  
  static contextType = GlobalContext;
  guideBloc = new GuideBloc();
  minioClient = new MinioClient();
  source;
  constructor(props) {
    super(props);
    this.state = {
      loadingGuide: false,
      defaultSize: 10,
      guideCategories: [],
      guides: null,
      modalPdfPath: null,
      showModal: false,
      showModalGuide: false,
      numPages: null,
      pageNumber: 1,
      filter: {
        filter_value: "",
        page_number: 1,
        page_size: this.defaultSize,
        sort_order: {
          column: this.defaultOrder,
          order: this.defaultSort,
        },
      },
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages: numPages
    })
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  renderLoading = () => {
    return (
      <div
        style={{
          minWidth: "100%",
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "35vh",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: dataLoad,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={120}
            width={120}
          />
          <span>Sedang mengambil data...</span>
        </div>
      </div>
    );
  };

  loadData = async () => {
    /* kirim ke server */
    const query = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
    };
    await this.guideBloc.fetchCategories(query, this.source.token);
  };

  loadGuideList = async (guide_category_id) => {
    this.setState({ guides: null })
    /* kirim ke server */
    const query = {
      search: guide_category_id,
      limit: 100,
      offset: null,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
    };
    await this.guideBloc.fetchList(query, this.source.token);
  };

  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.setTokenAPI();

    this.guideBloc.guideCategoryChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            guideCategories: response.result,
          });

          response.result.map((category) => {
            this.setGuideCategoryIcon(category)
          })

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.guideBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingGuide: true
          })
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingGuide: true
          })
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            guides: response.result,
            showModalGuide: true,
            loadingGuide: false
          });

          response.result.filter((val) => val.guide_type == "video").map(
            (guide, i) => {
              this.setVideoLink(guide);
            }
          );

          response.result.filter((val) => val.guide_type == "pdf").map(
            (guide, i) => {
              this.setPdfLink(guide);
            }
          );

          break;
        case apiResponse.ERROR:
          this.setState({
            loadingGuide: false
          })
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.guideBloc.guideCategoryChannel.unsubscribe();
    this.guideBloc.listChannel.unsubscribe();
  }

  hanldeWaypointEnter = () => {
    const { setActiveNav } = this.context;

    setActiveNav("Panduan");
  };

  setGuideCategoryIcon = (category) => {
    this.minioClient.get(category.attachment_path).then((url) => {
      this.setState({ [`categoryIconUrl${category.guide_category_id}`]: url });
    });
  }

  setPdfLink = (guide) => {
    if (guide.guide_type == "pdf") {
      this.minioClient.get(guide.attachment_path).then((url) => {
        this.setState({ [`pdfUrl${guide.guide_id}`]: url });
      });
    }
  }

  setVideoLink = (guide) => {
    if (guide.video_type == "upload") {
      this.minioClient.get(guide.attachment_path).then((url) => {
        this.setState({ [`videoUrl${guide.guide_id}`]: url });
        this.setVideoThumbnail(guide, url)
      });

    } else {
      this.setState({ [`videoUrl${guide.guide_id}`]: guide.youtube_link });
      this.setVideoThumbnail(guide, guide.youtube_link)
    }
  }

  setVideoThumbnail = (guide, url) => {
    if (guide.video_type == "youtube") {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      var videoId = (match && match[7].length == 11) ? match[7] : "";
      var videoThumbnail = `http://img.youtube.com/vi/${videoId}/0.jpg`;
      this.setState({ [`videoThumbnail${guide.guide_id}`]: videoThumbnail });
    }
  }

  modalGuide = () => (
    <div>
      <Modal isOpen={true} toggle={true} centered={true} size="lg">
        <ModalBody className="app-modal-body">
          <div className="close-btn" onClick={() => this.setState({ showModalGuide: false })}>
            <Icon icon="icomoon-free:cross" width={20} />
          </div>
          <div className="container-fluid">
            <div className="row mt-4 mb-4">
                {this.state.guides.length > 0 ?
                  this.state.guides.map(
                    (guide, i) => {
                      return (
                        <div className="col-md-4" key={i}>
                          <div style={{ 
                            background: '#FFFFFF',
                            marginBottom: 24,
                            boxShadow: '0px 6px 8px 2px rgba(98, 115, 205, 0.12)',
                            borderRadius: '8px',
                            padding: 8
                          }} >
                              <div className="card-video" style={{ 
                                height: '15rem',
                                width: '100%',
                                left: '14.39%',
                                right: '66.05%',
                                top: '16.97%',
                                bottom: '66.06%',
                                background: 'rgba(0, 0, 0, 0.42)',
                                textAlign: "center",
                                borderRadius: '8px 8px 0px 0px' }}>
                                {guide.guide_type == "pdf" 
                                ?
                                  <div style={{ position: 'relative', width:'100%', height:'100%' }} onClick={() => this.showModal(guide.attachment_path)} >
                                    <Document file={this.state[`pdfUrl${guide.guide_id}`]} noData="Data tidak ditemukan">
                                      <Page pageNumber={1} />
                                    </Document>
                                    <div className="background-blur"></div>
                                    <div className="icon-center"> 
                                      <img src='/img/icons/eye.svg' style={{ width:'100%',
                                      height:'100%'}} alt=""/>
                                    </div>
                                  </div>
                                : 
                                  guide.video_type == "youtube" 
                                  ? (
                                    <div style={{ position: 'relative', width:'100%', height:'100%' }} onClick={() => this.showModalVideo(guide)} >
                                      <img src={this.state[`videoThumbnail${guide.guide_id}`]} style={{ width:'100%', height:'100%', objectFit: 'cover'}} alt="" />
                                      <div className="background-blur"></div>
                                      <div className="icon-center"> 
                                        <img src='/img/icons/play.svg' style={{ width:'100%', height:'100%'}} alt="" />
                                      </div>
                                    </div>
                                  ) 
                                  : 
                                    <div style={{ position: 'relative', width:'100%', height:'100%' }} onClick={() => this.showModalVideo(guide)} >
                                      <VideoThumbnail videoUrl={this.state[`videoUrl${guide.guide_id}`]}  style={{ width:'100%', height:'100%', objectFit: 'cover'}} />
                                      <div className="background-blur"></div>
                                      <div className="icon-center"> 
                                        <img src='/img/icons/play.svg' style={{ width:'100%', height:'100%'}} alt=""  />
                                      </div>
                                    </div>
                                }
                              </div>
                              <h6 className="mt-4">
                                <LinesEllipsis
                                  text={guide.guide_name}
                                  maxLine='3'
                                  ellipsis='...'
                                  trimRight
                                  basedOn='letters' />
                              </h6>
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                <div style={{ 
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'flex-start',
                                  padding: '4px 11px',
                                  gap: '10px',
                                  background: guide.guide_type == "pdf" ? '#F7A679' : '#A0BEF8',
                                  borderRadius: '3px',
                                  color: 'white'
                                }}>{guide.guide_type == "pdf" ? "PDF" : "Video"}</div>
                              </div>
                          </div>
                        </div>
                      )
                    }
                  )
                : <center><h2
                style={{ fontWeight: "400"}}
              >
                Data Kosong
              </h2>
              </center>}
                
            </div>
          </div >
        </ModalBody>
      </Modal>
    </div>
  );

  modalPdf = () => (
    <div>
      <Modal isOpen={true} toggle={true} centered={true} size="lg">
        <ModalBody className="app-modal-body">
          <div className="close-btn" onClick={() => this.setState({ showModal: false })}>
            <Icon icon="icomoon-free:cross" width={20} />
          </div>
            <div style={{ height: window.innerHeight }}>
              <Viewer fileUrl={this.state.modalPdfPath} />
            </div>
        </ModalBody>
      </Modal>
    </div>
  );

  modalVideo = () => (
    <div>
      <Modal isOpen={true} toggle={true} centered={true} size="lg">
        <div class="modal-content" style={{ height: "400px", maxHeight: "400px" }}>
          <ModalBody className="app-modal-body">
            <div className="close-btn" onClick={() => this.setState({ showModalVideo: false })}>
              <Icon icon="icomoon-free:cross" width={20} />
            </div>

            <iframe allowFullScreen={true} style={{ position: 'relative', height: '100%', width: '100%' }} src={this.state.videoUrl} />

          </ModalBody>
        </div>
      </Modal>
    </div>
  );

  showModalGuide = (path) => {
    this.setState({
      showModalGuide: true
    });
  }

  showModal = (path) => {
    this.minioClient.get(path).then((url) => {
      this.setState({
        modalPdfPath: url,
        showModal: true
      });
    });
  }

  showModalVideo = (guide) => {
    this.setState({
      videoUrl: this.state[`videoUrl${guide.guide_id}`],
      showModalVideo: true
    });
  }

  renderCategories = () => (
    <div className="row mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {this.state.guideCategories.map(
        (category, i) => {
          return (
            <div
              className="container guide-container"
              onClick={() => this.loadGuideList(category.guide_category_id)}
              // data-aos="fade-up" data-aos-duration="1100" data-aos-delay={500}
              style={{ cursor: "pointer", position: "relative", backgroundColor: "white" }}
            >

              <div className="header-guide" style={{ backgroundColor: category.category_background_color }}>
                <img src={this.state[`categoryIconUrl${category.guide_category_id}`]} alt="" />
              </div>
              <div className="guide-name">
                <span>{category.category_name}</span>
              </div>
            </div>
          )
        }
      )}

    </div>
  )


  renderVideoGuides = () => (
    <div className="container-fluid">
      <div className="row mt-4 mb-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Carousel responsive={this.sliderResponsive} containerClass="carousel-container" swipeable={true} draggable={true}>
          {this.state.guides.filter((val) => val.guide_type == "video").map(
            (guide, i) => {
              return (
                <div className="d-lg-flex" key={i + 1}>
                  <div className="card-video border-0 me-lg-4 mb-lg-4 mb-4 mt-4">
                    <div className="backgroundEffect"></div>
                    {guide.video_type == "youtube" ? (
                      <div className="pic"> <img src={this.state[`videoThumbnail${guide.guide_id}`]} alt="" />
                        <div className="date"> <span className="day">Video</span></div>
                      </div>
                    ) : <div className="pic"> <VideoThumbnail
                      videoUrl={this.state[`videoUrl${guide.guide_id}`]} />
                      <div className="date"> <span className="day">Video</span></div>
                    </div>}

                    <div className="content">
                      <h6 className="mt-4">
                        <LinesEllipsis
                          text={guide.guide_name}
                          maxLine='5'
                          ellipsis='...'
                          trimRight
                          basedOn='letters' />
                      </h6>
                      <div className="d-flex align-items-center justify-content-between mt-3 pb-3">
                        <div className="btn btn-primary" onClick={() => this.showModalVideo(guide)}>Putar video<span className="fas fa-play"></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </Carousel>
      </div>
    </div>
  )

  sliderResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  render() {
    return (
      <section
        className="panduan-section"
        style={{ backgroundColor: "white" }}
        id="panduan"
      >
        <div className="container">
          
          <Waypoint scrollableAncestor={window} onEnter={this.hanldeWaypointEnter} />
          {this.state.guideCategories ? (
            < this.renderCategories />
          ) : this.renderLoading()}
          {this.state.guides ? (
            <hr />) : null}
          {this.state.guides ? (
            <hr />) : null}
        </div>

        {this.state.guides && this.state.showModalGuide ? (
          <this.modalGuide />
        ) : this.state.loadingGuide ? this.renderLoading() : null}

        {this.state.showModal ? (
          <this.modalPdf />
        ) : null}

        {this.state.showModalVideo ? (
          <this.modalVideo />
        ) : null}
      </section>
    );
  }
}

export default PanduanSection;

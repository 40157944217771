import { runWithAdal } from "react-adal";
import { authContext } from "./services/adalConfig";
import TimeAgo from "javascript-time-ago";
import id from "javascript-time-ago/locale/id";
import "aos/dist/aos.css";
import "./index.css";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AOS from "aos";
import store from "./store"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

TimeAgo.addDefaultLocale(id);
AOS.init({once: true});

const DO_NOT_LOGIN = false;
const currentUrl = window.location.href;

if (currentUrl.includes("/attendance_ext.html")) {
  const app = (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
  ReactDOM.render(app, document.getElementById("root"))
} else {
  runWithAdal(
    authContext,
    () => {
      require("./preload.jsx");
    },
    DO_NOT_LOGIN
  );
}


import React, { useState, useEffect } from "react";
import Shimmer from "react-shimmer-effect";
import { Dropdown, DropdownToggle, DropdownMenu, Modal } from "reactstrap";
import Select from "react-select";
import { authContext } from "../../../services/adalConfig";
import avatarMale from "../../../assets/images/avatar_male.png";
import avatarFemale from "../../../assets/images/avatar_female.png";

import { Link } from "react-router-dom";

const ProfileMenu = (props) => {
	const userdata = JSON.parse(localStorage.getItem("userdata"));

	const [menu, setMenu] = useState(false);

	const [email, setemail] = useState("");
	const [username, setusername] = useState("");
	const [userphoto, setuserphoto] = useState("");
	const [useravatar, setuseravatar] = useState(avatarMale);

	const [modalChangeRoleOpen, setModalChangeRoleOpen] = useState(false);
	const [selectedAccessRole, setSelectedAccessRole] = useState({
		label: localStorage.getItem("currentRoleName"),
		value: localStorage.getItem("currentRoleId"),
	});
	const listAccessRole = JSON.parse(sessionStorage.getItem("allRole"));
	const itemAccessRole = [];
	listAccessRole.map((role) => {
		itemAccessRole.push({ label: role.access_name, value: role.access_id });
		return null;
	});
	const optionAccessRole = [
		{
			label: "Akses Role",
			options: itemAccessRole,
		},
	];
	function handleSelectAccessRole(selectedAccessRole) {
		setSelectedAccessRole(selectedAccessRole);
	}
	function toggleChangeRole(cmd) {
		if (cmd === "open") {
			setModalChangeRoleOpen(true);
		}
		if (cmd === "close") {
			setModalChangeRoleOpen(false);
		}
	}

	useEffect(() => {
		if (authContext.getCachedUser()) {
			setusername(userdata.employee_name);
			setemail(userdata.employee_email_internal);
			setuserphoto(
				userdata.employee_image_file_baseurl + userdata.employee_image_file_path
			);
			if (userdata.employee_gender === "P") {
				setuseravatar(avatarFemale);
			}
		}
	}, []);

	const addDefaultPhoto = (ev) => {
		ev.target.src = useravatar;
	};

	return (
		<React.Fragment>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className="d-inline-block"
			>
				<DropdownToggle
					className="btn header-item waves-effect"
					id="page-header-user-dropdown"
					tag="button"
				>
					{userphoto === "" ? (
						<Shimmer>
							<div
								className="rounded-circle header-profile-user"
								style={{
									verticalAlign: "middle",
								}}
							/>
						</Shimmer>
					) : (
						<img
							onError={addDefaultPhoto}
							className="rounded-circle header-profile-user"
							src={userphoto}
							alt=""
						/>
					)}
					{username === "" ? (
						<Shimmer>
							<span
								className="d-none d-xl-inline-block ms-1 fw-medium font-size-15"
								style={{ width: 120 }}
							>
								&nbsp;
							</span>
						</Shimmer>
					) : (
						<span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15 text-dark">
							{username}{" "}
						</span>
					)}
					<i className="uil-angle-down d-none d-xl-inline-block font-size-15 text-dark"></i>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<div className="dropdown-item" style={{ pointerEvents: "none" }}>
						<i className="dripicons-scale font-size-18 align-middle me-1 text-muted"></i>
						{localStorage.getItem("currentRoleName")}
					</div>
					<div className="dropdown-divider" />
					<Link to="/home/profile.html" className="dropdown-item">
						<i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
						{email}
					</Link>
					<div className="dropdown-divider" />
					<Link to="/logout.html" className="dropdown-item">
						<i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
						Keluar
					</Link>
				</DropdownMenu>
			</Dropdown>
			<Modal size="sm" isOpen={modalChangeRoleOpen} toggle={() => {}}>
				<div className="modal-header">
					<h5 className="modal-title mt-0" id="myModalLabel">
						Ganti Hak Akses
					</h5>
					<button
						type="button"
						onClick={() => {
							toggleChangeRole("close");
						}}
						className="close"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					<Select
						value={selectedAccessRole}
						onChange={(selected) => {
							//console.log(selected)
							handleSelectAccessRole(selected);
						}}
						options={optionAccessRole}
						classNamePrefix="select2-selection"
					/>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						onClick={() => {
							toggleChangeRole("close");
						}}
						className="btn btn-danger waves-effect"
						data-dismiss="modal"
					>
						Batal
					</button>
					<button
						type="button"
						onClick={() => {
							localStorage.setItem("currentRoleId", selectedAccessRole.value);
							localStorage.setItem("currentRoleName", selectedAccessRole.label);
							toggleChangeRole("close");
							window.location.reload();
						}}
						className="btn btn-primary waves-effect waves-light"
					>
						Ganti
					</button>
				</div>
			</Modal>
		</React.Fragment>
	);
};

/* ProfileMenu.propTypes = {
  notificationCount: PropTypes.number,
  notificationList: PropTypes.any
}
const mapStatetoProps = state => {
  return {
    ...state.Layout,
    ...state.Notification
  }
}
export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
) */
export default ProfileMenu;

import React, { useState, useEffect } from "react";
import { AvField } from 'availity-reactstrap-validation'
import ReactDatePicker, { registerLocale, subDays } from "react-datepicker";
import id from "date-fns/locale/id";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
registerLocale("id", id);

const DatePicker = (props) => {
    const [ compValue, setCompValue ] = useState();
    const [selectedDate, setSelectedDate] = useState(null);
    const [pMinDate, setPMinDate] = useState(null);
    const [pMaxDate, setPMaxDate] = useState(null);
    const [pExcludeDates, setpExcludeDates] = useState(null);
    const weekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const { 
        name, 
        errorMessage, 
        defaultValue = null, 
        validate, 
        onChange = null,
        placeholder = "dd/mm/yyyy",
        isAvForm = true,
        dateFormat = "dd/MM/yyyy",
        selected = new Date(),
        isWeekday = true,
        minDate = null,
        maxDate = null,
        excludeDates = null,
    } = props

    useEffect(() => {
        defaultVal(defaultValue)
    }, [defaultValue, minDate, maxDate, excludeDates])

    const handleChange = (data) => {
        setCompValue(moment(data).format('YYYY-MM-DD'))
        setSelectedDate(data)
        if(onChange) {
            onChange(data)
        }
    }

    const defaultVal = (val) => {
        try {
            if (val) {
                setCompValue(val)
                setSelectedDate(new Date(val))
            }
            if (minDate){
                setPMinDate(new Date(minDate))
            }
            if (maxDate){
                setPMaxDate(new Date(maxDate))
            }
            if (excludeDates){
                setpExcludeDates(excludeDates)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <ReactDatePicker 
                selected={selectedDate} 
                placeholderText={placeholder}
                onChange={handleChange}
                className="form-control"
                filterDate={isWeekday ? weekday : null}
                dateFormat={dateFormat}
                locale="id"
                minDate={pMinDate}
                maxDate={pMaxDate}
                excludeDates={pExcludeDates}
                showMonthDropdown
                showYearDropdown
            />
            
            { isAvForm &&
                <AvField 
                    errorMessage={errorMessage}
                    name={name}
                    type="hidden" 
                    value={compValue} 
                    validate={validate}
                />
            }
        </>
    )
}

export default DatePicker
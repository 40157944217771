import { apiRequest, ticketList } from "../../../../services/adapters/base";

class DataTableRepository {
	fetchRowTable = async (query, cancelToken) => {
		return await apiRequest(
			"get",
			ticketList,
			{
				cancelToken: cancelToken,
				params: query,
			},
			true
		);
	};
}

export default DataTableRepository;

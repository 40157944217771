import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Button, Row, Col, Label, Input } from "reactstrap"
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  FileInput,
  generateFormData
} from "../../../../helpers/ui/FileInput";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import listenGif from "../../../../assets/videos/listening.gif"
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { Link, Redirect } from "react-router-dom";
import CustomSelect from "../../../../helpers/ui/CustomSelect"
import reviewIcon from '../../../../assets/icons/icon_review_summary.png';
import DatePicker from '../../../../helpers/ui/DatePicker'
import Lottie from "react-lottie";
import animationData from "../../../../assets/lotties/nlp.json"
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function LegalisirDokumenView(props) {

  const baseUrl = process.env.REACT_APP_SIMPEG_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_SIMPEG_SERVICE_BASE_APPCODE;
  const title = "Pengajuan Legalisir Dokumen";
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentAccessToken, setCurrentAccessToken] = useState()
  const questions = ["Silakan pilih jenis legalisir", "Silakan tulis untuk keperluan apa"];
  const questionTotal = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const legalizeTypes = [
    { value: 1, label: 'SK PNS' },
    { value: 2, label: 'SK Pangkat' },
    { value: 3, label: 'SK Jabatan' },
  ]
  
  const [selectedLegalizeType, setSelectedLegalizeType] = useState({label: null, value: null})
  const [selectedLegalizeReason, setSelectedLegalizeReason] = useState()

  const [confirm, setConfirm] = useState()
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [failed, setFaield] = useState()
  const [redirect, setRedirect] = useState()


  useEffect(() => {
    const fetchAccessToken = () => {
      try {
        axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCode,
            email: userData.employee_email_internal
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentAccessToken(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAccessToken()
  }, []); 


  const submitData = async () => {
    const dataForm = {
      "uuid": userData.employee_uuid,
      "online_submission_type": 1,
      "online_submission_legalize_type": selectedLegalizeType.value,
      "online_submission_purpose": selectedLegalizeReason
  }

    const response = await axios.post(`${baseUrl}Kepegawaian/pengajuanOnline/create/json`, dataForm, {
      headers: {
        appCode: appCode,
        email: userData.employee_email_internal,
        accessToken: currentAccessToken
      },
    });

    if (response.data.status == true) {
      setSuccess(true)
    } else {
      setFaield(true)
    }
    setLoading(false)
  }

  const confirmResponse = (response) => {
    switch (response) {
      case "cancel":
        setConfirm(false)
        break;
      case "confirm":
        setLoading(true)
        setConfirm(false)        
        submitData();
        break;
      case "success":
        setSuccess(false)
        setRedirect(true)
        break;
      case "failed":
        setFaield(false)
        break;
      default:
    }
  };

  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const startListen = () => {
    SpeechRecognition.startListening({ language: 'id'})
  }

  useEffect(() => {
    setSelectedLegalizeReason(transcript)
  }, [transcript]);

  const showButton = (showNextButton) => {
    return (
      <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", bottom: 0, right: 0, zIndex: 99 }}>
          {currentQuestion > 1 && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              Kembali
            </Button> 
          )}

          {showNextButton == true && currentQuestion != questionTotal && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Selanjutnya
            </Button> 
          )}

          {showNextButton == true && currentQuestion == questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Review
          </Button> 
          )}

          {currentQuestion > questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setConfirm(true)}
          >
            Submit
          </Button> 
          )}
        </div>
    )
  }

  const showLottie = () => {
    return <Lottie options={{ 
      loop: true,
      autoplay: true, 
      animationData: animationData
     }}
      isClickToPauseDisabled={true}
      height="100%"
      width="100%"
      isStopped={false}
      isPaused={false}/>
  }
  
  return (
    <>
      {currentQuestion <= questionTotal && (
        <div className="mb-4" style={{ position: "fixed", top: 0, width: "100%" }}>
          <Progress color="info" value={currentQuestion / questionTotal * 100} />
        </div>
      )}
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {currentQuestion <= questionTotal ? (
          <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99 }}>
            Pertanyaan {currentQuestion} dari {questionTotal}
          </div>
        ) : <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
        <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
      </div>}

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px" }} >
        
        { currentQuestion == 1 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[0]}</p>

                { legalizeTypes.map((type, i) => (
                  <Button
                    key={i}
                    color="primary"
                    value={type.value}
                    outline
                    className="waves-effect waves-light"
                    onClick={(event) => {
                      setSelectedLegalizeType(type)
                      setCurrentQuestion(currentQuestion + 1)
                    }}
                    style={{
                      margin: "5px", 
                      borderRadius: "20px",
                      borderColor: "#3FC057",
                      background: selectedLegalizeType.value == type.value ? "#3FC057" : "rgba(63, 192, 87, 0.05)", 
                      color: selectedLegalizeType.value == type.value ? 'white' : '#3FC057'
                    }}
                  >
                    {type.label}
                  </Button>
                ))}
              {showButton(selectedLegalizeType.value != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          )
        }

        { currentQuestion == 2 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[1]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  type="text"
                  value={selectedLegalizeReason}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setSelectedLegalizeReason(event.target.value)
                  }}
                />
                <i className="uil-microphone" onClick={startListen}  style={{ 
                    fontSize: '24px',  
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    cursor: 'pointer',}}/>
              </div>
            </div>
            <div className="col-md-6">
              {listening ? (
                <div className="centered-horizontal">
                  <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
                </div>
              ) : showLottie()}
            {showButton(selectedLegalizeReason != null && selectedLegalizeReason != "")}
            </div>
          </Row>
        )}

        { currentQuestion == questionTotal + 1 && ( 
          <div className="col-md-12" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h5 className="nlp-content">Resume</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "5vw" }}>
              <Row>
                <Col md="auto">
                  <div> 
                    <div className="centered-horizontal" style={{ marginBottom: "10px" }}>
                      <img src={reviewIcon}/>
                    </div>
                  </div>
                </Col>
                <Col md={8} className="centered-vertical">
                  <Col md="auto">
                    <div>
                      <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Jenis Legalisir</Label>
                      <p className="nlp-sub-content">{selectedLegalizeType.label}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Keperluan</Label>
                      <p className="nlp-sub-content">{selectedLegalizeReason}</p>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
            {showButton(false)}
          </div>
         ) }
        </AvForm>
      </div>
      <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={confirm}
          showLoading={loading}
          showSuccess={success}
          showFailed={failed}
          response={confirmResponse}
        />
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default LegalisirDokumenView;

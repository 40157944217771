import { Component } from "react";
import Header from "../Attendance/components/Header/header";
import { withRouter } from "react-router-dom";
import './attendance.scss';
import apiResponse from "../../../services/apiResponse";
import { Dna } from  'react-loader-spinner'
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledAlert,
  CardText
} from "reactstrap"

import AttendanceBloc from "./Blocs/AttendanceBloc";

class Attendance extends Component {
  attendanceBloc = new AttendanceBloc();
  userData = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.attendanceBloc.attendanceChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          this.form && this.form.reset();
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    const queryParams = new URLSearchParams(this.props.location.search);

    this.setState({
      loading: true
    });

    this.attendanceBloc.fetchCreate({
      meeting_registration_attendance_registration_uuid: queryParams.get("uuid"),
      meeting_registration_attendance_name: this.userData.employee_name,
      meeting_registration_attendance_position: this.userData.employee_current_position_name,
      meeting_registration_attendance_no_telp: this.userData.employee_phone_number,
      meeting_registration_attendance_email: this.userData.employee_email_internal,
      meeting_registration_attendance_nip: this.userData.employee_nip
    });
  }

  componentWillUnmount() {
    this.attendanceBloc.attendanceChannel.unsubscribe();
  }

  render() {
    return (
      <>
        <div
          className="main"
          style={{
            background: "#F8F9F9",
            position: "relative",
            minHeight: "100vh",
          }}
        >
          <img
            src="img/ornaments/Pengumuman_bg.svg"
            alt="ornaments"
            style={{
              position: "absolute",
              top: "50%",
              left: "-20rem",
              transform: "translate(50%, -50%)",
              width: "23rem",
              userSelect: "none",
              MozUserSelect: "none",
            }}
          />
          <Header />

          <div className="container" style={{ padding: "6rem 2rem 6rem 2rem" }}>

            {this.state.success === true ? (
               <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Col sm={6}>
                  <UncontrolledAlert
                    color="success"
                    className="alert-success alert-dismissible fade show mt-4 px-4 mb-0 text-center"
                    role="alert"
                  >
                    <i className="uil uil-check-circle d-block display-4 mt-2 mb-3 text-success"></i>
                    <h5 className="text-success">Success</h5>
                    <p>Success menghadiri rapat</p>
                  </UncontrolledAlert>
                </Col>
              </div>
            ) : null }

            {this.state.failed === true ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Col sm={12}>
                  <UncontrolledAlert
                    color="danger"
                    className="alert-danger alert-dismissible fade show mt-4 px-4 mb-0 text-center"
                    role="alert"
                  >
                    <i className="uil uil-exclamation-octagon d-block display-4 mt-2 mb-3 text-danger"></i>
                    <h5 className="text-danger">Gagal</h5>
                    <p>Terjadi kesalahan. Harap coba beberapa saat lagi!</p>
                  </UncontrolledAlert>
                </Col>
              </div>
            ) : null}

            {this.state.loading === true ? (
              <Col>
                <Card>
                  <CardBody>  
                    <div className="parent-container">
                      <div className="centered-child">
                        <Row>
                          <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                          />
                        </Row>
                        <h4>Menyimpan data...</h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ) : null}
          </div>
        </div>
        
      </>
    );
  }
}

export default withRouter(Attendance);

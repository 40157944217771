import axios from "axios";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import avatarMale from "../../../../assets/images/avatar_male.png";
import avatarFemale from "../../../../assets/images/avatar_female.png";
import { authContext } from "../../../../services/adalConfig";
import { createChat, sendMessage } from "../../../../services/teamsService";
import imglyRemoveBackground, {Config} from "@imgly/background-removal"
import "./style.scss";
import { Row, Col} from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function RiwayatPendidikan(props) {

  const baseUrl = process.env.REACT_APP_SIMPEG_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_SIMPEG_SERVICE_BASE_APPCODE;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [educationList, setEducationList] = useState([])

  useEffect(async () => {
      const fetchAccessToken = () => {
        try {
          axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
            }
          }).then((response) => {
            const accessToken = response.data.response.access_token
            fetchEducationList(accessToken)
          })
          .catch((error) => {
            console.log(error)
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const fetchEducationList = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}Kepegawaian/RiwayatPendidikan/list/json?search=&limit=-1&offset=0&order=education_background_graduation_year&sort=desc&status=1&uuid=${userData.employee_uuid}`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setEducationList(result)
          if (result.length > 0) {
            setCurrentIndex(1)
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchAccessToken()

  }, []);  

  const increaseIndex = () => {
    setCurrentIndex(currentIndex + 1);
  }
  
  const decreaseIndex = () => {
    setCurrentIndex(currentIndex - 1);
  }

  return (
    <>
      <div
        className="main"
        style={{
          position: "relative",
          minHeight: "100vh",
          overflow: "hidden"
        }}
      >
        <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
          <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
        </div>

        <Profile/>

        <div style={{ margin: "13vh 18vw 13vh 18vw" }}>
          <p style={{ color: "#575757", fontFamily: "IBM Plex Sans", fontSize: "24px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal" }}>Riwayat Pendidikan</p>
          <div style={{borderRadius: "9px", border: "1px solid aqua", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)"}}>
            <div style={{padding: "30px"}}>
              <Row>
                <Col md={12}>
                  {educationList.map((education, index) => 
                  currentIndex == index + 1 && (
                    <Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col md={4}>
                          <label className="title">Jenjang</label>
                        </Col>
                        <Col md={8}>
                          <label className="value">{education.education_background_education_name ?? "-"}</label>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col md={4}>
                          <label className="title">Sekolah/Perguruan Tinggi</label>
                        </Col>
                        <Col md={8}>
                          <label className="value">{education.education_background_school_name ?? "-"}</label>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col md={4}>
                          <label className="title">Jurusan</label>
                        </Col>
                        <Col md={8}>
                          <label className="value">{education.education_background_school_major ?? "-"}</label>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col md={4}>
                          <label className="title">Nomor Ijazah</label>
                        </Col>
                        <Col md={8}>
                          <label className="value">{education.education_background_certificate_number ?? "-"}</label>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col md={4}>
                          <label className="title">Tahun Lulus</label>
                        </Col>
                        <Col md={8}>
                          <label className="value">{[education.education_background_graduation_year ?? "-"]}</label>
                        </Col>
                      </Row>
                    </Row>
                  )
                  )}
                </Col>
              </Row>
            </div>
            <hr/>
            <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "30px", paddingRight: "30px"}}>
                <i className="uil-arrow-left" style={{ fontSize: currentIndex == 1 || currentIndex == 0 ? "0px" : "20px" }} onClick={() => decreaseIndex()}/>
                <p>{currentIndex} of {educationList.length}</p>
                <i className="uil-arrow-right" style={{ fontSize: currentIndex == educationList.length ? "0px" : "20px" }} onClick={() => increaseIndex()}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RiwayatPendidikan;

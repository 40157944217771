import { apiRequest, announcementDetail, announcementUpdate } from "../../../../services/adapters/base";

class UpdateRepository {

  fetchDetail = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      announcementDetail,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      announcementUpdate,
      {
        body: query,
      },
      true
    );
  };
}

export default UpdateRepository;

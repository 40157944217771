import React from "react";
import { Waypoint } from "react-waypoint";
import { GlobalContext } from "../../../../../contexts/GlobalContext";

class HeroSection extends React.Component {
  static contextType = GlobalContext;
  userdata = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {
      hero: {
        title: this.createGreeting(),
        name: this.userdata.employee_name,
        nip: this.userdata.employee_nip
      },
    };
  }

  createGreeting() {
    var today = new Date()
    var hour = today.getHours()
    var greeting = ""

    if (hour >= 4 && hour <= 10) {
      greeting = "Selamat Pagi!"
    } else if(hour > 10 && hour <= 14) {
      greeting = "Selamat Siang!"
    } else if(hour > 14 && hour <= 18 ) {
      greeting = "Selamat Sore!"
    } else {
      greeting = "Selamat Malam!"
    }

    return greeting
  }

  hanldeWaypointEnter = () => {
    const { setActiveNav } = this.context;

    setActiveNav("Home");
  };

  render() {
    return (
      <div
        className="hero-section"
        style={{ background: "#F9FAFF", paddingTop: 60, paddingBottom: 40}}
        id="intro"
      >
        <div className="container">
        <div style={{ position: "absolute", right: 0}}>
              <img
                  src="/bg_ornament.png"
                  alt="wave shape"
                  className="img-fluid"
                />
            </div>
          <div className="row align-items-center justify-content-between py-5">
            <div className="col-md-5 col-lg-5">
              <div className="hero-content-left" style={{ width: "90%" }}>
                <p style={{ fontWeight: "400", color: "#0F0465", fontSize: "24px", opacity: "43%" }} data-aos="fade-up">
                  {this.state.hero.title}
                </p>

                <Waypoint scrollableAncestor={window} onEnter={this.hanldeWaypointEnter} />
                  <p
                    className="lead"
                    style={{ color: "#4A494F", fontWeight: "400", fontSize: "32px" }}
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    {this.state.hero.name}
                  </p>
                  <div style={{ display: "flex", padding: "10px", gap: "10px", position: "absolute", height: "41px", background: "#ECEAFD", borderRadius: "16px" } } data-aos="fade-up"
                      data-aos-delay="300" >
                    <p
                      style={{ color: "#4A494F", fontWeight: "400", fontSize: "16px" }}
                    >
                      {this.state.hero.nip}
                    </p>
                  </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-7">
              <div
                className="hero-animation-img"
                data-aos="fade-up"
                data-aos-delay="250"
                style={{ display: "flex", justifyContent: "flex-end"}}
              >
                <img
                  className="img-fluid d-block animation-one"
                  src="/logo_eoffice.png"
                  style={{ height: 250, margin: 0 }}
                  alt="animation"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bottom-img-absolute">
          <img
            src="img/hero-bg-shape-1.svg"
            alt="wave shape"
            className="img-fluid"
          />
        </div> */}
      </div>
    );
  }
}

export default HeroSection;

module.exports = {
  application: [
    {
      id: "1",
      title: "Kepegawaian",
      icon: "Kepegawaian",
      color: "#EFFEFC",
      active: true,
      featuresColor: "#3BC2CB",
      features: [
        {
          title: "Data Pegawai",
          icon: "ep:avatar",
          alert: false,
          link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/kepegawaian/home.html`,
        },
        {
          title: "Izin Ketidakhadiran",
          icon: "ri:file-paper-2-fill",
          alert: false,
          link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/izinpegawai/koreksikehadiran.html`,
        },
        {
          title: "Perjalanan Dinas",
          icon: "bx:bxs-car",
          alert: false,
          link: `https://persuratan${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/outbox/daftardraft.html?create`,
        },
        {
          title: "Pengajuan Dokumen",
          icon: "ic:round-drive-folder-upload",
          alert: true,
          link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/kepegawaian/pengajuan_dokumen.html`,
        },
        {
          title: "Pengajuan Cuti",
          icon: "ri:user-received-2-fill",
          alert: false,
          link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/izinpegawai/cuti.html`,
        },
        {
          title: "Presensi",
          icon: "ph:fingerprint-simple-bold",
          alert: false,
          link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/kepegawaian/presensi.html`,
        },
        // {
        //   title: "Hukuman Disiplin",
        //   icon: "ri:hammer-fill",
        //   alert: false,
        //   link: "https://simpeg.bkpm.go.id/kepegawaian/hukuman_disiplin.html",
        // },
        {
          title: "Rekap Absensi",
          icon: "ri:file-4-fill",
          alert: false,
          link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/kepegawaian/rekap_absensi.html`,
        },
        {
          title: "Pengajuan WFH",
          icon: "ri:user-location-fill",
          alert: false,
          link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/kepegawaian/pengajuan_wfh.html`,
        },
      ],
    },
    {
      id: "2",
      title: "Persuratan",
      icon: "Persuratan",
      color: "#FFF5F3",
      active: true,
      featuresColor: "#FF7A78",
      features: [
        {
          title: "Buat Surat",
          icon: "ri:mail-add-fill",
          alert: false,
          link: `https://persuratan${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/outbox/daftardraft.html?create`,
        },
        {
          title: "Disposisi",
          icon: "ri:mail-send-fill",
          alert: false,
          link: `https://persuratan${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/disposition/daftardisposisi.html`,
        },
        {
          title: "Surat Masuk",
          icon: "ri:mail-open-fill",
          alert: false,
          link: `https://persuratan${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/inbox/daftarsurat.html`,
        },
        {
          title: "Surat Keluar",
          icon: "ri:mail-send-line",
          alert: true,
          link: `https://persuratan${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/outbox/daftarsurat.html`,
        },
      ],
    },
    {
      id: "3",
      title: "Inventory",
      icon: "Inventory",
      color: "#F4FBFF",
      active: true,
      featuresColor: "#98CEFA",
      features: [
        {
          title: "Kendaraan Dinas",
          icon: "ri:car-washing-fill",
          alert: false,
          link: `https://inventory${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/peminjaman/kendaraandinas.html`,
        },
        {
          title: "Peralatan",
          icon: "ri:tools-fill",
          alert: false,
          link: `https://inventory${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/peminjaman/peralatan.html`,
        },
        {
          title: "Wisma",
          icon: "ri:home-smile-2-fill",
          alert: false,
          link: `https://inventory${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/peminjaman/kampusinvestasi.html`,
        },
        {
          title: "Ruang Rapat",
          icon: "ri:building-fill",
          alert: false,
          link: `https://inventory${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/peminjaman/ruangrapat.html`,
        },
        {
          title: "Perbaikan Peralatan",
          icon: "ri:home-gear-fill",
          alert: false,
          link: `https://inventory${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/perbaikan/peralatan.html`,
        },
        {
          title: "Dashboard Ruang Rapat",
          icon: "ri:home-smile-2-fill",
          alert: false,
          link: `https://inventory${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/meetingroom/meetingroom.html`,
        },
      ],
    },
    {
      id: "4",
      title: "Keuangan",
      icon: "Keuangan",
      color: "#FDD39D",
      active: true,
      featuresColor: "#FCB860",
      features: [
        {
          title: "Slip Gaji",
          icon: "ri:user-2-fill",
          alert: false,
          link: `https://simkeu${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/payroll/skp.html`,
        },
        {
          title: "SPT",
          icon: "ri:file-3-fill",
          alert: true,
          link: `https://simkeu${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/payroll/spt.html`,
        },
        {
          title: "Detail Penghasilan",
          icon: "ri:file-chart-fill",
          alert: false,
          link: `https://simkeu${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/payroll/tunkin-reguler.html`,
        },
      ],
    },
    {
      id: "5",
      title: "DMS",
      icon: "DMS",
      active: true,
      color: "#FDEDF9",
      link: `https://dms${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id`,
    },
    {
      id: "6",
      title: "Chat & Meet",
      icon: "Chat",
      color: "#E0D3E6",
      active: true,
      featuresColor: "#CE97EE",
      features: [
        {
          title: "Microsoft Teams",
          icon: "logos:microsoft-teams",
          alert: true,
          link: "msteams://",
        },
        {
          title: "Zoom Meetings",
          icon: "grommet-icons:zoom",
          alert: false,
          link: "zoomus://",
        },
        {
          title: "Google Meets",
          icon: "logos:google-meet",
          alert: false,
          link: "https://meet.google.com/",
        },
      ],
    },
    {
      id: "7",
      title: "Dashboard",
      icon: "SKP",
      color: "#BECAE2",
      active: true,
      link: `https://simpeg${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/dashboard.html`,
    },
    // {
    //   id: "8",
    //   title: "SKP",
    //   icon: "Notulen",
    //   color: "#CBD7B5",
    //   active: true,
    //   link: "https://simpeg.bkpm.go.id/kepegawaian/skp.html",
    // },
    {
      id: "9",
      title: "SIMANTAB",
      icon: "Simantab",
      color: "#E4EBEF",
      active: false,
      link: `https://simantab${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/`,
    },
    {
      id: "10",
      title: "Email",
      icon: "Email",
      color: "#F5E1D6",
      active: true,
      link: "https://outlook.office.com/",
    },
  ],
};

import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import GuideRepository from "../Repositories/GuideRepository";

class GuideBloc {
  listChannel = new Subject();
  guideCategoryChannel = new Subject();
  repository = new GuideRepository();

  constructor() {
    this.listChannel.next({ status: apiResponse.INITIAL });
    this.guideCategoryChannel.next({ status: apiResponse.INITIAL });
  }

  fetchList = async (query, cancelToken) => {
    this.listChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchList(query, cancelToken)
        .then((result) => {
          this.listChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.listChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchCategories = async (query, cancelToken) => {
    this.guideCategoryChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchCategories(query, cancelToken)
        .then((result) => {
          this.guideCategoryChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.guideCategoryChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };
}

export default GuideBloc;

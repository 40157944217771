import PropTypes from 'prop-types'
import React from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes, adminRoutes } from "./routes/WebRoutes"

// Import all middleware
import Basemiddleware from "./routes/middleware/BaseMiddleware"

// layouts Format
import DefaultLayout from "./components/DefaultLayout/Index"
import NonAuthLayout from "./components/NonAuthLayout"
import AdminLayout from "./components/DefaultLayout/AdminLayout"

import ErrorEmpty from './components/Error/Empty'

// Conflicting with template style
// Import scss
import "./assets/scss/theme.scss"
import "./assets/css/custom.css"
import "./assets/css/line.css"
import 'moment/locale/id'

// import { createStore } from "redux";
// import { Provider } from "react-redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import AgencyCoApp from "./reducers";

// create store
// const store = createStore(
//   AgencyCoApp,
//   composeWithDevTools()
//   // applyMiddleware(...middleware),
//   // other store enhancers if any
// );

const App = props => {

  return (
    <React.Fragment>
      {/* <Provider store={store}> */}
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Basemiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={route.authRequired}
            />
          ))}
          {userRoutes.map((route, idx) => (
            <Basemiddleware
              path={route.path}
              layout={DefaultLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          {adminRoutes.map((route, idx) => (
            <Basemiddleware
              path={route.path}
              layout={AdminLayout}
              component={route.component}
              key={idx}
              isAuthProtected={route.authRequired}
            />
          ))}
          <Basemiddleware
            layout={NonAuthLayout}
            component={ErrorEmpty}
          />
        </Switch>
      </Router>
      {/* </Provider> */}
    </React.Fragment >
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout
  }
}

export default connect(mapStateToProps, null)(App)
import { Component } from "react";
import Header from "../Attendance/components/Header/header";
import { ConfirmAlert } from "../../helpers/ui/alert";
import { withRouter } from "react-router-dom";
import './attendance.scss';
import { AvForm, AvField } from "availity-reactstrap-validation"
import apiResponse from "../../services/apiResponse";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Form,
  Container,
  Label,
  Input,
  FormGroup,
  Button,
  UncontrolledAlert
} from "reactstrap"
import AttendanceBloc from "./Blocs/AttendanceBloc";

class Attendance extends Component {
  attendanceBloc = new AttendanceBloc();

  constructor(props) {
    super(props);
    this.state = {
      finishSubmit: false
    };
  }

  submitData = async () => {
    await this.attendanceBloc.fetchCreate(this.state.dataForm);
  };

  handlerOnSubmit = (e, values) => {
    
    const queryParams = new URLSearchParams(this.props.location.search);

    e.preventDefault();
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        meeting_registration_attendance_registration_uuid: queryParams.get("uuid"),
      },
    });

    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ...values,
      }
    });
    
    this.setState({
      confirm: true,
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.attendanceBloc.attendanceChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
            finishSubmit: true
          });
          // this.form && this.form.reset();
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.attendanceBloc.attendanceChannel.unsubscribe();
  }

  render() {
    return (
      <>
        <div
          className="main"
          style={{
            background: "#F8F9F9",
            position: "relative",
            minHeight: "100vh",
          }}
        >
          <img
            src="img/ornaments/Pengumuman_bg.svg"
            alt="ornaments"
            style={{
              position: "absolute",
              top: "50%",
              left: "-20rem",
              transform: "translate(50%, -50%)",
              width: "23rem",
              userSelect: "none",
              MozUserSelect: "none",
            }}
          />
          <Header />

          {this.state.finishSubmit === true ? (
              <div className="container" style={{ padding: "6rem 2rem 6rem 2rem" }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Col sm={6}>
                    <UncontrolledAlert
                      color="success"
                      className="alert-success alert-dismissible fade show mt-4 px-4 mb-0 text-center"
                      role="alert"
                    >
                      <i className="uil uil-check-circle d-block display-4 mt-2 mb-3 text-success"></i>
                      <h5 className="text-success">Success</h5>
                      <p>Success menghadiri rapat</p>
                    </UncontrolledAlert>
                  </Col>
                </div>
              </div>
            ) :  <div className="container" style={{ padding: "6rem 2rem 6rem 2rem" }}>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Form Kehadiran</CardTitle>
                    <p className="card-title-desc">
                      Harap isi form dibawah ini
                    </p>
  
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                      onKeyPress={this.preventEnter}
                      ref={c => (this.form = c)}>
                      <Row>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Nama *
                        </label>
                        <div className="col-md-10">
                          <AvField
                            name="meeting_registration_attendance_name"
                            type="text"
                            errorMessage="Masukan Nama"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Email *
                        </label>
                        <div className="col-md-10">
                          <AvField
                           
                            name="meeting_registration_attendance_email"
                            type="text"
                            errorMessage="Masukan Email"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          No Telp *
                        </label>
                        <div className="col-md-10">
                          <AvField
                           
                            name="meeting_registration_attendance_no_telp"
                            type="text"
                            errorMessage="Masukan No Telp"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Instansi *
                        </label>
                        <div className="col-md-10">
                          <AvField
                           
                            name="meeting_registration_attendance_company"
                            type="text"
                            errorMessage="Masukan Instansi"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Row>
                      <Row>
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Jabatan *
                        </label>
                        <div className="col-md-10">
                          <AvField
                           
                            name="meeting_registration_attendance_position"
                            type="text"
                            errorMessage="Masukan Jabatan"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Row>
                      <Button color="primary" type="submit">
                        Kirim
                      </Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>}
        </div>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
      </>
    );
  }
}

export default withRouter(Attendance);

import React from "react";
import FollowLinks from "../FollowLinks";
import { Row, Col } from "reactstrap";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer
          // className={`footer-section ${!this.props.noMargin ? "mt-5" : ""}`}
          style={{ borderTop: "2px solid #4BAC61" }}
        >
          <div
            className="footer-bottom pt-4 pb-4"
            style={{
              background: "#243C92",
              border: "2x",
            }}
          >
            <div className="container">
              <div className="row text-center justify-content-center">
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <p
                      className="copyright-text pb-0 mb-0"
                      style={{ color: "#AAA" }}
                    >
                      Copyrights © 2024.
                      <a
                        href="/#"
                        style={{ color: "white", fontWeight: 600 }}
                      >
                        {" "}
                        KEMENTERIAN INVESTASI DAN HILIRISASI/BKPM
                      </a>
                    </p>
                  </Col>
                  {/* <Col xs={12} sm={12} md={6}>
                    <FollowLinks />
                  </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </footer>
        {/* <footer>
          <div
            className="footer-bottom pt-4 pb-4"
            style={{
              background: "white",
              border: "2x",
            }}
          >
            <div className="container">
              <div className="row text-center">
                <div
                  style={{ alignItems: "center", height: "100%" }}
                >
                  <p
                    className="copyright-text pb-0 mb-0"
                    style={{ color: "#AAA" }}
                  >
                    Copyrights © 2022. All rights reserved by
                    <a
                      href="/#"
                      style={{ color: "#243C92", fontWeight: 600 }}
                    >
                      {" "}
                      MINISTRY OF INVESMENT/BKPM
                    </a>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </footer> */}
      </React.Fragment>
    );
  }
}

export default Footer;

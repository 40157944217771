import { apiRequest, searchEmployee } from "../../../../services/adapters/base";

class EmployeeRepository {
	fetchSearch = async (query, cancelToken) => {
		return await apiRequest(
			"get",
			searchEmployee,
			{
				cancelToken: cancelToken,
				params: query,
			},
			true
		);
	};
}

export default EmployeeRepository;

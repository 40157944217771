import {
    apiRequest,
    portalServiceList,
  } from "../../../../services/adapters/base";
  
  class PortalServiceRepository {
    fetchList = async (query, cancelToken) => {
      return await apiRequest(
        "get",
        portalServiceList,
        {
          cancelToken: cancelToken,
          params: query,
        },
        true
      );
    };
  }
  
  export default PortalServiceRepository;
  
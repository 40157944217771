import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import AnnouncementRepository from "../Repositories/AnnouncementRepositories";

class AnnouncementBloc {
  listChannel = new Subject();
  popupChannel = new Subject();
  repository = new AnnouncementRepository();

  constructor() {
    this.listChannel.next({ status: apiResponse.INITIAL });
    this.popupChannel.next({ status: apiResponse.INITIAL });
  }

  fetchList = async (query, cancelToken) => {
    this.listChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchList(query, cancelToken)
        .then((result) => {
          this.listChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.listChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchPopupAnnouncement = async (query, cancelToken) => {
    this.popupChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchPopupAnnouncement(query, cancelToken)
        .then((result) => {
          this.popupChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.popupChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };
}

export default AnnouncementBloc;

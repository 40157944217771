import { Subject } from 'rxjs'
import apiResponse from '../../../../services/apiResponse'
import UpdateRepository from '../Repositories/UpdateRepository'


class UpdateBloc {

    detailChannel = new Subject();
    updateChannel = new Subject();
    repository = new UpdateRepository()

    constructor() {
        this.detailChannel.next({status: apiResponse.INITIAL})
        this.updateChannel.next({status: apiResponse.INITIAL})
    }

    fetchDetail = async (query, cancelToken) => {
        this.detailChannel.next({ status: apiResponse.LOADING });
        try {
          await this.repository
            .fetchDetail(query, cancelToken)
            .then((result) => {
              this.detailChannel.next({
                status: apiResponse.COMPLETED,
                data: result,
              });
            })
            .catch((error) => {
              this.detailChannel.next({ status: apiResponse.ERROR, data: error });
            });
        } catch (error) {}
      };

    fetchUpdate = async (query) => {
        this.updateChannel.next({status: apiResponse.LOADING})
        await this.repository.fetchUpdate(query).then((result) => {
            this.updateChannel.next({status: apiResponse.COMPLETED, data: result})
        }).catch((error) => {
            this.updateChannel.next({status: apiResponse.ERROR, data: error})
        })
    }
}

export default UpdateBloc
import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import PriorityRepository from "../Repositories/PriorityRepository";

class PriorityBloc {
  priorityChannel = new Subject();
  repository = new PriorityRepository();

  constructor() {
    this.priorityChannel.next({ status: apiResponse.INITIAL });
  }

  fetchPriority = async (query) => {
    this.priorityChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchPriority(query)
      .then((result) => {
        this.priorityChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.priorityChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default PriorityBloc;

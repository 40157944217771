import {
  apiRequest,
  announcementList,
  announcementPopup
} from "../../../../services/adapters/base";

class AnnouncementRepository {
  fetchList = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      announcementList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchPopupAnnouncement = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      announcementPopup,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };
}

export default AnnouncementRepository;

import { apiRequest, ticketList, ticketClose, ticketEscalation, ticketBackToPic, ticketExport } from "../../../../services/adapters/base";

class DataTableRepository {
  fetchRowTable = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      ticketList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchCloseTable = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      ticketList, 
      { 
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchCloseTicket = async (query) => {
    return await apiRequest(
      "post",
      ticketClose,
      {
        body: query,
      },
      true
    );
  };

  fetchEscalationTicket = async (query) => {
    return await apiRequest(
      "post",
      ticketEscalation,
      {
        body: query,
      },
      true
    );
  };

  fetchBackToPicTicket = async (query) => {
    return await apiRequest(
      "post",
      ticketBackToPic,
      {
        body: query,
      },
      true
    );
  };

  fetchHelpdeskExport = async (query) => {
    return await apiRequest("get", ticketExport, {
        params: query
    }, true)
  }
}

export default DataTableRepository;

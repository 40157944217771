import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Button, Row, Col, Label, Input, Table } from "reactstrap"
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  FileInput,
  generateFormData
} from "../../../../helpers/ui/FileInput";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import listenGif from "../../../../assets/videos/listening.gif"
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { Link, Redirect } from "react-router-dom";
import CustomSelect from "../../../../helpers/ui/CustomSelect"
import reviewIcon from '../../../../assets/icons/icon_review_summary.png';
import DatePicker from '../../../../helpers/ui/DatePicker'
import Lottie from "react-lottie";
import animationData from "../../../../assets/lotties/nlp.json"
import { addDays } from 'date-fns';
import Shimmer from "react-shimmer-effect";
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function PengajuanCutiView(props) {

  const baseUrl = process.env.REACT_APP_SIMPEG_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_SIMPEG_SERVICE_BASE_APPCODE;
  const title = "Pengajuan Cuti";
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentAccessToken, setCurrentAccessToken] = useState()
  const questions = ["Silakan pilih jenis cuti", "Silakan pilih detail jenis cuti", "Silakan pilih tanggal cuti", "Silakan masukan alasan cuti", "Silakan masukan alamat selama menjalankan cuti", "Silakan masukan no telepon selama cuti", "Silakan upload file pendukung"];
  const questionTotal = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [leaveTypes, setLeaveTypes] = useState([])
  const [detailLeaveTypes, setDetailLeaveTypes] = useState([])
  const [listCuti, setListCuti] = useState([]);
  const [sisaSaldo, setSisaSaldo] = useState(0);
  const [historyCuti, setHistoryCuti] = useState([]);
  const [holidays, setHolidays] = useState([])
  const [holidaysFormatDate, setHolidayFormatDate] = useState()
  const [minDateEnd, setMinDateEnd] = useState();
  const [maxDateEnd, setMaxDateEnd] = useState()
  const [minDateStart, setMinDateStart] = useState()
  const [isUploadRequired, setIsUploadRequired] = useState(false);
  const [minDateLoading, setMinDateLoading] = useState(false)
  const CUTI_SAKIT = 'e1ec1835-6a27-4831-91f9-5b68a7b11de2'
  const CUTI_TAHUNAN = 'b490a04a-0362-489b-832a-29e4c3b961bc'
  const CUTI_BERSALIN = '5a0b8444-74a6-4228-9c4c-1431a61eedbc'
  const CUTI_ALASAN_PENTING = '47b76ad9-0ccd-493c-8fb0-57d7aee48a80'

  const [selectedLeaveType, setSelectedLeaveType] = useState({})
  const [selectedDetailLeaveType, setSelectedDetailLeaveType] = useState({})
  const [selectedStartDate, setSelectedStartDate] = useState()
  const [selectedEndDate, setSelectedEndDate] = useState()
  const [selectedLeaveReason, setSelectedLeaveReason] = useState()
  const [selectedAddress, setSelectedAddress] = useState()
  const [selectedPhone, setSelectedPhone] = useState()
  const [selectedFile, setSelectedFile] = useState()

  const [confirm, setConfirm] = useState()
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [failed, setFaield] = useState()
  const [redirect, setRedirect] = useState()
  const [failedInfo, setFailedInfo] = useState()

  const {
    transcript,
    listening,
  } = useSpeechRecognition();

  const startListen = () => {
    SpeechRecognition.startListening({ language: 'id'})
  }

  useEffect(() => {
    const fetchAccessToken = () => {
      try {
        axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCode,
            email: userData.employee_email
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentAccessToken(accessToken)
          fetchLeaveTypes(accessToken)
          fetchSisaSaldo(accessToken)
          fetchHolidays(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchLeaveTypes = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}Kepegawaian/cuti/typeList/json`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });
        setLeaveTypes(response.data.response.result.map((tool) => {
          return {
            label: tool.paid_leave_type_name,
            value: tool.paid_leave_type_uuid,
          };
        }),)  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchSisaSaldo = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}Kepegawaian/cuti/getYearQuota/json?uuid=${userData.employee_uuid}`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });
        setHistoryCuti(response.data.response.list)
        setSisaSaldo(response.data.response.sisa)  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchHolidays = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}auth/getHoliday/json`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });
        setHolidays(response.data.response)  
        const formattedDates = response.data.response.map(function (item, i) {
          var libur = addDays(new Date(item), 0);
          return libur
        })

        setHolidayFormatDate([...formattedDates])
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAccessToken()
  }, []); 

  const loadMinDate = async (query_params) => {

    setMinDateLoading(true);

    const params = {
      nip: userData.employee_nip,
    }

    try {
      const response = await axios.get(`${baseUrl}Kepegawaian/cuti/getMinDate/json`, {
        params: {...params, ...query_params},
        headers: {
          appCode: appCode,
          email: userData.employee_email,
          accessToken: currentAccessToken
        },
      });

      setMinDateLoading(false)
      setMinDateEnd(response.data.response.new_result.end_date_min)
      setMaxDateEnd(response.data.response.new_result.end_date_max)
      setMinDateStart(response.data.response.new_result.start_date_min)
    } catch (error) {
      console.error('Error fetching:', error);
    }
  }

  const handleTanggalCuti = (v = null, type = selectedLeaveType.value) => {
    if (type == CUTI_TAHUNAN) { 
      loadMinDate({amount: sisaSaldo, date: v, jenis: type})
    } else if(type == CUTI_BERSALIN) {
      loadMinDate({amount: 90, date: v, jenis: type})
    } else {
      loadMinDate({amount: 0, date: v, jenis: type})
    }
  }

  const onLeaveTypeSelected = async (leaveTypeUuid) => {
    try {
      const response = await axios.get(`${baseUrl}Kepegawaian/cuti/childTypeList/json?parent=${leaveTypeUuid}`, {
        headers: {
          appCode: appCode,
          email: userData.employee_email,
          accessToken: currentAccessToken
        },
      });

      setDetailLeaveTypes(response.data.response.result.map((tool) => {
        return {
          label: tool.paid_leave_type_detail_name,
          value: tool.paid_leave_type_detail_uuid,
        };
      }),)  
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setSelectedStartDate(null)
    setSelectedEndDate(null)

    if (leaveTypeUuid == CUTI_SAKIT || leaveTypeUuid == CUTI_BERSALIN || leaveTypeUuid == CUTI_ALASAN_PENTING) {
      setIsUploadRequired(true)
    } else {
      setIsUploadRequired(false)
    }

    handleTanggalCuti(null, leaveTypeUuid)

  }

  const checkDate = async () => {
    const response = await axios.get(`${baseUrl}Kepegawaian/cuti/checkDate/json`, {
      params: {
        "employee_uuid": userData.employee_uuid,
        "start_date": selectedStartDate,
        "end_date": selectedEndDate
      },
      headers: {
        appCode: appCode,
        email: userData.employee_email,
        accessToken: currentAccessToken
      },
    });
    let result = response.data.response;
    return result;
  }

  const submitData = async () => {
    const resultCheckDate = await checkDate()
    if (resultCheckDate.count_used_date == 0) {
      const dataForm = {
        paid_leave_employee_uuid: userData.employee_uuid,
        access_id: 2,
        period_revision_uuid: null,
        paid_leave_employee_name: userData.employee_name,
        paid_leave_type_uuid: selectedLeaveType.value,
        paid_leave_type_detail_uuid: selectedDetailLeaveType.value,
        paid_leave_reason: selectedLeaveReason,
        paid_leave_address: selectedAddress,
        paid_leave_phone: selectedPhone,
        paid_leave_start_date: selectedStartDate,
        paid_leave_end_date: selectedEndDate,
        "document[]": selectedFile != null ? selectedFile[0] : null
      }
  
      const formData = generateFormData(dataForm, {
        field: "documents",
        name: "simpeg/cuti/attachment/",
      });
  
      const response = await axios.post(`${baseUrl}Kepegawaian/cuti/create/json`, formData, {
        headers: {
          appCode: appCode,
          email: userData.employee_email,
          accessToken: currentAccessToken
        },
      });
  
      if (response.data.status == true) {
        setSuccess(true)
      } else {
        setFaield(true)
        setFailedInfo("Data gagal disimpan")
      }
      setLoading(false) 
    } else {
      setFaield(true)
      console.log(resultCheckDate)
      setFailedInfo(resultCheckDate.message)
      setLoading(false)
    }
  }

  const confirmResponse = (response) => {
    switch (response) {
      case "cancel":
        setConfirm(false)
        break;
      case "confirm":
        if (listCuti?.length > sisaSaldo) {
          alert('Total pengajuan cuti melebihi kuota cuti anda, mohon disesuaikan!')
        } else {
          setLoading(true)
          setConfirm(false)        
          submitData();
        }
        break;
      case "success":
        setSuccess(false)
        setRedirect(true)
        break;
      case "failed":
        setFaield(false)
        break;
      default:
    }
  };

  const showButton = (showNextButton) => {
    return (
      <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", bottom: 0, right: 0, zIndex: 99 }}>
          {currentQuestion > 1 && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              Kembali
            </Button> 
          )}

          {showNextButton == true && currentQuestion != questionTotal && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Selanjutnya
            </Button> 
          )}

          {showNextButton == true && currentQuestion == questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Review
          </Button> 
          )}

          {currentQuestion > questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setConfirm(true)}
          >
            Submit
          </Button> 
          )}
        </div>
    )
  }

  const setTotalLeaveDay = (startDate, endDate) => {
    setListCuti([])
    if (selectedLeaveType.value === CUTI_TAHUNAN) {
      if (startDate != null && endDate != null) {
          let start = new Date(startDate)
          let end = new Date(endDate)
          let list = new Array()

          for (let m = start.getMonth(); m <= end.getMonth(); m++) {
              for (let d = 1; d <= 31; d++) {
                  let time = `${start.getFullYear()}-${m + 1}-${d}`
                  time = new Date(time)

                  if (d < start.getDate() && m == start.getMonth()) {
                      continue
                  }
                  if (m == end.getMonth() && d > end.getDate()) {
                      continue
                  }
                  if (time.getMonth() == m) {
                      if (time.getDay() != 6 && time.getDay() != 0) {
                          let theDate = moment(time).format('YYYY-MM-DD');
                          if (!holidays.includes(theDate)) {
                              list.push(theDate);
                          }
                      }
                  }
              }
          }
          setListCuti(list)
      } 
    } else{
        if (startDate != null && endDate != null) {
            var startDateX = new Date(startDate);
            var endDateX = new Date(endDate);

            var getDateArray = function (start, end) {
                var arr = new Array();
                var dt = new Date(start);

                while (dt <= end) {
                    arr.push(new Date(dt));
                    dt.setDate(dt.getDate() + 1);

                }
                return arr;
            }
            var dateArr = getDateArray(startDateX, endDateX);
            setListCuti(dateArr)
        }
    }
  }

  useEffect(() => {
    if (currentQuestion == 4) {
      setSelectedLeaveReason(transcript)
    } else if(currentQuestion == 5) {
      setSelectedAddress(transcript)
    } else if(currentQuestion == 6) {
      setSelectedPhone(transcript)
    }
  }, [transcript]);

  const showLottie = () => {
    return <Lottie options={{ 
      loop: true,
      autoplay: true, 
      animationData: animationData
     }}
      isClickToPauseDisabled={true}
      height="100%"
      width="100%"
      isStopped={false}
      isPaused={false}/>
  }
  
  return (
    <>
      {currentQuestion <= questionTotal && (
        <div className="mb-4" style={{ position: "fixed", top: 0, width: "100%" }}>
          <Progress color="info" value={currentQuestion / questionTotal * 100} />
        </div>
      )}
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {currentQuestion <= questionTotal ? (
          <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99 }}>
            Pertanyaan {currentQuestion} dari {questionTotal}
          </div>
        ) : <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
        <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
      </div>}

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px" }} >
        
        { currentQuestion == 1 && (
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[0]}</p>
              <div className="pt-2">
                <CustomSelect
                  name="paid_leave_uuid"
                  id="paid_leave_uuid"
                  options={leaveTypes}
                  validate={{ required: { value: true } }}
                  defaultValue={selectedLeaveType.value}
                  onChange={(data) => {
                    setCurrentQuestion(currentQuestion + 1)
                    setSelectedLeaveType(data)
                    onLeaveTypeSelected(data.value)
                    setListCuti([])
                  }} 
                />
              </div>
              {showButton(Object.keys(selectedLeaveType).length > 0)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 2 && (
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[1]}</p>
              <div className="pt-2">
                <CustomSelect
                  name="paid_leave_detail_uuid"
                  id="paid_leave_detail_uuid"
                  options={detailLeaveTypes}
                  validate={{ required: { value: true } }}
                  defaultValue={selectedDetailLeaveType.value}
                  onChange={(data) => {
                    setCurrentQuestion(currentQuestion + 1)
                    setSelectedDetailLeaveType(data)
                    loadMinDate({amount: sisaSaldo})
                  }} 
                />
              </div>
              {showButton(Object.keys(selectedDetailLeaveType).length > 0)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 3 && (
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[2]}</p>
              <div>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="start-date-input">Tanggal Mulai</Label>
                    <br/>
                    <DatePicker
                      className="form-control"
                      type="date"
                      isWeekday={true}
                      defaultValue={selectedStartDate}
                      dateFormat="dd/MM/yyyy"
                      excludeDates={holidaysFormatDate}
                      onChange={(e) => {
                        const date = moment(e).format('YYYY-MM-DD')
                        setSelectedStartDate(date);
                        setSelectedEndDate(null)
                        handleTanggalCuti(moment(e).format('YYYY-MM-DD'))
                        // if (selectedEndDate != null) {
                        //   setCurrentQuestion(currentQuestion + 1)
                        //   setTotalLeaveDay(date, selectedEndDate)
                        // }
                      }}
                      id="start-date-input"
                      name="start-date-input"
                      minDate={minDateStart}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Label className="form-label nlp-sub-content" htmlFor="end-date-input">Tanggal Selesai</Label>
                    <br/>
                    {minDateLoading ? 
                      <Shimmer>
                          <div style={{ width: "100%", height: 40 }}></div>
                      </Shimmer>
                    : <DatePicker
                        className="form-control"
                        type="date"
                        isWeekday={true}
                        dateFormat="dd/MM/yyyy"
                        defaultValue={selectedEndDate}
                        excludeDates={holidaysFormatDate}
                        onChange={(e) => {
                          const date = moment(e).format('YYYY-MM-DD')
                          setSelectedEndDate(date);
                          handleTanggalCuti(moment(e).format('YYYY-MM-DD'))
                          if (selectedStartDate != null) {
                            // setCurrentQuestion(currentQuestion + 1)
                            setTotalLeaveDay(selectedStartDate, date)
                          }
                        }}
                        id="end-date-input"
                        name="end-date-input"
                        minDate={minDateEnd}
                        maxDate={maxDateEnd}
                        selectsRange
                        selectsDisabledDaysInRange
                        inline
                        showDisabledMonthNavigation
                      />}
                  </div>
                </Col>
                <Col md={12} className="mb-3">
                    <span className="text-muted">Jumlah cuti diajukan : <strong>{listCuti.length}</strong> Hari</span>
                </Col>
              </Row>
              {selectedLeaveType.value == CUTI_TAHUNAN && (
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label className="form-label nlp-sub-content" htmlFor="start-date-input">Informasi Sisa Cuti Tahunan</Label>
                      <br/>
                      <Table className="table table-striped table-bordered table-hover">
                        <tr>
                          <th>Tahun</th>
                          <th>Digunakan</th>
                          <th>Sisa</th>
                        </tr>
                        {historyCuti.map(element => 
                            <tr>
                              <td>{element["tahun"]}</td>
                              <td>{element["digunakan"]}</td>
                              <td>{element["total_saldo_cuti"] - element["digunakan"]}</td>
                            </tr>
                        )}
                        <tr>
                          <td colspan="2"><b>Saldo</b></td>
                          <td><b>{sisaSaldo}</b> dari ({historyCuti[historyCuti.length - 1]["total_saldo_cuti"]})</td>
                        </tr>
                      </Table>
                    </div>
                  </Col>
                </Row>
              )}
              </div>
              {showButton(selectedStartDate != null && selectedEndDate != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 4 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[3]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  type="text"
                  value={selectedLeaveReason}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setSelectedLeaveReason(event.target.value)
                  }}
                />
                <i className="uil-microphone" onClick={startListen}  style={{ 
                    fontSize: '24px',  
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    cursor: 'pointer',}}/>
              </div>
            </div>
            <div className="col-md-6">
              {listening ? (
                <div className="centered-horizontal">
                  <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
                </div>
              ) : showLottie()}
              {showButton(selectedLeaveReason != null && selectedLeaveReason != "")}
            </div>
            
          </Row>
        )}

        { currentQuestion == 5 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[4]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  type="text"
                  value={selectedAddress}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setSelectedAddress(event.target.value)
                  }}
                />
                <i className="uil-microphone" onClick={startListen}  style={{ 
                    fontSize: '24px',  
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    cursor: 'pointer',}}/>
              </div>
            </div>
            <div className="col-md-6">
              {listening ? (
                <div className="centered-horizontal">
                  <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
                </div>
              ) : showLottie()}
              {showButton(selectedAddress != null && selectedAddress != "")}
            </div>
            
          </Row>
        )}

        { currentQuestion == 6 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[5]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  type="text"
                  value={selectedPhone}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setSelectedPhone(event.target.value)
                  }}
                />
                <i className="uil-microphone" onClick={startListen}  style={{ 
                    fontSize: '24px',  
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    cursor: 'pointer',}}/>
              </div>
            </div>
            <div className="col-md-6">
              {listening ? (
                <div className="centered-horizontal">
                  <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
                </div>
              ) : showLottie()}
              {showButton(selectedPhone != null && selectedPhone != "")}
            </div>
            
          </Row>
        )}

        { currentQuestion == 7 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[6]}</p>
                <FileInput
                  name="documents"
                  accept=".pdf"
                  isMultiple={false}
                  selectedFiles={selectedFile}
                  onChange={(event) => {
                    setSelectedFile(event) 
                  }}
                />
                <p style={{ fontWeight: 500 }} className="text-secondary">
                  Format file yang diijinkan: .pdf
                </p>
            </div>
              {showButton(isUploadRequired == false || (isUploadRequired == true && selectedFile != null))}
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == questionTotal + 1 && ( 
          <div className="col-md-12" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h5 className="nlp-content">Resume</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "5vw" }}>
              <Row className="centered-vertical">
                <Col md="auto" className="centered-horizontal" style={{ marginRight: "50px" }}>
                  <div> 
                    <div style={{ marginBottom: "10px" }}>
                      <img src={reviewIcon}/>
                    </div>
                  </div>
                </Col>
                <Col md="auto" style={{ marginRight: "50px" }}>
                  <Row>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Jenis Cuti</Label>
                        <p className="nlp-sub-content">
                          {`${selectedLeaveType.label} (${selectedDetailLeaveType.label})`}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Tanggal Cuti</Label>
                        <p className="nlp-sub-content">{`${moment(selectedStartDate).format('LL')} s/d ${moment(selectedEndDate).format('LL')}`}</p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Alamat Selama Cuti</Label>
                        <p className="nlp-sub-content">{selectedAddress}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Alasan Cuti</Label>
                        <p className="nlp-sub-content">{selectedLeaveReason}</p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Dokumen Pendukung</Label>
                        <p className="nlp-sub-content">{ selectedFile != null ? selectedFile[0].name : "-"}</p>  
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">No Telp Selama Cuti</Label>
                        <p className="nlp-sub-content">{selectedPhone}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {showButton(false)}
          </div>
         ) }
        </AvForm>
      </div>
      <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo={failedInfo}
          showConfirm={confirm}
          showLoading={loading}
          showSuccess={success}
          showFailed={failed}
          response={confirmResponse}
        />
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default PengajuanCutiView;

import {
  apiRequest,
  verifyPdf,
} from "../../../../services/adapters/base";

class VerifyPdfRepository {
  fetchVerify = async (query) => {
    return await apiRequest(
      "post",
      verifyPdf,
      {
        body: query,
      },
      true
    );
  };
}

export default VerifyPdfRepository;

import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Tooltip } from "reactstrap";
import { accessRole } from "../../../../helpers/access_role";
import { config } from "../../../../helpers/config_global";
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import MKIDatatable from "../../../../modules/mki-datatable/lib";
import { cancelToken } from "../../../../services/adapters/base";
import apiResponse from "../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";
import { Icon } from "@iconify/react";
import moment from "moment";

class TableActiveComponent extends Component {
  dataTableBloc = new DataTableBloc();

  userdata = JSON.parse(localStorage.getItem("userdata"));

  source;
  historyTable = "historyTicketTableActive";
  defaultOrder = "created_at";
  defaultSize = 10;
  defaultSort = "desc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      detailUuid: null,
      updateUuid: null,
      notulenUuid: null,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      loadingTable: true,
      filter: sessionStorage.getItem(this.historyTable)
        ? JSON.parse(sessionStorage.getItem(this.historyTable))
        : {
            filter_value: "",
            page_number: 1,
            page_size: this.defaultSize,
            sort_order: {
              column: this.defaultOrder,
              order: this.defaultSort,
            },
          },
    };
  }
  toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    /* kirim ke server */
    let offset =
      (this.state.filter.page_number - 1) * this.state.filter.page_size;
    if (this.state.defaultSize !== this.state.filter.page_size) {
      this.setState({
        defaultSize: this.state.filter.page_size,
      });
      offset = 0;
      this.configDatatable.page_number = 1;
    }
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: offset,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      status: [0, 1, 2, 3],
      employee_uuid: this.userdata.employee_uuid,
      start_date: this.props.filter?.helpdeskStartDate,
      end_date: this.props.filter?.helpdeskEndDate,
    };
    await this.dataTableBloc.fetchRowTable(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  closeTicket = async () => {
    await this.dataTableBloc.fetchCloseTicket({
      ticket_uuid: this.state.inactiveUuid,
    });
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.closeTicket();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.setTokenAPI();

    this.dataTableBloc.rowtableChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            totalRow: response.total,
            rows: response.result,
            loadingTable: false,
          });
          this.props.cb(response.total, "open");
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.dataTableBloc.closeTicketChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState(
            {
              loading: false,
              success: true,
              loadingTable: true,
            },
            function () {
              this.setTokenAPI();
              this.props.handleInactiveSuccess();
            }
          );
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.dataTableBloc.rowtableChannel.unsubscribe();
  }

  columns = [
    {
      key: "ticket_tracking_id",
      text: "Tracking ID",
      className: "address",
      sortable: true,
      cell: (ticket) => {
        return (
          <Link to={`/admin/helpdesk.html?detail&uuid=${ticket.ticket_uuid}`}>
            <span style={{ fontWeight: 500 }}>
              {ticket.ticket_tracking_id.toUpperCase()}
            </span>
          </Link>
        );
      },
    },
    {
      key: "ticket_init_name",
      text: "Nama",
      className: "address",
      sortable: true,
    },
    //desc
    {
      key: "ticket_subject",
      text: "Subjek",
      className: "subjek",
      sortable: true,
    },
    {
      key: "service_name",
      text: "Layanan",
      className: "service",
      sortable: true,
    },
    {
      key: "module_name",
      text: "Modul",
      className: "module",
      sortable: true,
    },
    //status
    {
      key: "ticket_status",
      text: "Status",
      className: "status",
      sortable: true,
      cell: (ticket) => {
        let className = "";
        let text = "";

        switch (ticket.ticket_status) {
          case 0:
            className = "text-danger";
            text = "New";
            break;

          case 1:
            className = "text-success";
            text = "Open";
            break;

          case 2:
            className = "text-warning";
            text = "Menunggu balasan";
            break;

          case 3:
            className = "text-primary";
            text = "Dibalas";
            break;

          case 4:
            className = "text-purple";
            text = "Closed";
            break;

          default:
            text = "-";
            break;
        }

        return (
          <span
            className={className}
            style={{ fontSize: ".75rem", fontWeight: 500 }}
          >
            {text}
          </span>
        );
      },
    },
    //location
    {
      key: "ticket_last_replier_name",
      text: "Terakhir dibalas",
      className: "last_reply",
      sortable: true,
      width: "10%",
    },
    {
      key: "created_at",
      text: "Tanggal Dibuat",
      className: "created_at",
      sortable: true,
      cell: ({ created_at }) => {
        return <>{moment(created_at).format("lll")}</>;
      },
    },
    {
      key: "updated_at",
      text: "Update Terakhir",
      className: "address",
      sortable: true,
      cell: ({ updated_at }) => {
        return <>{moment(updated_at).format("lll")}</>;
      },
    },
    
  ];

  render() {
    return (
      <>
        <MKIDatatable
          className={this.configDatatable.tableClass}
          config={this.configDatatable}
          records={this.state.rows}
          columns={this.columns}
					minWidth={1000}
          dynamic={true}
          total_record={this.state.totalRow}
          initial={this.state.filter}
          onChange={(filter) => {
            this.setState(
              {
                loadingTable: true,
                filter: filter,
              },
              function () {
                this.setTokenAPI();
              }
            );
          }}
          loading={this.state.loadingTable}
        />
        {this.state.detailUuid !== null ? (
          <Redirect to={"helpdesk.html?detail&uuid=" + this.state.detailUuid} />
        ) : null}
      </>
    );
  }
}

export default TableActiveComponent;

import React, { createContext, useEffect, useState } from "react";

export const AgendaContext = createContext();

export const AgendaProvider = ({ children }) => {

  let userRole = parseInt(localStorage.getItem("currentRoleId"));
  let userData = JSON.parse(localStorage.getItem("userdata"));
  // states
  const [source, setSource] = useState();
  const [dataForm, setDataForm] = useState({
    agenda_participants: [],
    agenda_type: userRole,
    agenda_nip: userData.employee_nip,
  });

  return (
    <AgendaContext.Provider
      value={{
        dataForm,
        setDataForm,
        source,
        setSource,
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

import { authContext } from "./adalConfig";

const graphApiUrl = 'https://graph.microsoft.com/v1.0';
const me = authContext.getCachedUser()?.profile.oid; 

const acquireToken = () => {
  return new Promise(async (resolve, reject) => {
    try {
      authContext.acquireToken('https://graph.microsoft.com', function (error, token) {
          if (error || !token) {
            console.log('Token acquisition failed:', error);
            reject(null)
          } else {
            localStorage.setItem('adalToken', token)
            resolve(token)
        }
      });
    } catch (error) {
      console.log(error);
      reject(null)
    }
  })
}

const getHeaders = async () => {
  const token = await acquireToken();
    return {
        "Authorization": `Bearer ${token}`,
        "Content-Type":	"application/json"
    };
}

export const getListChat = () => {
  return new Promise(async (resolve, reject) => {
    try {
      
      const endpoint = `/chats?$expand=members,lastMessagePreview&$filter=chatType eq 'oneOnOne'&$orderby=lastMessagePreview/createdDateTime desc`;
      const apiUrl = `${graphApiUrl}${endpoint}`;

      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: await getHeaders(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error:', errorData);
        reject(errorData); // Reject the promise with the error data
        return;
      }

      const data = await response.json();
      
      resolve(data); // Resolve the promise with the response data
    } catch (error) {
      console.error('Gagal:', error);
      reject(error); // Reject the promise with the error
    }
  });
};

export const getChat = (chatId) => {
  return new Promise(async (resolve, reject) => {
    try {
      
      const endpoint = `/chats/${chatId}?$expand=members`;
      const apiUrl = `${graphApiUrl}${endpoint}`;

      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: await getHeaders(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error:', errorData);
        reject(errorData); // Reject the promise with the error data
        return;
      }

      const data = await response.json();
      
      resolve(data); // Resolve the promise with the response data
    } catch (error) {
      console.error('Gagal:', error);
      reject(error); // Reject the promise with the error
    }
  });
};

export const createChat = (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = "/chats";
        const apiUrl = `${graphApiUrl}${endpoint}`;
  
        const chatData = {
          chatType: 'oneOnOne',
          members: [
            {
              '@odata.type': '#microsoft.graph.aadUserConversationMember',
              roles: ['owner'],
              'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${me}')`,
            },
            {
              '@odata.type': '#microsoft.graph.aadUserConversationMember',
              roles: ['owner'],
              'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${userId}')`,
            },
          ],
        };
  
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: await getHeaders(),
          body: JSON.stringify(chatData),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error:', errorData);
          reject(errorData); // Reject the promise with the error data
          return;
        }
  
        const data = await response.json();
        
        resolve(data); // Resolve the promise with the response data
      } catch (error) {
        console.error('Gagal:', error);
        reject(error); // Reject the promise with the error
      }
    });
};

export const sendMessage = (chatId, message) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = `/chats/${chatId}/messages`;
        const apiUrl = `${graphApiUrl}${endpoint}`;
  
        const chatMessage = {
            body: {
                content: message
            }
        };

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: await getHeaders(),
          body: JSON.stringify(chatMessage),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error:', errorData);
          reject(errorData); 
          return;
        }
  
        const data = await response.json();
        resolve(data);
      } catch (error) {
        console.error('Gagal:', error);
        reject(error);
      }
    });
};

export const getMessages = (chatId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = `/chats/${chatId}/messages`;
        const apiUrl = `${graphApiUrl}${endpoint}`;

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: await getHeaders()
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error:', errorData);
          reject(errorData); 
          return;
        }
  
        const data = await response.json();
        resolve(data);
      } catch (error) {
        console.error('Gagal:', error);
        reject(error);
      }
    });
};

export const getListUser = (search = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      var endpoint = `/users`;
      if (search != null && search != "") {
        endpoint = `/users?$search="displayName:${search}"`;
      }

      const apiUrl = `${graphApiUrl}${endpoint}`;

      const defaultHeader = await getHeaders()
      const header = {...defaultHeader,...{ConsistencyLevel: "eventual"}}

      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: header
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error:', errorData);
        reject(errorData); 
        return;
      }

      const data = await response.json();
      resolve(data);
    } catch (error) {
      console.error('Gagal:', error);
      reject(error);
    }
  });
};

export const markChatAsRead = async (chatId) => {
    const endpoint = `/chats/${chatId}/markChatReadForUser`;
    const apiUrl = `${graphApiUrl}${endpoint}`;

    const body = {  
      user: {
        id: me,
        tenantId: process.env.REACT_APP_ADAL_TENANT
      }    
    };

    await fetch(apiUrl, {
      method: 'POST',
      headers: await getHeaders(),
      body: JSON.stringify(body),
    });
};


export const currentUser = authContext.getCachedUser()


import {
    apiRequest as baseApi,
    authConnect as baseAuthConnect,
    authGetAccessRole as baseAuthGetAccessRole,
    authGetAccessMenus as baseAuthGetAccessMenus,
    userGetData
} from "./services/adapters/base"

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import Preload from './components/Preload'

import ErrorNetwork from './components/Error/Network'
import ErrorAccess from './components/Error/Access'
import ErrorUser from './components/Error/User'
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import imglyRemoveBackground, {Config} from "@imgly/background-removal"

import store from "./store"

import NotificationService from "./services/notification"

/* sessionStorage.clear()
localStorage.clear() */


sessionStorage.getItem('preload', '')

testConnection()

async function testConnection() {
  
  ReactDOM.render(<Preload text="Memeriksa koneksi..." />, document.getElementById("root"))
  await baseApi("get", baseAuthConnect, {}, false)
  .then(async result => {
    if(result.serverTime) {
        localStorage.setItem('localTime', new Date().getTime())
        localStorage.setItem('serverTime', result.serverTime)
    }

    /* var lastSync = await localStorage.getItem('preload')
    const thisTime = (Math.floor(Date.now()/1000))

    if(lastSync !== null && thisTime <= lastSync+6000 ) {
      ReactDOM.render(<Preload text="Mempersiapkan sistem..." />, document.getElementById("root"))
      gotoRoot()
    } else {
      getUserdata()
      await localStorage.setItem('preload', thisTime)
    } */
    getUserdata()
  })
  .catch(result => {
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })
}

async function getUserdata() {
  ReactDOM.render(<Preload text="Mengambil detail login..." />, document.getElementById("root"))
  await baseApi("post", userGetData, {}, true)
  .then(async result => {
    if(result.status) {
      localStorage.setItem('tokenId', result.response.tokenId)
      localStorage.setItem('userdata', JSON.stringify(result.response.userdata))
      localStorage.setItem('showTour', result.response.userdata.employee_show_tour)

      baseApi("post", "user/getBase64PhotoRemovedBackground/", {body: {"employee_uuid": result.response.userdata.employee_uuid}}, true)
      .then(async result => {
        if(result.status) {
          if (result.response == null) {
            baseApi("post", "user/getBase64Photo/", {body: {"user_data": JSON.parse(localStorage.getItem("userdata"))}}, true)
            .then(async result => {
              if(result.status) {
                let config = {
                  model: 'small',
                  debug: true,
                  progress: (key, value, total) => {
                    console.log(`${key}: ${value}/${total}`);
                  },
                };
                // try{
                //   imglyRemoveBackground("data:image/jpeg;base64," + result.response, config).then((blob) => {
                //     localStorage.setItem('photo_without_background', URL.createObjectURL(blob))
                //     var reader = new FileReader();
                //     reader.readAsDataURL(blob); 
                //     reader.onloadend = function() {
                //       var base64data = reader.result;                
                //       baseApi("post", "user/savePhotoBackgroundRemoved/", {body: {"employee_uuid": JSON.parse(localStorage.getItem("userdata")).employee_uuid, "employee_photo_background_removed": base64data}}, true)
                //       .catch(result => {
                //         console.log(result)
                //       })
                //     }
                //   })
                // }catch(e) {}
              } 
            })
            .catch(result => {
              console.log(result)
            })
          } else {
            localStorage.setItem('photo_without_background', result.response);  
          }
        } 
      })
      .catch(result => {
        console.log(result)
      })
      
      getAccessRole()
    } else {
      ReactDOM.render(<ErrorUser />, document.getElementById("root"))
    }
  })
  .catch(result => {
    console.log(result)
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })

}

async function getAccessRole() {
  ReactDOM.render(<Preload text="Verifikasi Role Access..." />, document.getElementById("root"))
  await baseApi("get", baseAuthGetAccessRole)
  .then(result => {
    const roleList = result.response
      if(roleList.length>0) {
        if(!localStorage.getItem('currentRoleId')) {
          localStorage.setItem('currentRoleId', roleList[0].access_id)
          localStorage.setItem('currentRoleName', roleList[0].access_name)
        }
        sessionStorage.setItem('allRole', JSON.stringify(roleList))
        getAccessMenu()
      } else {
        ReactDOM.render(<ErrorAccess />, document.getElementById("root"))
      }
  })
  .catch(result => {
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })
}

async function getAccessMenu() {
  ReactDOM.render(<Preload text="Mempersiapkan sistem..." />, document.getElementById("root"))
  await baseApi("get", baseAuthGetAccessMenus)
  .then(result => {
    const dataAccessKey = []
    const dataAccessValue = []
    const dataBreadcrumb = []
    var key = 0
    result.response.map((level1) => {
      if(level1.child.length>0) {
        level1.child.map((level2) => {
          if(level2.child.length>0) {
            level2.child.map((level3) => {
              if(level3.child.length===0) {
                dataAccessKey[key] = level3.link
                dataAccessValue[key] = level3.access
                dataBreadcrumb[key] = level3.breadcrumb
                ++key
              }
              return null
            })
          } else {
            dataAccessKey[key] = level2.link
            dataAccessValue[key] = level2.access
            dataBreadcrumb[key] = level2.breadcrumb
            ++key
          }
          return null
        })
      } else {
        dataAccessKey[key] = level1.link
        dataAccessValue[key] = level1.access
        dataBreadcrumb[key] = level1.breadcrumb
        ++key
      }
      return null
    })

    sessionStorage.setItem('jsonMenu', JSON.stringify(result.response))
    sessionStorage.setItem('accessPath', JSON.stringify(dataAccessKey))
    sessionStorage.setItem('accessValue', JSON.stringify(dataAccessValue))
    sessionStorage.setItem('accessBreadcrumb', JSON.stringify(dataBreadcrumb))

    /* const app = (
      <Provider store={store}>
        <NotificationService />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    )
    ReactDOM.render(app, document.getElementById("root"))
    serviceWorker.unregister()
    serviceWorker.register() */
    gotoRoot()
  })
  .catch(result => {
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })
}

function gotoRoot() {
  const app = (
    <Provider store={store}>
      <NotificationService />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
  ReactDOM.render(app, document.getElementById("root"))
  serviceWorker.unregister()
  //serviceWorker.register()
}
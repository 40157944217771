import { Component } from "react";
import { agenda } from "../../../data";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { isValidHttpUrl } from "../../../helpers/utility";
import apiResponse from "../../../services/apiResponse";
import moment from "moment";

import DetailBloc from "./Blocs/DetailBloc";

import FooterSection from "../Index/Components/Footer";
import Header from "../../../components/DefaultLayout/Header/header";

class IndexView extends Component {
  detailBloc = new DetailBloc();

  constructor(props) {
    super(props);
    this.state = {
      showModalAgenda: false,
    };
  }

  toggleModalAgenda = () => {
    this.setState({
      showModalAgenda: !this.state.showModalAgenda,
    });
  };

  componentDidMount() {
    const header = document.getElementById("header");

    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              agenda: response,
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ uuid: queryParams.get("agenda") });
    header.scrollIntoView();
  }

  componentWillUnmount() {
    this.detailBloc.detailChannel.unsubscribe();
  }

  render() {
    return (
      <>
        <div
          className="main"
          style={{
            background: "#F8F9F9",
            position: "relative",
            minHeight: "100vh",
          }}
        >
          <Header />
          {this.state.agenda ? (
            <div className="container" style={{ padding: "6rem 15rem" }}>
              <div className="container py-5">
                <Link
                  className="back-btn-container"
                  style={{
                    display: "flex",
                    columnGap: ".5rem",
                    alignItems: "center",
                    fontSize: "11pt",
                    cursor: "pointer",
                  }}
                  to="home.html"
                >
                  <Icon icon="akar-icons:arrow-left" />
                  <span>Kembali</span>
                </Link>
                <div
                  className="content mt-5"
                  data-aos="zoom-in-up"
                  data-aos-duration="1100"
                  data-aos-delay="200"
                >
                  <h3 className="mb-4" style={{ fontWeight: "bold" }}>
                    {this.state.agenda.agenda_name}
                  </h3>
                  <div
                    className="d-flex mb-1"
                    style={{
                      alignItems: "center",
                      columnGap: ".5rem",
                      fontWeight: "11pt",
                    }}
                  >
                    <Icon
                      icon="ant-design:calendar-filled"
                      style={{ color: "#4EB6E2", fontSize: "12pt" }}
                    />
                    <span style={{ color: "#827E9A", fontSize: "12pt" }}>
                      {moment(
                        `${this.state.agenda.agenda_start_date}T${this.state.agenda.agenda_start_time}`
                      ).format("LLL") +
                        ` s/d ${this.state.agenda.agenda_end_time}`}
                    </span>
                  </div>

                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      columnGap: ".5rem",
                      fontWeight: "11pt",
                    }}
                  >
                    <Icon
                      icon="fluent:location-12-filled"
                      style={{ color: "#4EB6E2", fontSize: "12pt" }}
                    />
                    <span style={{ color: "#827E9A", fontSize: "12pt" }}>
                      {isValidHttpUrl(this.state.agenda.agenda_location) ? (
                        <a
                          href={this.state.agenda.agenda_location}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {this.state.agenda.agenda_location}
                        </a>
                      ) : (
                        this.state.agenda.agenda_location
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <img
                src="img/ornaments/Agenda_bg.svg"
                alt="ornaments"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "-20rem",
                  transform: "translate(50%, -50%)",
                  width: "23rem",
                  userSelect: "none",
                  MozUserSelect: "none",
                }}
              />
            </div>
          ) : null}
        </div>
        <FooterSection noSubscription={true} />
      </>
    );
  }
}

export default withRouter(IndexView);

import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import UpdateRepository from "../Repositories/UpdateRepository";

class UpdateBloc {
  detailChannel = new Subject();
  updateChannel = new Subject();
  notulensiChannel = new Subject();
  repository = new UpdateRepository();

  constructor() {
    this.updateChannel.next({ status: apiResponse.INITIAL });
  }

  fetchDetail = async (query) => {
    this.detailChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchDetail(query)
      .then((result) => {
        this.detailChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.detailChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchUpdate = async (query) => {
    this.updateChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchUpdate(query)
      .then((result) => {
        this.updateChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.updateChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default UpdateBloc;

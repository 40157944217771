import { Subject } from "rxjs";
import apiResponse from "../../../services/apiResponse";
import CreateRepository from "../repositories/EmployeeRepository";

class EmployeeBloc {  
  employeeChannel = new Subject();
  repository = new CreateRepository();

  constructor() {
    this.employeeChannel.next({ status: apiResponse.INITIAL });
  }


  fetchSearch = async (query, cancelToken, cb = null) => {
		this.employeeChannel.next({ status: apiResponse.LOADING });
		try {
			await this.repository
				.fetchSearch(query, cancelToken)
				.then((result) => {
					this.employeeChannel.next({
						status: apiResponse.COMPLETED,
						data: result,
					});
					if (cb) cb(result);
				})
				.catch((error) => {
					this.employeeChannel.next({ status: apiResponse.ERROR, data: error });
				});
		} catch (error) {}
	};
}

export default EmployeeBloc;

import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import ReplyRepository from "../Repositories/ReplyRepository";

class ReplyBloc {
  replyChannel = new Subject();
  repliesChannel = new Subject();
  repository = new ReplyRepository();

  constructor() {
    this.replyChannel.next({ status: apiResponse.INITIAL });
    this.repliesChannel.next({ status: apiResponse.INITIAL });
  }

  fetchReply = async (query) => {
    this.replyChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchReply(query)
      .then((result) => {
        this.replyChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.replyChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchReplies = async (query) => {
    this.repliesChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchReplies(query)
      .then((result) => {
        this.repliesChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.repliesChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default ReplyBloc;

import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import TicketRepository from "../Repositories/TicketRepository";

class TicketBloc {
  createChannel = new Subject();
  listChannel = new Subject();
  closeTicketChannel = new Subject();
  detailChannel = new Subject();
  replyChannel = new Subject();
  repliesChannel = new Subject();
  repository = new TicketRepository();

  constructor() {
    this.createChannel.next({ status: apiResponse.INITIAL });
    this.listChannel.next({ status: apiResponse.INITIAL });
    this.replyChannel.next({ status: apiResponse.INITIAL });
    this.repliesChannel.next({ status: apiResponse.INITIAL });
  }

  fetchList = async (query, cancelToken) => {
    this.listChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchList(query, cancelToken)
        .then((result) => {
          this.listChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.listChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchCreate = async (query) => {
    this.createChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchCreate(query)
      .then((result) => {
        this.createChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.createChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchDetail = async (query) => {
    this.detailChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchDetail(query)
      .then((result) => {
        this.detailChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.detailChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchCloseTicket = async (query) => {
    this.closeTicketChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchCloseTicket(query)
      .then((result) => {
        this.closeTicketChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.closeTicketChannel.next({
          status: apiResponse.ERROR,
          data: error,
        });
      });
  };

  fetchReply = async (query) => {
    this.replyChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchReply(query)
      .then((result) => {
        this.replyChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.replyChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchReplies = async (query) => {
    this.repliesChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchReplies(query)
      .then((result) => {
        this.repliesChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.repliesChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default TicketBloc;

import { apiRequest, guideCreate, guideCategoryList, searchEmployee } from "../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      guideCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchGuideCategories = async (query) => {
    return await apiRequest(
      "get",
      guideCategoryList,
      {
        body: query,
      },
      true
    );
  };

  fetchSearch = async (query, cancelToken) => {
		return await apiRequest(
			"get",
			searchEmployee,
			{
				cancelToken: cancelToken,
				params: query,
			},
			true
		);
	};
}

export default CreateRepository;

import {
    apiRequest,
    guideList,
    guideCategoryList
  } from "../../../../services/adapters/base";
  
  class GuideRepository {
    fetchList = async (query, cancelToken) => {
      return await apiRequest(
        "get",
        guideList,
        {
          cancelToken: cancelToken,
          params: query,
        },
        true
      );
    };

    fetchCategories = async (query, cancelToken) => {
      return await apiRequest(
        "get",
        guideCategoryList,
        {
          cancelToken: cancelToken,
          params: query,
        },
        true
      );
    };
  }
  
  export default GuideRepository;
  
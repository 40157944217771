import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Button, Row, Col, Label, Input } from "reactstrap"
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  FileInput,
  generateFormData
} from "../../../../helpers/ui/FileInput";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import listenGif from "../../../../assets/videos/listening.gif"
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { Link, Redirect } from "react-router-dom";
import CustomSelect from "../../../../helpers/ui/CustomSelect"
import reviewIcon from '../../../../assets/icons/icon_review_summary.png';
import Lottie from "react-lottie";
import animationData from "../../../../assets/lotties/nlp.json"
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function PeminjamanPeralatanView(props) {

  const baseUrl = process.env.REACT_APP_INVENTORY_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_INVENTORY_SERVICE_BASE_APPCODE;
  const title = "Pengajuan Peminjaman Barang";
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentAccessToken, setCurrentAccessToken] = useState()
  const questions = ["Silakan pilih tanggal peminjaman", "Silakan pilih jenis barang yang dipinjam", "Silakan tambahkan tujuan peminjaman"];
  const questionTotal = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [tools, setTools] = useState([])

  const [selectedStartDate, setSelectedStartDate] = useState()
  const [selectedEndDate, setSelectedEndDate] = useState()
  const [selectedTools, setSelectedTools] = useState([
    {
      tool_uuid: null,
      tool_name: null,
      qty: null
    }
  ])
  const [selectedReason, setSelectedReason] = useState(null);

  const [confirm, setConfirm] = useState()
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [failed, setFaield] = useState()
  const [redirect, setRedirect] = useState()

  const {
    transcript,
    listening,
  } = useSpeechRecognition();

  const startListen = () => {
    SpeechRecognition.startListening({ language: 'id'})
  }

  useEffect(() => {
    setSelectedReason(transcript)
  }, [transcript]);

  useEffect(() => {
    const fetchAccessToken = () => {
      try {
        axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCode,
            email: userData.employee_email
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentAccessToken(accessToken)
          fetchTools(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchTools = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}prosespeminjaman/peralatan/listreftools/json`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });
        setTools(response.data.response.result.map((tool) => {
          return {
            label: tool.tool_name,
            value: tool.tool_uuid,
          };
        }),)  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAccessToken()
  }, []); 

  const submitData = async () => {
    const dataForm = {
      loantool_employee_uuid: userData.employee_uuid,
      sync_employee_fullname: userData.employee_name,
      sync_employee_current_position: userData.employee_current_position_name,
      sync_employee_current_unit_work: userData.employee_current_work_unit_name,
      loantool_applicant_contact_number: userData.employee_phone_number,
      loantool_date_from: selectedStartDate,
      loantool_date_until: selectedEndDate,
      loantool_list_uuid: selectedTools.map((item) => item.tool_uuid).join(","),
      loantool_list_qty: selectedTools.map((item) => item.qty).join(","),
      loantool_for: selectedReason
    }

    const response = await axios.post(`${baseUrl}prosespeminjaman/peralatan/create/json`, dataForm, {
      headers: {
        appCode: appCode,
        email: userData.employee_email,
        accessToken: currentAccessToken
      },
    });

    if (response.data.status == true) {
      setSuccess(true)
    } else {
      setFaield(true)
    }
    setLoading(false)
  }

  const confirmResponse = (response) => {
    switch (response) {
      case "cancel":
        setConfirm(false)
        break;
      case "confirm":
        setLoading(true)
        setConfirm(false)        
        submitData();
        break;
      case "success":
        setSuccess(false)
        setRedirect(true)
        break;
      case "failed":
        setFaield(false)
        break;
      default:
    }
  };

  const showButton = (showNextButton) => {
    return (
      <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", bottom: 0, right: 0, zIndex: 99 }}>
          {currentQuestion > 1 && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              Kembali
            </Button> 
          )}

          {showNextButton == true && currentQuestion != questionTotal && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Selanjutnya
            </Button> 
          )}

          {showNextButton == true && currentQuestion == questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Review
          </Button> 
          )}

          { currentQuestion > questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setConfirm(true)}
          >
            Submit
          </Button> 
          )}
        </div>
    )
  }

  const addTool = () => {
    setSelectedTools((selectedTools) => [
      ...selectedTools,
      { tool_uuid: null, tool_name: null, qty: null }
    ]);
  };

  const removeTool = (indexToRemove) => {
    setSelectedTools((selectedTools) =>
      selectedTools.filter((_, index) => index !== indexToRemove)
    );
  };

  const updateToolUuid = (index, toolUuid, toolName) => {
    setSelectedTools((selectedTools) => {
      const updatedTools = [...selectedTools];
      updatedTools[index] = {
        ...updatedTools[index],
        tool_uuid: toolUuid,
        tool_name: toolName
      };
      return updatedTools;
    });
  };

  const updateToolQty = (index, newValue) => {
    setSelectedTools((selectedTools) => {
      const updatedTools = [...selectedTools];
      updatedTools[index] = {
        ...updatedTools[index],
        qty: newValue
      };
      return updatedTools;
    });
  };

  const showLottie = () => {
    return <Lottie options={{ 
      loop: true,
      autoplay: true, 
      animationData: animationData
     }}
      isClickToPauseDisabled={true}
      height="100%"
      width="100%"
      isStopped={false}
      isPaused={false}/>
  }
  
  return (
    <>
      {currentQuestion <= questionTotal && (
        <div className="mb-4" style={{ position: "fixed", top: 0, width: "100%" }}>
          <Progress color="info" value={currentQuestion / questionTotal * 100} />
        </div>
      )}
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {currentQuestion <= questionTotal ? (
          <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99 }}>
            Pertanyaan {currentQuestion} dari {questionTotal}
          </div>
        ) : <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
        <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
      </div>}

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px"}} >
        
        { currentQuestion == 1 && (
          <Row className="centered-vertical">

            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[0]}</p>
              <div>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="start-date-input">Tanggal Mulai</Label>
                    <input
                      className="form-control"
                      type="date"
                      value={selectedStartDate}
                      onChange={(event) => {
                        setSelectedStartDate(event.target.value);
                        if (selectedEndDate != null) {
                          setCurrentQuestion(currentQuestion + 1)
                        }
                      }}
                      id="start-date-input"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="end-date-input">Tanggal Selesai</Label>
                    <input
                      className="form-control"
                      type="date"
                      value={selectedEndDate}
                      onChange={(event) => {
                        setSelectedEndDate(event.target.value);
                        if (selectedStartDate != null) {
                          setCurrentQuestion(currentQuestion + 1)
                        }
                      }}
                      id="end-date-input"
                    />
                  </div>
                </Col>
              </Row>
              </div>
              {showButton(selectedStartDate != null && selectedEndDate != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 2 && (
          <Row className="centered-vertical">

            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[1]}</p>
              <div>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      {selectedTools.map((tool, index) => {
                        return <Row>
                        <Col md="5">
                          <CustomSelect
                            name="tool_uuid"
                            id="tool_uuid"
                            options={tools}
                            validate={{ required: { value: true } }}
                            defaultValue={tool["tool_uuid"]}
                            onChange={(data) => {
                              updateToolUuid(index, data.value, data.label)
                            }} 
                          />
                        </Col>
                        <Col md="5">
                          <AvField
                            name="tool_qty"
                            id="tool_qty"
                            placeholder="Quantity"
                            type="number"
                            value={tool["qty"]}
                            className="form-control"
                            onChange={(event) => updateToolQty(index, event.target.value)}
                          />
                        </Col>
                        {index == 0 ? (
                          <Col md="2">
                            <Button color="success" onClick={() => addTool() }>
                              <i className="uil-plus"></i>
                            </Button>
                          </Col>
                        ) : (
                          <Col md="2">
                            <Button color="danger" onClick={() => removeTool(index) }>
                              <i className="uil-trash"></i>
                            </Button>
                          </Col>
                        )}
                      </Row>
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
              {showButton(selectedTools[0].tool_uuid != null && selectedTools[0].qty != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == 3 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[2]}</p>
            
              <div className="pt-2" style={{ width: "100%", height: "50px", borderBottom: "2px solid #DCDCDC", position: "relative"}}>
                <input
                  type="text"
                  value={selectedReason}
                  style={{fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingRight: "50px"}}
                  onChange={(event) => {
                    setSelectedReason(event.target.value)
                  }}
                />
                <i className="uil-microphone" onClick={startListen} 
                  style={{ 
                    fontSize: '24px',  
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    cursor: 'pointer',}}/>
              </div>
            </div>
            <div className="col-md-6">
              {listening ? (
                <div className="centered-horizontal">
                  <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
                </div>
              ) : showLottie()}
            </div>
            {showButton(selectedReason != null && selectedReason != "")}
          </Row>
        )}

        { currentQuestion == questionTotal + 1 && ( 
          <div className="col-md-12" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h5 className="nlp-content">Resume</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "5vw" }}>
              <Row className="centered-vertical">
                <Col className="centered-horizontal" md="auto" style={{ marginRight: "80px" }}>
                  <div> 
                    <div style={{ marginBottom: "10px"}}>
                      <img src={reviewIcon}/>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col md="auto" style={{ marginRight: "50px" }}>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Tanggal Peminjaman</Label>
                        <p className="nlp-sub-content">{`${moment(selectedStartDate).format('LL')} s/d ${moment(selectedEndDate).format('LL')}`}</p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Barang</Label>
                        <ul className="list-unstyled nlp-sub-content">
                          {selectedTools.map((tool, index) => {
                            return <li>
                              <i className="mdi mdi-circle-medium text-warning me-2"></i> {`${tool["tool_name"]} (${tool["qty"]})`}
                            </li>
                          })}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Alasan Peminjaman</Label>
                        <p className="nlp-sub-content">{selectedReason}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {showButton(false)}
          </div>
         ) }
        </AvForm>
      </div>
      <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={confirm}
          showLoading={loading}
          showSuccess={success}
          showFailed={failed}
          response={confirmResponse}
        />
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default PeminjamanPeralatanView;

import React from "react";
import { Icon } from "@iconify/react";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import AgendaModal from "../AgendaModal";
import { Waypoint } from "react-waypoint";
import AgendasCalendar from "../AgendasCalendar";

class AgendaSection extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      agendas: {},
      showModalAgenda: false,
    };
  }

  refetchEvents = () => {
    this.setState({
      ...this.state,
      refresh: !this.state.refresh,
    });
  };

  toggleModalAgenda = () => {
    this.setState({
      showModalAgenda: !this.state.showModalAgenda,
    });
  };

  hanldeWaypointEnter = () => {
    const { setActiveNav } = this.context;

    setActiveNav("Agenda");
  };

  render() {
    return (
      <section
        className="agenda-section ptb-100"
        style={{ backgroundColor: "#F8F9FC" }}
        id="agenda"
      >
        <div className="container">
          <div className="row">

            <div className="section-heading" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <h2
                style={{ fontWeight: "bold", color: "#0F0465" }}
              >
                Agenda Bulan Ini{" "}
                <Icon
                  icon="akar-icons:circle-plus-fill"
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleModalAgenda}
                />
              </h2>

              <button
                type="button"
                className="btn btn-primary d-flex"
                onClick={this.refetchEvents}
                style={{ alignItems: "center", columnGap: ".5rem" }}
              >
                <Icon icon="icon-park-outline:refresh" />
                <span>Refresh</span>
              </button>

            </div>
          </div>
          <Waypoint scrollableAncestor={window} onEnter={this.hanldeWaypointEnter} />
          <AgendasCalendar
            refresh={this.state.refresh}
            refetchEvents={this.state.refresh}
            toggleRefetch={this.refetchEvents}
          />
        </div>

        <AgendaModal
          showModalAgenda={this.state.showModalAgenda}
          toggleModalAgenda={this.toggleModalAgenda}
          token={this.props.token}
          refetchEvents={this.refetchEvents}
        />
      </section>
    );
  }
}

export default AgendaSection;

import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import AnnouncementBloc from "../../Blocs/AnnouncementBloc";
import "./style.scss";


class PengumumanSection extends React.Component {
  static contextType = GlobalContext;
  announcementBloc = new AnnouncementBloc();
  userdata = JSON.parse(localStorage.getItem("userdata"));
  source;
  constructor(props) {
    super(props);
    this.state = {
      filter: sessionStorage.getItem(this.historyTable)
        ? JSON.parse(sessionStorage.getItem(this.historyTable))
        : {
          filter_value: "",
          page_number: 1,
          page_size: this.defaultSize,
          sort_order: {
            column: this.defaultOrder,
            order: this.defaultSort,
          },
        },
    };
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    /* kirim ke server */
    const query = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      employee_uuid: this.userdata.employee_uuid
    };
    await this.announcementBloc.fetchList(query, this.source.token);
  };

  componentDidMount() {
    this.setTokenAPI();
    this.announcementBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            announcements: response.result,
            loadingTable: false,
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  setAttachmentIcon = (item) => {
    let icon = "ant-design:file-text-filled";
    if (item.attachment_name) {
      switch (item.attachment_name.split(".")[1]) {
        case "pdf":
          icon = "bx:bxs-file-pdf";
          break;
        case "xlsx":
          icon = "ant-design:file-excel-filled";
          break;
        case "xls":
          icon = "ant-design:file-excel-filled";
          break;
        case "docx":
          icon = "bx:bxs-file-doc";
          break;
        case "doc":
          icon = "bx:bxs-file-doc";
          break;
        case "jpg":
          icon = "ant-design:file-image-filled";
          break;
        case "jpeg":
          icon = "ant-design:file-image-filled";
          break;
        case "png":
          icon = "ant-design:file-image-filled";
          break;
        default:
          break;
      }
    }
    return icon;
  }

  isJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }

  setAttachmentContent = (item) => {
    try {
      return JSON.parse(item.announcement_content);
    } catch (e) {
      return item.announcement_content;
    }
  }

  componentWillUnmount() {
    this.announcementBloc.listChannel.unsubscribe();
  }

  hanldeWaypointEnter = () => {
    const { setActiveNav } = this.context;

    setActiveNav("Pengumuman");
  };

  content = (item) => {
    return <div>
      {this.isJSON(item.announcement_content) ?
        <div className="text-overflow content">
          {<div dangerouslySetInnerHTML={{ __html: item.announcement_content_html }} />}
        </div>

        : <div className="text-overflow content">
          {<div dangerouslySetInnerHTML={{ __html: item.announcement_content != null ? item.announcement_content : item.announcement_title }} />}
        </div>}
    </div>
  }

  render() {
    return (
      <section
        className="pengumuman-section ptb-100"
        style={{ backgroundColor: "#F8F9FC" }}
        id="pengumuman"
      >
        {this.state.announcements ? (
          <div className="container">
            <div className="row">
              <div className="section-heading " style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h2
                  style={{ fontWeight: "bold", color: "#0F0465" }}
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  Pengumuman
                </h2>
                <a
                  className="btn-see-all"
                  href="admin/pengumuman.html"
                  target="_blank" rel="noreferrer"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  Lihat Semua
                </a>
              </div>
            </div>

            <Waypoint scrollableAncestor={window} onEnter={this.hanldeWaypointEnter} />
            <div className="row mt-4">
              {this.state.announcements.map((item, idx) => {
                return (
                  idx < 2 && (
                    <div
                      style={{ marginBottom: "30px" }}
                      className="col-md-12"
                      data-aos="zoom-in"
                      data-aos-duration="1100"
                      data-aos-delay="500"
                      key={idx + 1}
                    >
                      <Link
                        className="bg-white d-flex"
                        style={{
                          background: "#FFFFFF",
                          boxShadow: "0px 11px 14px rgba(0, 0, 0, 0.08)",
                          borderRadius: "8px",
                          height: "15rem",
                          color: "#323232",
                        }}
                        to={`?announcement=${item.announcement_uuid}`}
                      >
                        <div
                          className="file-type-container px-4 d-flex"
                          style={{
                            position: "relative",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: ".45rem",
                          }}
                        >
                          <div style={{ position: "relative", width: "6rem", height: "6rem" }}>
                            <img
                              src="/img/icons/icon_attachment.png"
                              style={{ width: "100%", height: "100%" }}
                            />
                            <p
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "8px"
                              }}
                            >
                              {item.attachment_name ? item.attachment_name.split(".")[1] : "text"}
                            </p>
                          </div>
                        </div>
                        <div
                          className="content-container p-2"
                          style={{
                            height: "inherit",
                          }}
                        >
                          <div
                            className="file-type-container d-flex"
                            style={{
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            {item.announcement_read_id == null ? <b> {this.content(item)} </b>

                              : this.content(item)}
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                );
              })}

              <div className="row px-0 mx-0">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "flex-end",
                    columnGap: ".5rem",
                  }}
                >
                  {/*
                    <div
                      style={{ fontSize: "24pt", cursor: "pointer" }}
                      title="Sebelumnya"
                    >
                      <Icon icon="bi:arrow-left-short" />
                    </div>

                    <div
                      style={{ fontSize: "24pt", cursor: "pointer" }}
                      title="Selanjutnya"
                    >
                      <Icon icon="bi:arrow-right-short" />
                    </div>
                    */}
                </div>
              </div>
            </div>


          </div >
        ) : null
        }
      </section >
    );
  }
}

export default PengumumanSection;

import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import "./header.scss";

class Header extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    this.setState({
      theposition: scrolled,
    });
    this.toggleNavShadow();
  };

  toggleNavShadow = () => {
    const brandBG = document.querySelector("nav.navbar");

    if (this.state.theposition > 0.0246) {
      brandBG.style.boxShadow = "0 0 14px rgba(0,0,0,0.2)";
    } else {
      brandBG.style.boxShadow = "0 0 14px rgba(0,0,0,0)";
    }
  };

  render() {
    return (
      <React.Fragment>
        <header className="header" id="header">
          <nav
            className="navbar navbar-expand-lg fixed-top bg-white"
            style={{ color: "#48474D" }}
          >
            <div className="container d-flex bar" style={{ padding: 10 }}>
              <Row style={{ width: "100%" }}>
                <Col
                  className="col-md-8 col-sm-10 col-xs-10 m-0 brand-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  id="brand-container"
                >
                  <Link to="/home.html" style={{ display: "block" }}>
                    <div className="d-flex">
                      <img
                        src="/bkpm-horizontal-id.png"
                        alt="logo-bkpm"
                        className="brand m-10"
                        style={{ width: 220 }}
                      />
                    </div>
                  </Link>
                </Col>

                <Col sm={2} xs={2} md={4}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "flex-end" }}
                  >

                  </div>
                </Col>
              </Row>
            </div>
          </nav>
        </header>
      </React.Fragment >
    );
  }
}

export default Header;

import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Button, Row, Col, Label, Input } from "reactstrap"
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import {
  FileInput,
  generateFormData
} from "../../../../helpers/ui/FileInput";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import listenGif from "../../../../assets/videos/listening.gif"
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { Link, Redirect } from "react-router-dom";
import CustomSelect from "../../../../helpers/ui/CustomSelect"
import reviewIcon from '../../../../assets/icons/icon_review_summary.png';
import DatePicker from '../../../../helpers/ui/DatePicker'
import Lottie from "react-lottie";
import animationData from "../../../../assets/lotties/nlp.json"
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function PengajuanSlipGajiView(props) {

  const baseUrlCore = process.env.REACT_APP_SIMKEU_SERVICE_BASE_URL_CORE;
  const baseUrlProcess = process.env.REACT_APP_SIMKEU_SERVICE_BASE_URL_PROCESS;
  const appCodeCore = process.env.REACT_APP_SIMKEU_SERVICE_BASE_APPCODE_CORE;
  const appCodeProcess = process.env.REACT_APP_SIMKEU_SERVICE_BASE_APPCODE_PROCESS;
  const title = "Pengajuan Permohonan Slip Gaji";
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentCoreAccessToken, setCurrentCoreAccessToken] = useState()
  const [currentProcessAccessToken, setCurrentProcessAccessToken] = useState()
  const [currentTokenId, setCurrentTokenId] = useState()
  const questions = ["Silakan pilih tahun", "Silakan pilih bulan", "Silakan pilih jumlah bulan"];
  const questionTotal = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [years, setYears] = useState([])
  const months = [
    {
      label: "Januari",
      value: 1
    },
    {
      label: "Februari",
      value: 2
    },
    {
      label: "Maret",
      value: 3
    },
    {
      label: "April",
      value: 4
    },
    {
      label: "Mei",
      value: 5
    },
    {
      label: "Juni",
      value: 6
    },
    {
      label: "Juli",
      value: 7
    },
    {
      label: "Agustus",
      value: 8
    },
    {
      label: "September",
      value: 9
    },
    {
      label: "Oktober",
      value: 10
    },
    {
      label: "November",
      value: 11
    },
    {
      label: "Desember",
      value: 12
    },
  ]

  const totalMonths = [
    {
      label: "1 Bulan",
      value: 1
    },
    {
      label: "3 Bulan",
      value: 3
    },
    {
      label: "6 Bulan",
      value: 6
    },
    {
      label: "12 Bulan",
      value: 12
    }
  ]

  const [selectedYear, setSelectedYear] = useState({})
  const [selectedMonth, setSelectedMonth] = useState({})
  const [selectedTotalMonth, setSelectedTotalMonth] = useState({})

  const [confirm, setConfirm] = useState()
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [failed, setFaield] = useState()
  const [redirect, setRedirect] = useState()


  useEffect(() => {
    const fetchCoreAccessToken = () => {
      try {
        axios.post(`${baseUrlCore}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCodeCore,
            email: userData.employee_email_internal
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentCoreAccessToken(accessToken)
          fetchTokenId(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchTokenId = (access_token) => {
      try {
        axios.post(`${baseUrlCore}user/getData/json`, {}, {
          headers: {
            appCode: appCodeCore,
            email: userData.employee_email_internal,
            accessToken: access_token
          }
        }).then((response) => {
          const tokenId = response.data.response.tokenId
          setCurrentTokenId(tokenId)
          fetchProcessAccessToken(tokenId)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchProcessAccessToken = (tokenId) => {
      try {
        axios.post(`${baseUrlProcess}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCodeProcess,
            email: userData.employee_email_internal
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentProcessAccessToken(accessToken)
          fetchYears(accessToken, tokenId)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchYears = (accessToken, tokenId) => {
      try {
        axios.get(`${baseUrlProcess}payroll/skp/payrollready/json`, {
          headers: {
            appCode: appCodeProcess,
            email: userData.employee_email_internal,
            accessToken: accessToken,
            tokenId: tokenId
          }
        }).then((response) => {
          const data = response.data.response
          setYears(data.map((year) => {
            return {
              label: year.label,
              value: year.value,
            };
          }),)  
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCoreAccessToken()
  }, []); 


  const submitData = async () => {
    const dataForm = {
      month: selectedMonth.value,
      year: selectedYear.value,
      jumlah: selectedTotalMonth.value
    }

    const response = await axios.post(`${baseUrlProcess}payroll/skp/payrollcreate/json`, dataForm, {
      headers: {
        appCode: appCodeProcess,
        email: userData.employee_email_internal,
        accessToken: currentProcessAccessToken,
        tokenId: currentTokenId
      },
    });

    if (response.data.status == true) {
      setSuccess(true)
    } else {
      setFaield(true)
    }
    setLoading(false)
  }

  const confirmResponse = (response) => {
    switch (response) {
      case "cancel":
        setConfirm(false)
        break;
      case "confirm":
        setLoading(true)
        setConfirm(false)        
        submitData();
        break;
      case "success":
        setSuccess(false)
        setRedirect(true)
        break;
      case "failed":
        setFaield(false)
        break;
      default:
    }
  };

  const showButton = (showNextButton) => {
    return (
      <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", bottom: 0, right: 0, zIndex: 99 }}>
          {currentQuestion > 1 && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              Kembali
            </Button> 
          )}

          {showNextButton == true && currentQuestion != questionTotal && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Selanjutnya
            </Button> 
          )}

          {showNextButton == true && currentQuestion == questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Review
          </Button> 
          )}

          {currentQuestion > questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setConfirm(true)}
          >
            Submit
          </Button> 
          )}
        </div>
    )
  }

  const showLottie = () => {
    return <Lottie options={{ 
      loop: true,
      autoplay: true, 
      animationData: animationData
     }}
      isClickToPauseDisabled={true}
      height="100%"
      width="100%"
      isStopped={false}
      isPaused={false}/>
  }
  
  return (
    <>
      {currentQuestion <= questionTotal && (
        <div className="mb-4" style={{ position: "fixed", top: 0, width: "100%" }}>
          <Progress color="info" value={currentQuestion / questionTotal * 100} />
        </div>
      )}
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {currentQuestion <= questionTotal ? (
          <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99 }}>
            Pertanyaan {currentQuestion} dari {questionTotal}
          </div>
        ) : <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
        <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
      </div>}

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px" }} >
        
        { currentQuestion == 1 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[0]}</p>

                { years.length ? years.map((year, i) => (
                  <Button
                    key={i}
                    color="primary"
                    value={year.value}
                    outline
                    className="waves-effect waves-light"
                    onClick={(event) => {
                      setSelectedYear(year)
                      setCurrentQuestion(currentQuestion + 1)
                    }}
                    style={{
                      margin: "5px", 
                      borderRadius: "20px",
                      borderColor: "#3FC057",
                      background: selectedYear.value == year.value ? "#3FC057" : "rgba(63, 192, 87, 0.05)", 
                      color: selectedYear.value == year.value ? 'white' : '#3FC057'
                    }}
                  >
                    {year.label}
                  </Button>
                )) : "Data tidak tersedia"}
              {showButton(Object.keys(selectedYear).length > 0)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          )
        }

        { currentQuestion == 2 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[1]}</p>
                <Row>
                  {months.map((month, i) => (
                    <Col md={3} style={{ 
                        borderRadius: "8px",
                        background: "#FFF",
                        boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)",
                        margin: "5px",
                        padding: "10px 15px 10px 15px",
                        background: selectedMonth.value == month.value ? "dodgerblue" : "white", 
                        color: selectedMonth.value == month.value ? 'white' : 'dodgerblue',
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setSelectedMonth(month)
                        setCurrentQuestion(currentQuestion + 1)
                      }}
                    >
                      <i className="bx bx-rectangle" style={{ marginRight: "10px" }}></i> {month.label}
                    </Col>
                    
                  ))}
                </Row>
              {showButton(Object.keys(selectedMonth).length > 0)}
            </div> 
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          ) 
        }

        { currentQuestion == 3 && ( 
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[2]}</p>
                <Row>
                  {totalMonths.map((month, i) => (
                    <Col md={3} style={{ 
                        borderRadius: "8px",
                        background: "#FFF",
                        boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)",
                        margin: "5px",
                        padding: "10px 15px 10px 15px",
                        background: selectedTotalMonth.value == month.value ? "dodgerblue" : "white", 
                        color: selectedTotalMonth.value == month.value ? 'white' : 'dodgerblue',
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setSelectedTotalMonth(month)
                        setCurrentQuestion(currentQuestion + 1)
                      }}
                    >
                      <i className="bx bx-rectangle" style={{ marginRight: "10px" }}></i> {month.label}
                    </Col>
                    
                  ))}
                </Row>
              {showButton(Object.keys(selectedTotalMonth).length > 0)}
            </div> 
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
          ) 
        }

        { currentQuestion == questionTotal + 1 && ( 
          <div className="col-md-12" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h5 className="nlp-content">Resume</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "5vw" }}>
              <Row>
                <Col md="auto">
                  <div> 
                    <div className="centered-horizontal" style={{ marginBottom: "10px" }}>
                      <img src={reviewIcon}/>
                    </div>
                  </div>
                </Col>
                <Col md={8} className="centered-vertical">
                  <Col md={2}>
                    <div>
                      <Label className="form-label" htmlFor="formrow-email-input">Tahun</Label>
                      <p>{selectedYear.label}</p>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div>
                      <Label className="form-label" htmlFor="formrow-email-input">Bulan Awal</Label>
                      <p>{selectedMonth.label}</p>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div>
                      <Label className="form-label" htmlFor="formrow-email-input">Jumlah Bulan</Label>
                      <p>{selectedTotalMonth.label}</p>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
            {showButton(false)}
          </div>
         ) }
        </AvForm>
      </div>
      <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={confirm}
          showLoading={loading}
          showSuccess={success}
          showFailed={failed}
          response={confirmResponse}
        />
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default PengajuanSlipGajiView;

import React, { createContext, useEffect, useState } from "react";

export const MeetingContext = createContext();

export const MeetingProvider = ({ children }) => {

  let userRole = parseInt(localStorage.getItem("currentRoleId"));
  let userData = JSON.parse(localStorage.getItem("userdata"));
  // states
  const [source, setSource] = useState();
  const [dataForm, setDataForm] = useState({
    meeting_participants: [],
    meeting_role: userRole,
    meeting_nip: userData.employee_nip,
  });

  return (
    <MeetingContext.Provider
      value={{
        dataForm,
        setDataForm,
        source,
        setSource,
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
};

import React, { useState, useEffect } from "react";
import avatarMale from "../../../../../../assets/images/avatar_male.png";
import avatarFemale from "../../../../../../assets/images/avatar_female.png";
import { Col, Row,  Dropdown, DropdownToggle, DropdownMenu, Modal  } from "reactstrap";
import iconBell from '../../../../../../assets/icons/bell.png'
import Shimmer from "react-shimmer-effect";
import { authContext } from "../../../../../../services/adalConfig";
import { Link } from "react-router-dom";

function ProfileDropdown() {

    const userData = JSON.parse(localStorage.getItem("userdata"));
    const [email, setemail] = useState("");
    const [username, setusername] = useState("");
    const [userphoto, setuserphoto] = useState("");
    const [useravatar, setuseravatar] = useState(avatarMale);
    const [menu, setMenu] = useState(false);
    const [modalChangeRoleOpen, setModalChangeRoleOpen] = useState(false);
    const [selectedAccessRole, setSelectedAccessRole] = useState({
        label: localStorage.getItem("currentRoleName"),
        value: localStorage.getItem("currentRoleId"),
    });
    const listAccessRole = JSON.parse(sessionStorage.getItem("allRole"));
    const itemAccessRole = [];
    
    listAccessRole.map((role) => {
        itemAccessRole.push({ label: role.access_name, value: role.access_id });
        return null;
    });

    const optionAccessRole = [
        {
        label: "Akses Role",
        options: itemAccessRole,
        },
    ];

    function handleSelectAccessRole(selectedAccessRole) {
        setSelectedAccessRole(selectedAccessRole);
    }

    function toggleChangeRole(cmd) {
        if (cmd === "open") {
        setModalChangeRoleOpen(true);
        }
        if (cmd === "close") {
        setModalChangeRoleOpen(false);
        }
    }

    useEffect(() => {
        if (authContext.getCachedUser()) {
            setusername(userData.employee_name);
            setemail(userData.employee_email_internal);
            setuserphoto(
                userData.employee_image_file_baseurl + userData.employee_image_file_path
            );
            if (userData.employee_gender === "P") {
                setuseravatar(avatarFemale);
            }
        }
    }, []);  
    const addDefaultPhoto = (ev) => {
        ev.target.src = useravatar;
    };

    return (
        <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
        >
            <DropdownToggle
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                tag="button"
            >
                {userData.employee_name}
                {userphoto === "" ? (
                    <Shimmer>
                    <div
                        className="rounded-circle header-profile-user"
                        style={{
                        verticalAlign: "middle",
                        marginLeft: "10px"
                        }}
                    />
                    </Shimmer>
                ) : (
                    <img
                    style={{ marginLeft: "10px", width: "32px", height: "32px" }}
                    onError={addDefaultPhoto}
                    className="rounded-circle header-profile-user"
                    src={userphoto}
                    alt=""
                    />
                )}
            </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <div className="dropdown-item" style={{ pointerEvents: "none" }}>
                        <i className="dripicons-scale font-size-18 align-middle me-1 text-muted"></i>
                        {localStorage.getItem("currentRoleName")}
                    </div>
                    <div className="dropdown-divider" />
                    <Link
                        to="service_profile.html"
                        className="dropdown-item"
                    >
                        <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
                        {email}
                    </Link>
                    {listAccessRole.length < -1 ? (
                        <div
                        onClick={() => {
                            toggleChangeRole("open");
                        }}
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        >
                        <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
                        Ganti Hak Akses
                        </div>
                    ) : null}
                    <div className="dropdown-divider" />
                    <Link to="/logout.html" className="dropdown-item">
                        <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
                        Keluar
                    </Link>
                </DropdownMenu>
        </Dropdown>
    )
}

export default ProfileDropdown;
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import avatarMale from "../../../../assets/images/avatar_male.png";
import avatarFemale from "../../../../assets/images/avatar_female.png";
import { authContext } from "../../../../services/adalConfig";
import imglyRemoveBackground, {Config} from "@imgly/background-removal"
import "./style.scss";
import { Row, Col} from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function DataDiklat(props) {

  const baseUrl = process.env.REACT_APP_SIMPEG_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_SIMPEG_SERVICE_BASE_APPCODE;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentIndex, setCurrentIndex] = useState({
    "Fungsional": 0,
    "Struktural": 0,
    "Teknis": 0
  });
  const [strukturalList, setStrukturalList] = useState([])
  const [fungsionalList, setFungsionalList] = useState([])
  const [teknisList, setTeknisList] = useState([])

  const updateCurrentIndex = (key, value) => {
    // Use the spread operator to create a new state object with the updated key-value pair
    setCurrentIndex((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(async () => {
      const fetchAccessToken = () => {
        try {
          axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
            }
          }).then((response) => {
            const accessToken = response.data.response.access_token
            fetchStrukturalDiklat(accessToken)
            fetchFungsionalDiklat(accessToken)
            fetchTeknisDiklat(accessToken)
          })
          .catch((error) => {
            console.log(error)
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const fetchStrukturalDiklat = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}/Kepegawaian/Diklat/list/json?employee_uuid=${userData.employee_uuid}&training_type=Struktural&search=&limit=-1&offset=0&order=training_id&sort=desc&status=1`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setStrukturalList(result)
          if (result.length > 0) {
            updateCurrentIndex("Struktural", 1)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchFungsionalDiklat = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}/Kepegawaian/Diklat/list/json?employee_uuid=${userData.employee_uuid}&training_type=Fungsional&search=&limit=-1&offset=0&order=training_id&sort=desc&status=1`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setFungsionalList(response.data.response.result)
          if (result.length > 0) {
            updateCurrentIndex("Fungsional", 1)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchTeknisDiklat = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}/Kepegawaian/Diklat/list/json?employee_uuid=${userData.employee_uuid}&training_type=Teknis&search=&limit=-1&offset=0&order=training_id&sort=desc&status=1`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setTeknisList(response.data.response.result)
          if (result.length > 0) {
            updateCurrentIndex("Teknis", 1)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchAccessToken()
  }, []);  

  const increaseIndex = (type) => {
    updateCurrentIndex(type, currentIndex[type] + 1);
  }
  
  const decreaseIndex = (type) => {
    updateCurrentIndex(type, currentIndex[type] - 1);
  }

  const generateBox = (title, list) => (
    <Col md={12}>
      <div style={{borderRadius: "9px", border: "1px solid aqua", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)"}}>
        <div style={{padding: "30px"}}>
          <h4>{title}</h4>
          <Row>
            <Col md={12}>
              {list.map((rank, index) => 
              currentIndex[title] == index + 1  && (
                <Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={4}>
                      <label className="title">Nama Diklat</label>
                    </Col>
                    <Col md={8}>
                      <label className="value">{rank.training_type_name ?? "-"}</label>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={4}>
                      <label className="title">Lokasi</label>
                    </Col>
                    <Col md={8}>
                      <label className="value">{rank.training_place ?? "-"}</label>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={4}>
                      <label className="title">Tanggal Mulai</label>
                    </Col>
                    <Col md={8}>
                      <label className="value">{moment(rank.training_start_date).format('DD-MM-YYYY') ?? "-"}</label>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={4}>
                      <label className="title">Tanggal Selesai</label>
                    </Col>
                    <Col md={8}>
                      <label className="value">{moment(rank.training_end_date).format('DD-MM-YYYY') ?? "-"}</label>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={4}>
                      <label className="title">Penyelenggara</label>
                    </Col>
                    <Col md={8}>
                      <label className="value">{rank.training_organizer ?? "-"}</label>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={4}>
                      <label className="title">Jumlah Jam</label>
                    </Col>
                    <Col md={8}>
                      <label className="value">{rank.training_number_of_hours ?? "-"}</label>
                    </Col>
                  </Row>
                </Row>
              )
              )}
            </Col>
          </Row>
        </div>
        <hr/>
        <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "30px", paddingRight: "30px"}}>
            <i className="uil-arrow-left" style={{ fontSize: currentIndex[title] == 1 || currentIndex[title] == 0 ? "0px" : "20px" }} onClick={() => decreaseIndex(title)}/>
            <p>{currentIndex[title]} of {list.length}</p>
            <i className="uil-arrow-right" style={{  fontSize: currentIndex[title] == list.length ? "0px" : "20px"}} onClick={() => increaseIndex(title)}/>
        </div>
      </div>
    </Col>
  )

  return (
    <>
      <div
        className="main"
        style={{
          position: "relative",
          minHeight: "100vh",
          overflow: "hidden"
        }}
      >
        <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
          <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
        </div>

        <Profile/>

        <div style={{ margin: "13vh 18vw 13vh 18vw" }}>
          <p style={{ color: "#575757", fontFamily: "IBM Plex Sans", fontSize: "24px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal" }}>Data Diklat</p>
          <Row>
            {generateBox("Struktural", strukturalList)}
          </Row>
          <Row style={{ marginTop: "10px" }}>
            {generateBox("Fungsional", fungsionalList)}            
          </Row>
          <Row style={{ marginTop: "10px" }}>
            {generateBox("Teknis", teknisList)}        
          </Row>
        </div>
      </div>
    </>
  );
}

export default DataDiklat;

import axios from "axios";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import { Progress, Button, Row, Col, Label, Input, Tooltip} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  AvForm,
  AvField,
} from "availity-reactstrap-validation";
import animationData from "../../../../assets/lotties/nlp.json"
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { Link, Redirect } from "react-router-dom";
import reviewIcon from '../../../../assets/icons/icon_review_summary.png';
import Lottie from "react-lottie";
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>



function PresensiPegawaiView(props) {

  const baseUrl = process.env.REACT_APP_SIMPEG_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_SIMPEG_SERVICE_BASE_APPCODE;
  const title = "Presensi Pegawai";
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [currentAccessToken, setCurrentAccessToken] = useState()
  const questions = ["Silakan pilih periode presensi"];
  const questionTotal = questions.length;
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [presensiDetail, setPresensiDetail] = useState();
  const [showPreview, setShowPreview] = useState(false)
  const [presensiList, setPresensiList] = useState([])
  const container = useRef(null);

  const [selectedStartDate, setSelectedStartDate] = useState()
  const [selectedEndDate, setSelectedEndDate] = useState()

  const [confirm, setConfirm] = useState()
  const [loading, setLoading] = useState()
  const [success, setSuccess] = useState()
  const [failed, setFaield] = useState()
  const [redirect, setRedirect] = useState()

  useEffect(() => {
    const fetchAccessToken = () => {
      try {
        axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
          headers: {
            appCode: appCode,
            email: userData.employee_email
          }
        }).then((response) => {
          const accessToken = response.data.response.access_token
          setCurrentAccessToken(accessToken)
          fetchPresensiDetail(accessToken)
        })
        .catch((error) => {
          console.log(error)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchPresensiDetail = async (accessToken) => {
      try {
        const response = await axios.get(`${baseUrl}Kepegawaian/Presensi/detail/json?uuid=${userData.employee_uuid}`, {
          headers: {
            appCode: appCode,
            email: userData.employee_email,
            accessToken: accessToken
          },
        });
        setPresensiDetail(response?.data?.response)  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAccessToken()
  }, []); 

  const submitData = async () => {
    const response = await axios.get(`${baseUrl}Kepegawaian/Presensi/simulation/json?uuid=${userData.employee_uuid}&start_date=${selectedStartDate}&end_date=${selectedEndDate}`, {
      headers: {
        appCode: appCode,
        email: userData.employee_email,
        accessToken: currentAccessToken
      },
    });

    if (response.data.status == true) {
      setPresensiList(response.data.response.result)
      setShowPreview(true)
    } else {
      setFaield(true)
    }
    setLoading(false)
  }

  const confirmResponse = (response) => {
    switch (response) {
      case "cancel":
        setConfirm(false)
        break;
      case "confirm":
        setLoading(true)
        setConfirm(false)        
        submitData();
        break;
      case "success":
        setSuccess(false)
        setRedirect(true)
        break;
      case "failed":
        setFaield(false)
        break;
      default:
    }
  };
  
  const dateParse = (input) =>{
    let res = "-";
    if (input != "" && input != null){
        res = moment(input).format('DD/MM/YYYY')
    }
    return res;
  }

  const getIconPresensi = (inp, val) => {
    if (inp != null) {
        if (val == 'Finger Print') {
            return (
              <>
                <br /> <i className="bx bx-fingerprint"></i>
              </>
            )
        }

        if (val == 'Mobile') {
            return (
              <>
                <br /> <i className="bx bx-mobile-alt"></i>
              </>
            )
        }
    }
    return '';
  }

  const simulationHonorerComponent = () => {
    return <div className="table-responsive" style={{minWidth:'100%'}}>
      <Table className="table table-striped table-bordered table-hover">
        <Thead>
          <Tr>
            <Th className="text-center" width="*">Tanggal</Th>
            <Th className="text-left" width="*">Hari</Th>
            <Th className="text-center" width="*">Masuk</Th>
            <Th className="text-center" width="*">Pulang</Th>
            <Th className="text-center" width="*">Hadir</Th>
            <Th className="text-center" width="*">S.Finger</Th>
            <Th className="text-center" width="*">Terlambat</Th>
            <Th className="text-center" width="*">Pulang Cepat</Th>
            <Th className="text-center" width="*">Keterangan</Th>
            <Th className="text-center" width="*">Pola Kerja</Th>
          </Tr>
        </Thead>
      <Tbody>
      {
        Object.entries(presensiList).map((item, key) => {
          const thisId = item[0];
          let cancelAvailable = false
          if (item[1].result_checkin !== null && item[1].result_checkout !== null) {
              cancelAvailable = true
          }
          let color = "";
          if (item[1].presensi_result_type == 1 || item[1].presensi_result_type == 10) color = "text-danger";
          if (item[1].presensi_result_type > 1 && item[1].presensi_result_type != 10) color = "text-primary";
                     
          return (
            <tr key={key}>
              <td className={`text-center ${color}`}>{  dateParse(item[1].presensi_result_date) }</td>
              <td className={`text-left ${color}`}>{ item[1].presensi_result_day_name }</td>
              { 
                item[1].presensi_result_type != null && item[1].presensi_result_is_present == 0 ?
                <>
                  <td className="text-center">{ item[1].presensi_result_checkin } {getIconPresensi(item[1].presensi_result_checkin, item[1].presensi_result_checkin_from)}</td>
                  <td className="text-center">{ item[1].presensi_result_checkout } {getIconPresensi(item[1].presensi_result_checkout, item[1].presensi_result_checkout_from)}</td>
                  <td className="text-center">{ item[1].presensi_result_is_present === 0 ? '-' : 'Ya' }</td>
                  <td className={`text-center ${color}`} colSpan="4">{ item[1].presensi_result_type_name }</td>
                </>
                :
                <>
                  <td className="text-center">{ item[1].presensi_result_checkin } {getIconPresensi(item[1].presensi_result_checkin, item[1].presensi_result_checkin_from)}</td>
                  <td className="text-center">{ item[1].presensi_result_checkout } {getIconPresensi(item[1].presensi_result_checkout, item[1].presensi_result_checkout_from)}</td>
                  <td className="text-center">{ item[1].presensi_result_is_present === 0 ? '-' : 'Ya' }</td>
                  <td className="text-center">{ item[1].presensi_result_is_single_finger === 0 ? '-' : '03:45:00' }</td>
                  <td className="text-center">{ item[1].presensi_result_late === null ? '-' : item[1].presensi_result_late }</td>
                  <td className="text-center">{ item[1].presensi_result_early === null ? '-' : item[1].presensi_result_early }</td>
                  <td className="text-left">{ item[1].presensi_type == -1 ? item[1].presensi_type_name : item[1].presensi_result_type_name }</td>
                  <td className="text-left">{ item[1].presensi_result_working_pattern }</td>
                </>
              }
            </tr>
          )
        })
      }
    </Tbody>
  </Table>
  </div>
  }

  const simulationPTSPComponent = () => {
    return <div className="table-responsive" style={{minWidth:'100%'}}>
      <Table className="table table-striped table-bordered table-hover">
        <Thead>
          <Tr>
            <Th className="text-center" width="*">Tanggal</Th>
            <Th className="text-left" width="*">Hari</Th>
            <Th className="text-center" width="*">Masuk</Th>
            <Th className="text-center" width="*">Pulang</Th>      
            <Th className="text-center" width="*">Hadir</Th>
            <Th className="text-center" width="*">H</Th>
            <Th className="text-center" width="*">S.Finger</Th>
            <Th className="text-center" width="*">H</Th>
            <Th className="text-center" width="*">Terlambat</Th>
            <Th className="text-center" width="*">H</Th>
            <Th className="text-center" width="*">Pulang Cepat</Th>
            <Th className="text-center" width="*">H</Th>
            <Th className="text-center" width="*">Keterangan</Th>
            <Th className="text-center" width="*">Pola Kerja</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            Object.entries(presensiList).map((item, key) => {
              const thisId = item[0];
              let cancelAvailable = false
              if (item[1].result_checkin !== null && item[1].result_checkout !== null) {
                  cancelAvailable = true
              }
              console.log(item)
              let color = "";
              if (item[1].presensi_result_type == 1 || item[1].presensi_result_type == 10) color = "text-danger";
              if (item[1].presensi_result_type > 1 && item[1].presensi_result_type != 10) color = "text-primary";         
                return (
                  <tr key={key}>
                    <td className={`text-center ${color}`}>{  this.dateParse(item[1].presensi_result_date) }</td>
                    <td className={`text-left ${color}`}>{ item[1].presensi_result_day_name }</td>
                    { 
                      item[1].presensi_result_type != null && item[1].presensi_result_is_present == 0 ?
                      <>
                          <td className="text-center">{ item[1].presensi_result_checkin } {this.getIconPresensi(item[1].presensi_result_checkin, item[1].presensi_result_checkin_from)}</td>
                          <td className="text-center">{ item[1].presensi_result_checkout } {this.getIconPresensi(item[1].presensi_result_checkout, item[1].presensi_result_checkout_from)}</td>
                          <td className="text-center">{ item[1].presensi_result_is_present === 0 ? '-' : 'Ya' }</td>
                          <td className="text-center">{ item[1].presensi_result_precent_ptsp_not_present}</td>
                          <td className={`text-center ${color}`} colSpan="7">{ item[1].presensi_result_type_name }</td>
                      </>
                      :
                      <>
                        <td className="text-center">{ item[1].presensi_result_checkin } {this.getIconPresensi(item[1].presensi_result_checkin, item[1].presensi_result_checkin_from)}</td>
                        <td className="text-center">{ item[1].presensi_result_checkout } {this.getIconPresensi(item[1].presensi_result_checkout, item[1].presensi_result_checkout_from)}</td>
                        <td className="text-center">{ item[1].presensi_result_is_present === 0 ? '-' : 'Ya' }</td>
                        <td className="text-center">{ item[1].presensi_result_precent_ptsp_not_present}</td>
                        <td className="text-center">{ item[1].presensi_result_is_single_finger === 0 ? '-' : '03:45:00' }</td>
                        <td className="text-center">{ item[1].presensi_result_precent_ptsp_single_finger}</td>
                        <td className="text-center">{ item[1].presensi_result_late === null ? '-' : item[1].presensi_result_late }</td>
                        <td className="text-center">{ item[1].presensi_result_precent_ptsp_late}</td>
                        <td className="text-center">{ item[1].presensi_result_early === null ? '-' : item[1].presensi_result_early }</td>
                        <td className="text-center">{ item[1].presensi_result_precent_ptsp_early}</td>
                        <td className="text-left">{ item[1].presensi_type == -1 ? item[1].presensi_type_name : item[1].presensi_result_type_name }</td>
                        <td className="text-left">{ item[1].presensi_result_working_pattern }</td>            
                      </>
                    }
                  </tr>
                )
              })
            }
          </Tbody>
        </Table>
    </div>
  }

  const simulationComponent = () => {
    return <div className="table-responsive" style={{minWidth:'100%'}}>
    <Table className="table table-striped table-bordered table-hover">
      <Thead>
        <Tr>
        <Th className="text-center" width="*">Tanggal</Th>
          <Th className="text-left" width="*">Hari</Th>
          <Th className="text-center" width="*">Masuk</Th>
          <Th className="text-center" width="*">Pulang</Th>
          <Th className="text-center" width="*">Hadir</Th>
          <Th className="text-center" width="*">T</Th>
          <Th className="text-center" width="*">H</Th>
          <Th className="text-center" width="*">S.Finger</Th>
          <Th className="text-center" width="*">T</Th>
          <Th className="text-center" width="*">H</Th>
          <Th className="text-center" width="*">Terlambat</Th>
          <Th className="text-center" width="*">T</Th>
          <Th className="text-center" width="*">H</Th>
          <Th className="text-center" width="*">Pulang Cepat</Th>
          <Th className="text-center" width="*">T</Th>
          <Th className="text-center" width="*">H</Th>
          <Th className="text-center" width="*">Keterangan</Th>
          <Th className="text-center" width="300px">Pola Kerja</Th>
        </Tr>
      </Thead>
      <Tbody>
      {
        Object.entries(presensiList).map((item, key) => {
          const thisId = item[0];
          let cancelAvailable = false
          if (item[1].result_checkin !== null && item[1].result_checkout !== null) {
              cancelAvailable = true
          }
          console.log(item)
          let color = "";
          if (item[1].presensi_result_type == 1 || item[1].presensi_result_type == 10) color = "text-danger";
          if (item[1].presensi_result_type > 1 && item[1].presensi_result_type != 10) color = "text-primary";
          
          return (
            <tr key={key}>
              <td className={`text-center ${color}`}>{  dateParse(item[1].presensi_result_date) }</td>
              <td className={`text-left ${color}`}>{ item[1].presensi_result_day_name }</td>
                { 
                  item[1].presensi_result_type != null && item[1].presensi_result_is_present == 0 ?
                  <>
                    <td className="text-center">{ item[1].presensi_result_checkin } {getIconPresensi(item[1].presensi_result_checkin, item[1].presensi_result_checkin_from)}</td>
                    <td className="text-center">{ item[1].presensi_result_checkout } {getIconPresensi(item[1].presensi_result_checkout, item[1].presensi_result_checkout_from)}</td>
                    <td className="text-center">{ item[1].presensi_result_is_present === 0 ? '-' : 'Ya' }</td>
                    <td className="text-center">{ item[1].presensi_result_precent_tunkin_not_present}</td>
                    <td className="text-center">{ item[1].presensi_result_precent_ptsp_not_present}</td>
                    <td className={`text-center ${color}`} colSpan="10">{ item[1].presensi_result_type_name }</td>
                  </>
                  :
                  <>
                    <td className="text-center">{ item[1].presensi_result_checkin } {getIconPresensi(item[1].presensi_result_checkin, item[1].presensi_result_checkin_from)}</td>
                    <td className="text-center">{ item[1].presensi_result_checkout } {getIconPresensi(item[1].presensi_result_checkout, item[1].presensi_result_checkout_from)}</td>
                    <td className="text-center">{ item[1].presensi_result_is_present === 0 ? '-' : 'Ya' }</td>
                    <td className="text-center">{ item[1].presensi_result_precent_tunkin_not_present}</td>
                    <td className="text-center">{ item[1].presensi_result_precent_ptsp_not_present}</td>
                    <td className="text-center">{ item[1].presensi_result_is_single_finger === 0 ? '-' : '03:45:00' }</td>
                    <td className="text-center">{ item[1].presensi_result_precent_tunkin_single_finger}</td>
                    <td className="text-center">{ item[1].presensi_result_precent_ptsp_single_finger}</td>
                    <td className="text-center">{ item[1].presensi_result_late === null ? '-' : item[1].presensi_result_late }</td>
                    <td className="text-center">{ item[1].presensi_result_precent_tunkin_late}</td>
                    <td className="text-center">{ item[1].presensi_result_precent_ptsp_late}</td>
                    <td className="text-center">{ item[1].presensi_result_early === null ? '-' : item[1].presensi_result_early }</td>
                    <td className="text-center">{ item[1].presensi_result_precent_tunkin_early}</td>
                    <td className="text-center">{ item[1].presensi_result_precent_ptsp_early}</td>
                    <td className="text-left">{ item[1].presensi_type == -1 ? item[1].presensi_type_name : item[1].presensi_result_type_name }</td>
                    <td className="text-left">{ item[1].presensi_result_working_pattern }</td>
                  </>
                }
              </tr>
            )
          })
        }
      </Tbody>
    </Table>
  </div>
  }

  const showButton = (showNextButton) => {
    return (
      <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", bottom: 0, right: 0, zIndex: 99 }}>
          {currentQuestion > 1 && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              Kembali
            </Button> 
          )}

          {showNextButton == true && currentQuestion != questionTotal && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              style={{margin: "5px"}}
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              Selanjutnya
            </Button> 
          )}

          {showNextButton == true && currentQuestion == questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Review
          </Button> 
          )}

          {currentQuestion > questionTotal && (
            <Button
            color="primary"
            outline
            className="waves-effect waves-light"
            style={{margin: "5px"}}
            onClick={() => setConfirm(true)}
          >
            Submit
          </Button> 
          )}
        </div>
    )
  }

  const showLottie = () => {
    return <Lottie options={{ 
      loop: true,
      autoplay: true, 
      animationData: animationData
     }}
      isClickToPauseDisabled={true}
      height="100%"
      width="100%"
      isStopped={false}
      isPaused={false}/>
  }

  return (
    <>
      {currentQuestion <= questionTotal && (
        <div className="mb-4" style={{ position: "fixed", top: 0, width: "100%" }}>
          <Progress color="info" value={currentQuestion / questionTotal * 100} />
        </div>
      )}
      <div
        className="main centered-vertical"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {currentQuestion <= questionTotal ? (
          <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99 }}>
            Pertanyaan {currentQuestion} dari {questionTotal}
          </div>
        ) : <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
        <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
      </div>}

        <Profile/>

        <AvForm className="col-md-12" style={{ paddingLeft: "50px", paddingRight: "50px" }} >

        { currentQuestion == 1 && (
          <Row className="centered-vertical">
            <div className="col-md-6" style={{ marginTop: "100px", marginBottom: "100px" }}>
              <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
              <p className="nlp-content">{questions[2]}</p>
              <div>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="start-date-input">Periode Mulai</Label>
                    <br/>
                    <input
                      className="form-control"
                      type="date"
                      defaultValue={selectedStartDate}
                      onChange={(e) => {
                        setSelectedStartDate(e.target.value);
                        if (selectedEndDate != null) {
                          setCurrentQuestion(currentQuestion + 1)
                        }
                      }}
                      id="start-date-input"
                      name="start-date-input"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label className="form-label nlp-sub-content" htmlFor="end-date-input">Periode Selesai</Label>
                    <br/>
                    <input
                      className="form-control"
                      type="date"
                      defaultValue={selectedEndDate}
                      onChange={(e) => {
                        setSelectedEndDate(e.target.value);
                        if (selectedStartDate != null) {
                          setCurrentQuestion(currentQuestion + 1)
                        }
                      }}
                      id="end-date-input"
                      name="end-date-input"
                    />
                  </div>
                </Col>
              </Row>
              </div>
              {showButton(selectedStartDate != null && selectedEndDate != null)}
            </div>
            <div className="col-md-6">
              {showLottie()}
            </div>
          </Row>
        )}

        { currentQuestion == questionTotal + 1 && showPreview == false && ( 
          <div className="col-md-12" style={{ marginTop: "100px", marginBottom: "100px" }}>
            <h5 className="nlp-content">Resume</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "5vw" }}>
              <Row>
                <Col md="auto" style={{ marginRight: "50px" }}>
                  <div> 
                    <div className="centered-horizontal" style={{ marginBottom: "10px" }}>
                      <img src={reviewIcon}/>
                    </div>
                  </div>
                </Col>
                <Col md ={8} className="centered-vertical">
                  <Col>
                    <div>
                      <Label className="form-label nlp-sub-content" htmlFor="formrow-email-input">Periode Presensi</Label>
                      <p className="nlp-sub-content">{`${moment(selectedStartDate).format('LL')} s/d ${moment(selectedEndDate).format('LL')}`}</p>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
            {showButton(false)}
          </div>
         ) }

        { currentQuestion == questionTotal + 1 && showPreview == true && ( 
          <div className="col-md-12" style={{ marginTop: 100, marginBottom: 100 }}>
            <h5 className="nlp-content">Result</h5>
            <h2 className="nlp-title" style={{ marginBottom: "30px" }}>{title}</h2>
          
            <div style={{ borderRadius: "9px", border: "1px solid #FFF", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", padding: "40px" }}>
            <div className="p-2 bd-highlight float-end">
              <div className="nlp-sub-content" style={{paddingTop: 8}}>
                      <i className="bx bx-mobile-alt"></i> Absensi Mobile
                  </div>
              </div>
              <div className="p-2 bd-highlight float-end">
                  <div className="nlp-sub-content" style={{paddingTop: 8}}>
                      <i className="bx bx-fingerprint"></i> Absensi Mesin
                  </div>
              </div>
              { presensiDetail.employee_status_name.includes('Honorer') ? simulationHonorerComponent() : presensiDetail.employee_current_work_unit_name.includes('PTSP') ? simulationPTSPComponent() : simulationComponent()}
            </div>
          </div>
         ) }
        </AvForm>
      </div>
      <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan mengambil data ini?"
          loadingTitle="Mengambil data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal diambil"
          showConfirm={confirm}
          showLoading={loading}
          showSuccess={success}
          showFailed={failed}
          response={confirmResponse}
        />
        {redirect ? <Redirect to="/home_new.html" /> : null}
    </>
  );
}

export default PresensiPegawaiView;

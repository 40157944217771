import { accessRole } from "../../helpers/access_role";
import ErrorAccess from "../../components/Error/Content/Access";
import { AgendaProvider } from "./Context/AgendaContext";

import IndexView from "./Index/View";
import CreateView from "./Create/View";
import DetailView from "./Detail/View";
import UpdateView from "./Update/View";
import NotulenView from "./Notulen/View";

const RouteView = (props) => {
  const queryParams = new URLSearchParams(props.location.search);
  const _create = queryParams.get("create");
  const _detail = queryParams.get("detail");
  const _update = queryParams.get("update");
  const _notulen = queryParams.get("notulen");
  const _delete = queryParams.get("detail");
  const _approve = queryParams.get("approve");
  const _print = queryParams.get("print");

  const renderWithProvider = (element) => {
    return <AgendaProvider>{element}</AgendaProvider>;
  };

  if (_create != null) {
    return accessRole("create") ? (
      renderWithProvider(<CreateView />)
    ) : (
      <ErrorAccess />
    );
  } else if (_detail != null) {
    return accessRole("create") ? (
      renderWithProvider(<DetailView />)
    ) : (
      <ErrorAccess />
    );
  } else if (_update != null) {
    return accessRole("create") ? (
      renderWithProvider(<UpdateView />)
    ) : (
      <ErrorAccess />
    );
  } else if (_notulen != null) {
    // return <NotulenView />;
    return accessRole("update") ? (
      renderWithProvider(<NotulenView />)
    ) : (
      <ErrorAccess />
    );
  } else if (_delete != null) {
    return null;
  } else if (_approve != null) {
    return null;
  } else if (_print != null) {
    return null;
  }
  // return <IndexView />;
  return accessRole("read") ? (
    renderWithProvider(<IndexView />)
  ) : (
    <ErrorAccess />
  );
};
export default RouteView;

import { accessRole } from "../../helpers/access_role";
import { GlobalProvider } from "../../contexts/GlobalContext";

import IndexView from "./Index/View";
import ErrorAccess from "../../components/Error/Access";

import AgendaView from "./Agenda/View";
import AnnouncementView from "./Announcement/View";
import ProfileView from "./Profile/View";
import SearchView from "./Index/Components/Search/Search";

const renderWithProvider = (element) => {
  return <GlobalProvider>{element}</GlobalProvider>;
};

const RouteView = (props) => {
  const queryParams = new URLSearchParams(props.location.search);
  const _agenda = queryParams.get("agenda");
  const _announcement = queryParams.get("announcement");
  const _search = queryParams.get("search");
  const _profile = queryParams.get("profile");

  if (_agenda != null) {
    return accessRole("read") ? (
      renderWithProvider(<AgendaView />)
    ) : (
      <ErrorAccess />
    );
  } else if (_announcement) {
    return accessRole("read") ? (
      renderWithProvider(<AnnouncementView />)
    ) : (
      <ErrorAccess />
    );
  } else if (_profile) {
    return accessRole("read") ? (
      renderWithProvider(<ProfileView />)
    ) : (
      <ErrorAccess />
    );
  }  else if (_search) {
    return accessRole("read") ? (
      renderWithProvider(<SearchView />)
    ) : (
      <ErrorAccess />
    );
  }

  return accessRole("read") ? (
    renderWithProvider(<IndexView />)
  ) : (
    <ErrorAccess />
  );
};

export default RouteView;

import axios from "axios";
import React, { useState, useEffect } from "react";
import Profile from "../../Index/Components/Profile/Profile";
import avatarMale from "../../../../assets/images/avatar_male.png";
import avatarFemale from "../../../../assets/images/avatar_female.png";
import { authContext } from "../../../../services/adalConfig";
import imglyRemoveBackground, {Config} from "@imgly/background-removal"
import "./style.scss";
import { Row, Col} from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function ProfileView(props) {

  const baseUrl = process.env.REACT_APP_SIMPEG_SERVICE_BASE_URL;
  const appCode = process.env.REACT_APP_SIMPEG_SERVICE_BASE_APPCODE;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [userphoto, setuserphoto] = useState("");
  const [useravatar, setuseravatar] = useState(avatarMale);
  const [currentAccessToken, setCurrentAccessToken] = useState()
  const [currentIndex, setCurrentIndex] = useState(0)
  
  //profile
  const [employeeData, setEmployeeData] = useState({})

  //riwayat pendidikan
  const RIWAYAT_PENDIDIKAN = "riwayat_pendidikan"
  const [educationList, setEducationList] = useState([])

  //riwayat jabatan
  const RIWAYAT_JABATAN = "riwayat_jabatan"
  const [positionList, setPositionList] = useState([])

  //riwayat pangkat 
  const RIWAYAT_PANGKAT = "riwayat_pangkat"
  const [rankList, setRankList] = useState([])

  //hukuman disiplin
  const HUKUMAN_DISIPLIN = "hukuman_disiplin"
  const [hukumanDisiplinList, setHukumanDisiplinList] = useState([])

  //diklat
  const DIKLAT_STRUKTURAL = "Diklat Struktural"
  const DIKLAT_FUNGSIONAL = "Diklat Fungsional"
  const DIKLAT_TEKNIS = "Diklat Teknis"
  const [strukturalList, setStrukturalList] = useState([])
  const [fungsionalList, setFungsionalList] = useState([])
  const [teknisList, setTeknisList] = useState([])

  const updateCurrentIndex = (key, value) => {
    setCurrentIndex((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
      loadImageDataFromURL()

      if (userData.employee_gender === "P") {
        setuseravatar(avatarFemale);
      }

      const initIndex = () => {
        updateCurrentIndex(RIWAYAT_PENDIDIKAN, 0)
        updateCurrentIndex(RIWAYAT_JABATAN, 0)
        updateCurrentIndex(RIWAYAT_PANGKAT, 0)
        updateCurrentIndex(HUKUMAN_DISIPLIN, 0)
        updateCurrentIndex(DIKLAT_STRUKTURAL, 0)
        updateCurrentIndex(DIKLAT_FUNGSIONAL, 0)
        updateCurrentIndex(DIKLAT_TEKNIS, 0)
      }
    
      const fetchAccessToken = () => {
        try {
          axios.post(`${baseUrl}auth/getAccessToken/json`, {}, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
            }
          }).then((response) => {
            const accessToken = response.data.response.access_token
            setCurrentAccessToken(accessToken)
            fetchEmployeeData(accessToken)
            fetchEducationList(accessToken)
            fetchPositionList(accessToken)
            fetchRankList(accessToken)
            fetchHukumanDisiplin(accessToken)
            fetchStrukturalDiklat(accessToken)
            fetchFungsionalDiklat(accessToken)
            fetchTeknisDiklat(accessToken)
          })
          .catch((error) => {
            console.log(error)
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const fetchEmployeeData = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}Kepegawaian/Identitas/detail/json?uuid=${userData.employee_uuid}`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          setEmployeeData(response.data.response)
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchEducationList = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}Kepegawaian/RiwayatPendidikan/list/json?search=&limit=-1&offset=0&order=education_background_graduation_year&sort=desc&status=1&uuid=${userData.employee_uuid}`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setEducationList(result)
          if (result.length > 0) {
            updateCurrentIndex(RIWAYAT_PENDIDIKAN, 1)
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchPositionList = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}Kepegawaian/RiwayatJabatan/list/json?search=&limit=-1&offset=0&order=position_history_decree_date&sort=desc&status=1&pegawai_uuid=${userData.employee_uuid}`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setPositionList(result)
          if (result.length > 0) {
            updateCurrentIndex(RIWAYAT_JABATAN, 1)
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchRankList = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}Kepegawaian/RiwayatPangkat/list/json?search=&limit=-1&offset=0&order=rank_history_tmt_date&sort=desc&status=1&uuid=${userData.employee_uuid}`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setRankList(result)
          if (result.length > 0) {
            updateCurrentIndex(RIWAYAT_PANGKAT, 1)
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchHukumanDisiplin = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}Kepegawaian/HukumanDisiplin/listHistory/json?search=&limit=-1&offset=0&order=punishment_discipline_id&sort=desc&status=1&uuid=${userData.employee_uuid}`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setHukumanDisiplinList(result)
          if (result.length > 0) {
            updateCurrentIndex(HUKUMAN_DISIPLIN, 1)
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchStrukturalDiklat = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}/Kepegawaian/Diklat/list/json?employee_uuid=${userData.employee_uuid}&training_type=Struktural&search=&limit=-1&offset=0&order=training_id&sort=desc&status=1`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setStrukturalList(result)
          if (result.length > 0) {
            updateCurrentIndex(DIKLAT_STRUKTURAL, 1)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchFungsionalDiklat = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}/Kepegawaian/Diklat/list/json?employee_uuid=${userData.employee_uuid}&training_type=Fungsional&search=&limit=-1&offset=0&order=training_id&sort=desc&status=1`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setFungsionalList(response.data.response.result)
          if (result.length > 0) {
            updateCurrentIndex(DIKLAT_FUNGSIONAL, 1)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchTeknisDiklat = async (accessToken) => {
        try {
          const response = await axios.get(`${baseUrl}/Kepegawaian/Diklat/list/json?employee_uuid=${userData.employee_uuid}&training_type=Teknis&search=&limit=-1&offset=0&order=training_id&sort=desc&status=1`, {
            headers: {
              appCode: appCode,
              email: userData.employee_email,
              accessToken: accessToken
            }
          });
          var result = response.data.response.result
          setTeknisList(response.data.response.result)
          if (result.length > 0) {
            updateCurrentIndex(DIKLAT_TEKNIS, 1)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      initIndex()
      fetchAccessToken()
  }, []);  

  const addDefaultPhoto = (ev) => {
    ev.target.src = useravatar;
  };

  async function loadImageDataFromURL() {
      let config = {
        model: 'small'
      };
      try{
        setuserphoto(localStorage.getItem("photo_without_background"))
      } catch(e) {
        setuserphoto(
          userData.employee_image_file_baseurl + userData.employee_image_file_path
        );
      }

  }

  const increaseIndex = (type) => {
    updateCurrentIndex(type, currentIndex[type] + 1);
  }
  
  const decreaseIndex = (type) => {
    updateCurrentIndex(type, currentIndex[type] - 1);
  }

  const renderTitle = (title) => {
    return <p style={{ color: "#575757", fontFamily: "IBM Plex Sans", fontSize: "20px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal" }}>{title}</p>
  }

  const renderProfile = () => {
    return (
      <>
        <div style={{borderRadius: "9px", padding: "30px", border: "1px solid aqua", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)",}}>
          <Row>
            <Row className="centered-horizontal">
              <Col md={6} style={{ margin: 0 }}>
                <div className="circular-image">
                  <img
                      
                      onError={addDefaultPhoto}
                      src={userphoto}
                      alt=""
                      />
                </div>
              </Col>
              <Row className="centered-horizontal" style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px"}}>
                  <h2 style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{employeeData.employee_full_name}</h2>
                  <h2 style={{ width: "auto" }}><div style={{ 
                    display: "inline-flex",
                    padding: "10px",
                    width: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "6px",
                    fontSize: "12px",
                    color: "#3E70D1",
                    background: "rgba(62, 112, 209, 0.10)",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }} >
                    {employeeData.employee_current_position_name}
                  </div></h2>
              </Row>
            </Row>
            <Col md={12} style={{ marginTop: "30px" }}>
              <Row>
                <Row className="mb-3">
                    <label className="title col-md-4">Jabatan</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_current_position_name ?? "-"}</label>
                    </div>
                </Row>
                <Row className="mb-3">
                    <label className="title col-md-4">NIP</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_nip ?? "-"}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Jenis Kelamin</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_gender_name ?? "-"}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">NIP Lama</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_nip_old ?? "-"}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Email</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_email_internal ?? "-"}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Unit Kerja</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_current_work_unit_name ?? "-"}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Pangkat, Golongan, TMT</label>
                    <div className="col-md-8">
                      <label className="value">{`${employeeData.employee_current_rank_name ?? "-"}, ${employeeData.employee_current_rank_group_room ?? "-"}, ${moment(employeeData.employee_current_tmt_rank).format('DD-MM-YYYY') ?? "-"}`}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Masa Kerja Golongan</label>
                    <div className="col-md-8">
                      <label className="value">{moment().diff(moment(employeeData.employee_current_tmt_rank), 'years')} Tahun {moment().diff(moment(employeeData.employee_current_tmt_rank), 'months') % 12} Bulan</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Masa Kerja Keseluruhan</label>
                    <div className="col-md-8">
                      <label className="value">{`${employeeData.masa_kerja_tahun} Tahun ${employeeData.masa_kerja_bulan} Bulan`}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Alamat Rumah Sesuai KTP</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_identity_address ?? "-"}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Alamat Rumah Domisili</label>
                    <div className="col-md-8">
                      <label className="value">{employeeData.employee_domicile_address ?? "-"}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Kota/Kode Pos</label>
                    <div className="col-md-8">
                      <label className="value">{`${employeeData.employee_domicile_city_name ?? "-"}/${employeeData.employee_domicile_postal_code ?? "-"}`}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Provinsi/Negara</label>
                    <div className="col-md-8">
                      <label className="value">{`${employeeData.employee_domicile_province_name ?? "-"}/${employeeData.employee_domicile_country_name ?? "-"}`}</label>
                    </div>
                </Row>
                <Row  className="mb-3">
                    <label className="title col-md-4">Telepon Rumah/Hp</label>
                    <div className="col-md-8">
                      <label className="value">{`${employeeData.employee_phone_number ?? "-"}/${employeeData.employee_mobile_phone_number ?? "-"}`}</label>
                    </div>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    )
  }
  
  const renderRiwayatPendidikan = (title = RIWAYAT_PENDIDIKAN, list = educationList) => {
    return (
      <>
        <div style={{borderRadius: "9px", border: "1px solid lightBlue", borderTop: "8px solid lightBlue", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)"}}>
          <div style={{padding: "30px"}}>
            {renderTitle("Riwayat Pendidikan")}
            <Row>
              <Col md={12}>
                {educationList.map((education, index) => 
                currentIndex[title] == index + 1 && (
                  <Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <label className="title">Jenjang</label>
                      </Col>
                      <Col md={8}>
                        <label className="value">{education.education_background_education_name ?? "-"}</label>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <label className="title">Sekolah/Perguruan Tinggi</label>
                      </Col>
                      <Col md={8}>
                        <label className="value">{education.education_background_school_name ?? "-"}</label>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <label className="title">Jurusan</label>
                      </Col>
                      <Col md={8}>
                        <label className="value">{education.education_background_school_major ?? "-"}</label>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <label className="title">Nomor Ijazah</label>
                      </Col>
                      <Col md={8}>
                        <label className="value">{education.education_background_certificate_number ?? "-"}</label>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col md={4}>
                        <label className="title">Tahun Lulus</label>
                      </Col>
                      <Col md={8}>
                        <label className="value">{[education.education_background_graduation_year ?? "-"]}</label>
                      </Col>
                    </Row>
                  </Row>
                )
                )}
              </Col>
            </Row>
          </div>
          <hr/>
          <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "30px", paddingRight: "30px"}}>
            <i className="uil-arrow-left" style={{ fontSize: currentIndex[title] == 1 || currentIndex[title] == 0 ? "0px" : "20px" }} onClick={() => decreaseIndex(title)}/>
            <p>{currentIndex[title]} of {list.length}</p>
            <i className="uil-arrow-right" style={{  fontSize: currentIndex[title] == list.length ? "0px" : "20px"}} onClick={() => increaseIndex(title)}/>
          </div>
        </div>
      </>
    );
  }

  const renderRiwayatJabatan = (title = RIWAYAT_JABATAN, list = positionList) => {
    return <div style={{borderRadius: "9px", border: "1px solid green", borderTop: "8px solid green", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)"}}>
      <div style={{padding: "30px"}}>
        {renderTitle("Riwayat Jabatan")}
        <Row>
          <Col md={12}>
            {positionList.map((position, index) => 
            currentIndex[title] == index + 1 && (
              <Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Eselon</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{position.echelon_name ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Nama Jabatan</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{position.position_history_position_name ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">TMT Jabatan</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{position.position_history_tmt_position_date ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Nomor SK</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{position.position_history_decree_number ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Tanggal SK</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{[position.position_history_decree_date ?? "-"]}</label>
                  </Col>
                </Row>
              </Row>
            )
            )}
          </Col>
        </Row>
      </div>
      <hr/>
      <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "30px", paddingRight: "30px"}}>
        <i className="uil-arrow-left" style={{ fontSize: currentIndex[title] == 1 || currentIndex[title] == 0 ? "0px" : "20px" }} onClick={() => decreaseIndex(title)}/>
        <p>{currentIndex[title]} of {list.length}</p>
        <i className="uil-arrow-right" style={{  fontSize: currentIndex[title] == list.length ? "0px" : "20px"}} onClick={() => increaseIndex(title)}/>
      </div>
    </div>
  }

  const renderRiwayatPangkat = (title = RIWAYAT_PANGKAT, list = rankList) => {
    return <div style={{borderRadius: "9px", border: "1px solid red", borderTop: "8px solid red", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)"}}>
      <div style={{padding: "30px"}}>
        {renderTitle("Riwayat Pangkat")}
        <Row>
          <Col md={12}>
            {rankList.map((rank, index) => 
            currentIndex[title] == index + 1 && (
              <Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Pangkat</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.rank_name ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Nomor SK</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.rank_history_decree_number ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Tanggal SK</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.rank_history_decree_date ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Golongan</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.rank_group_room ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">TMT</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.rank_history_tmt_date ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Masa Kerja</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{`${rank.rank_history_year_period} Tahun ${rank.rank_history_month_period} Bulan`}</label>
                  </Col>
                </Row>
              </Row>
            )
            )}
          </Col>
        </Row>
      </div>
      <hr/>
      <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "30px", paddingRight: "30px"}}>
        <i className="uil-arrow-left" style={{ fontSize: currentIndex[title] == 1 || currentIndex[title] == 0 ? "0px" : "20px" }} onClick={() => decreaseIndex(title)}/>
        <p>{currentIndex[title]} of {list.length}</p>
        <i className="uil-arrow-right" style={{  fontSize: currentIndex[title] == list.length ? "0px" : "20px"}} onClick={() => increaseIndex(title)}/>
      </div>
    </div>;
  }

  const renderRiwayatHukumanDisiplin = (title = HUKUMAN_DISIPLIN, list = hukumanDisiplinList) => {
    return <div style={{borderRadius: "9px", border: "1px solid orange",  borderTop: "8px solid orange", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)"}}>
      <div style={{padding: "30px"}}>
      {renderTitle("Riwayat Hukuman Disiplin")}
        <Row>
          <Col md={12}>
            {hukumanDisiplinList.map((rank, index) => 
            currentIndex[title] == index + 1 && (
              <Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Nomor SK</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.punishment_discipline_sk_number ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Tanggal SK</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.punishment_discipline_sk_date ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">TMT SK</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.punishment_discipline_sk_tmt_date ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Rekomendasi Hukuman</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.punishment_discipline_punishment_name ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Hasil Akhir Hukuman</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.punishment_discipline_result_final_punishment_name ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Status</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.punishment_discipline_status_name ?? "-"}</label>
                  </Col>
                </Row>
              </Row>
            )
            )}
          </Col>
        </Row>
      </div>
      <hr/>
      <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "30px", paddingRight: "30px"}}>
        <i className="uil-arrow-left" style={{ fontSize: currentIndex[title] == 1 || currentIndex[title] == 0 ? "0px" : "20px" }} onClick={() => decreaseIndex(title)}/>
        <p>{currentIndex[title]} of {list.length}</p>
        <i className="uil-arrow-right" style={{  fontSize: currentIndex[title] == list.length ? "0px" : "20px"}} onClick={() => increaseIndex(title)}/>
      </div>
    </div>
  }

  const renderDiklat = (title, list) => (
    <>
      <div style={{padding: "30px"}}>
        <h4>{title}</h4>
        <Row>
          <Col md={12}>
            {list.map((rank, index) => 
            currentIndex[title] == index + 1  && (
              <Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Nama Diklat</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.training_type_name ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Lokasi</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.tranining_place ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Tanggal Mulai</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{moment(rank.training_start_date).format('DD-MM-YYYY') ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Tanggal Selesai</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{moment(rank.training_end_date).format('DD-MM-YYYY') ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Penyelenggara</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.training_organizer ?? "-"}</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={4}>
                    <label className="title">Jumlah Jam</label>
                  </Col>
                  <Col md={8}>
                    <label className="value">{rank.training_number_of_hours ?? "-"}</label>
                  </Col>
                </Row>
              </Row>
            )
            )}
          </Col>
        </Row>
      </div>
      <hr/>
      <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "30px", paddingRight: "30px"}}>
          <i className="uil-arrow-left" style={{ fontSize: currentIndex[title] == 1 || currentIndex[title] == 0 ? "0px" : "20px" }} onClick={() => decreaseIndex(title)}/>
          <p>{currentIndex[title]} of {list.length}</p>
          <i className="uil-arrow-right" style={{  fontSize: currentIndex[title] == list.length ? "0px" : "20px"}} onClick={() => increaseIndex(title)}/>
      </div>
    </>
  )

  return (
    <>
      <div
        className="main"
        style={{
          position: "relative",
          minHeight: "100vh",
          overflow: "hidden"
        }}
      >
        <div className="centered-vertical" style={{ width: "80px", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, left: 0, zIndex: 99, cursor: "pointer"}}>
          <Link to="/home_new.html" ><i className="uil-angle-left"/> Kembali</Link>
        </div>

        <Profile/>

        <div style={{ margin: "13vh 8vw 13vh 8vw" }}>
          <p style={{ color: "#575757", fontFamily: "IBM Plex Sans", fontSize: "24px", fontStyle: "normal", fontWeight: "600", lineHeight: "normal", marginBottom: "20px" }}>Profil Pegawai</p>
          <Row className="mb-3">
            <Col md={6}>
              <Col md={12} className="mb-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                {renderProfile()}
              </Col>
              <Col md={12} className="mb-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                {renderRiwayatJabatan()}
              </Col>
              <Col md={12} className="mb-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                {renderRiwayatPendidikan()}
              </Col>
            </Col>
            <Col md={6}>
              <div className="mb-3" style={{borderRadius: "9px", border: "1px solid aqua", borderTop: "8px solid aqua", background: "#FFF", boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)"}}>
                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {renderDiklat(DIKLAT_FUNGSIONAL, fungsionalList)}
                </Col>
                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {renderDiklat(DIKLAT_STRUKTURAL, strukturalList)}
                </Col>
                <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                {renderDiklat(DIKLAT_TEKNIS, teknisList)}
                </Col>
              </div>
              <Col md={12} className="mb-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                {renderRiwayatPangkat()}
              </Col>
              <Col md={12} className="mb-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                {renderRiwayatHukumanDisiplin()}
              </Col>
              
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ProfileView;

import { apiRequest, searchEmployee, finishTour } from "../../../../services/adapters/base";

class EmployeeRepository {
  fetchSearch = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      searchEmployee,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchFinishTour = async (query) => {
    return await apiRequest(
      "post",
      finishTour,
      {
        body: query,
      },
      true
    );
  };
}

export default EmployeeRepository;

import { Icon } from "@iconify/react";
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm
} from "availity-reactstrap-validation";
import React, { Component } from "react";
import { ConfirmAlert } from "../../../../../helpers/ui/alert";
import {
  FileInput,
  generateFormData
} from "../../../../../helpers/ui/FileInput";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import TicketBloc from "../../Blocs/TicketBloc";
import CustomSelect from "../../../../../helpers/ui/CustomSelect";
import "./style.scss";
import { Modal, ModalBody } from "reactstrap";
import PanduanSection from "../../Components/PanduanSection";

let reformatTypeHelpdeskService = function (data) {
  return data.map(function (service) {
    let newObj = {};
    newObj["label"] = service.helpdesk_service_name;
    newObj["value"] = service.helpdesk_service_id;
    return newObj;
  });
}

let reformatTypeHelpdeskModule = function (data) {
  return data.map(function (module) {
    let newObj = {};
    newObj["label"] = module.helpdesk_module_name;
    newObj["value"] = module.helpdesk_module_id;
    return newObj;
  });
}

class HelpDesk extends Component {
  source;
  ticketBloc = new TicketBloc();
  user = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        ticket_service: 0,
      },
      services: this.props.services,
      optServices: reformatTypeHelpdeskService(this.props.services),
      showHelpdesk: false,
      showGuide: false,
    };
  }

  handleTicketServiceChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_service_id"]: e.value,
      },
      optModules: reformatTypeHelpdeskModule(this.state.services.filter((helpdeskService) => helpdeskService.helpdesk_service_id == e.value)[0].modules)
    });
  };

  handleTicketModuleChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_module_id"]: e.value,        
      },
    });
  };

  handleInputChange = (e) => {
    if (e.currentTarget.name == "ticket_service") {
      this.setState({
        optModules: reformatTypeHelpdeskModule(this.state.services.filter((helpdeskService) => helpdeskService.helpdesk_service_id == e.currentTarget.value)[0].modules)
      })
    }

    try {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          [e.currentTarget.name]: e.currentTarget.value,
        },
      });
    } catch (e) {
      this.setState({
        ...this.state.dataForm,
        ticket_attachment: e.target.value,
      });
    }

  };

  handleChatClick = () => {
    this.setState({
      showHelpdesk: !this.state.showHelpdesk,
    });
  };

  handleGuideClick = () => {
    this.setState( {
      showGuide: !this.state.showGuide
    })
  }

  submitData = async () => {
    this.state.dataForm["employee_uuid"] =  this.user.employee_uuid;
      
    const formData = generateFormData(this.state.dataForm, {
      field: "ticket_attachment",
      name: "ticketAttachments/attachment",
    });
    await this.ticketBloc.fetchCreate(formData);
  };

  handlerOnSubmit = (e, values) => {
    e.preventDefault();

    this.setState({
      confirm: true,
      dataForm: {
        ...this.state.dataForm,
        ...values,
      },
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  componentDidMount() {
    const initName = this.user.employee_name;
    const initEmail = this.user.employee_email_internal;

    this.ticketBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          setTimeout(() => {
            this.setState({
              showHelpdesk: false,
              loading: null,
              success: null,
            });
          }, 1200);
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    if (initName && initEmail) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          ticket_init_name: initName,
          ticket_init_email: initEmail,
        },
      });
    }
  }

  componentWillUnmount() {
    this.ticketBloc.createChannel.unsubscribe();
  }

  render() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px"}} >
          <div className="float-container2">
            <div className="float-btn-guide" onClick={this.handleGuideClick}>
                <Icon
                  icon="ri-question-line"
                  className={this.state.showGuide ? "inactive" : "active"}
                />
                <Icon
                  icon="line-md:close"
                  className={this.state.showGuide ? "active" : "inactive"}
                />
            </div>
          </div>
          <div className="float-container">
            <div className="float-btn" onClick={this.handleChatClick}>
              <Icon
                icon="ri:customer-service-2-line"
                className={this.state.showHelpdesk ? "inactive" : "active"}
              />
              <Icon
                icon="line-md:close"
                className={this.state.showHelpdesk ? "active" : "inactive"}
              />
            </div>
            <a className="float-btn2" target="_blank" href={`https://portal-admin${process.env.REACT_APP_ENVIRONMENT === "development" ? "-dev" :  ""}.bkpm.go.id/admin/helpdesk.html`}>
            <Icon
              icon="ri:find-replace-fill"
              className="active"
            />
          </a>
          </div>
          {/* <a className="float-btn2" target="_blank" href={`http://localhost:3001/admin/helpdesk.html`}> */}
        </div>
        {this.state.showHelpdesk && (
          <div className="float-form-container" style={{ zIndex: 99 }}>
            <h5 className="form-title">Helpdesk</h5>
            <p className="form-subtitle">
              Silahkan submit keluhan anda melalui isian di bawah ini.
            </p>
            <hr />
            <AvForm
              className="needs-validation"
              onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
              onKeyPress={this.preventEnter}
            >
              <div className="form-group">
                <label htmlFor="ticket_subject">Subjek</label>
                <AvField
                  name="ticket_subject"
                  placeholder="Masukkan subjek..."
                  type="text"
                  errorMessage="Subjek tidak boleh kosong"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="ticket_subject"
                  onChange={this.handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="ticket_service">Layanan</label>
                <CustomSelect
                  name="ticket_service"
                  id="ticket_service"
                  errorMessage="Silahkan pilih layanan"
                  options={this.state.optServices}
                  validate={{ required: { value: true } }}
                  onChange={this.handleTicketServiceChange}
                  placeholder="Pilih layanan" />
              </div>
 
              <div className="form-group">
                <label htmlFor="ticket_service">Modul</label>
                <CustomSelect
                  name="ticket_module"
                  id="ticket_module"
                  errorMessage="Silahkan pilih modul"
                  options={this.state.optModules}
                  validate={{ required: { value: true } }}
                  onChange={this.handleTicketModuleChange}
                  placeholder={this.state.optModules == null ? "Silahkan pilih layanan terlebih dahulu" : "Pilih modul" } />
              </div>

              <div className="form-group">
                <label htmlFor="ticket_content">Deskripsi</label>
                <AvField
                  name="ticket_content"
                  placeholder="Masukkan dreskripsi keluhan..."
                  type="textarea"
                  rows={3}
                  errorMessage="Deskripsi tidak boleh kosong"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="ticket_content"
                  onChange={this.handleInputChange}
                />
              </div>

              <label htmlFor="ticket_attachment" className="col-form-label">
                Lampiran
              </label>
              <div className="form-group">
                <FileInput
                  name="ticket_attachment"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.jpg,.png,.jpeg"
                  isMultiple={true}
                  maxFiles={3}
                />
                <p style={{ fontWeight: 500 }} className="text-secondary">
                  Format file yang diijinkan: .pdf, .doc, .docx, .xls, .xlsx,
                  .txt, .jpg, .png
                </p>
                <p className="text-secondary">
                  *Maksimal 3 files dengan ukuran paling besar 10MB.
                </p>
              </div>
              <div className="form-group">
                <AvCheckboxGroup
                  name="checkboxExample"
                  required
                  errorMessage="Agreement harus di centang"
                >
                  <AvCheckbox
                    label="Saya yakin dan bertanggung jawab atas data ini"
                    value="1"
                  />
                </AvCheckboxGroup>
              </div>
              <div className="form-group pb-4" style={{ fontWeight: 500 }}>
                <button
                  className="btn btn-primary float-right px-3"
                  type="submit"
                >
                  <Icon
                    icon="icon-park-outline:send-email"
                    className="mr-2"
                    width={15}
                  />
                  <span>Kirim</span>
                </button>
                <button
                  className="btn btn-danger float-right px-3 mr-3"
                  onClick={() => {
                    this.setState({
                      showHelpdesk: false,
                    });
                  }}
                >
                  <Icon
                    icon="icon-park-outline:close"
                    className="mr-2"
                    width={15}
                  />
                  <span>Batal</span>
                </button>
              </div>
            </AvForm>

            <ConfirmAlert
              confirmTitle="Konfirmasi!"
              confirmInfo="Apakah anda yakin akan mengirim data ini?"
              loadingTitle="Mengirim data..."
              loadingInfo="Tunggu beberapa saat"
              successTitle="Berhasil!"
              successInfo="Data berhasil disimpan"
              failedTitle="Gagal!"
              failedInfo="Data gagal disimpan"
              showConfirm={this.state.confirm}
              showLoading={this.state.loading}
              showSuccess={this.state.success}
              showFailed={this.state.failed}
              response={this.confirmResponse}
            />
          </div>
        )}
        <div>
          <Modal isOpen={this.state.showGuide} toggle={this.state.showGuide} centered={true} size="xl">
            <ModalBody className="app-modal-body">
              <center><h2
                  style={{ fontWeight: "bold", color: "#0F0465" }}
                >
                  Panduan Aplikasi
                </h2>
                </center>
              <div className="close-btn" onClick={this.handleGuideClick}>
                <Icon icon="icomoon-free:cross" width={20} />
              </div>
              <>
                <PanduanSection />
              </>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default HelpDesk;

import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import AttendanceRepository from "../Repositories/AttendanceRepository";

class AttendanceBloc {
  attendanceChannel = new Subject();
  repository = new AttendanceRepository();

  constructor() {
    this.attendanceChannel.next({ status: apiResponse.INITIAL });
  }

  fetchCreate = async (query) => {
    this.attendanceChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchCreate(query)
      .then((result) => {
        this.attendanceChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.attendanceChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default AttendanceBloc;

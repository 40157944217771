import { Component } from "react";
import moment from "moment";
import Header from "../../../components/DefaultLayout/Header/header";
import FooterSection from "../Index/Components/Footer";
// import { announcement } from "../../../data";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import DetailBloc from "./Blocs/DetailBloc";
import { withRouter } from "react-router-dom";
import { isValidHttpUrl } from "../../../helpers/utility";
import apiResponse from "../../../services/apiResponse";
import MinioClient from "../../../services/minioClient";
import Wysiwyg from "./Components/Wysiwyg";

class IndexView extends Component {
  userdata = JSON.parse(localStorage.getItem("userdata"));
  detailBloc = new DetailBloc();
  minio = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchAttachment = async (path) => {
    const attachment = await this.minio.get(path).then(
      (res) => res,
      (err) => err
    );

    this.setState({
      announcement: {
        ...this.state.announcement,
        attachment_uri: attachment,
      },
    });
  };

  componentDidMount() {
    const header = document.getElementById("header");

    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            
            this.setState({
              announcement: {
                ...response,
                announcement_content: response.announcement_content,
              },
            });
            this.fetchAttachment(this.state.announcement.attachment_path);
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ uuid: queryParams.get("announcement"), employee_uuid: this.userdata.employee_uuid });
    header.scrollIntoView();
  }

  componentWillUnmount() {
    this.detailBloc.detailChannel.unsubscribe();
  }

  isJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }

  render() {
    return (
      <>
        <div
          className="main"
          style={{
            background: "#F8F9F9",
            position: "relative",
            minHeight: "100vh",
          }}
        >
          <img
            src="img/ornaments/Pengumuman_bg.svg"
            alt="ornaments"
            style={{
              position: "absolute",
              top: "50%",
              left: "-20rem",
              transform: "translate(50%, -50%)",
              width: "23rem",
              userSelect: "none",
              MozUserSelect: "none",
            }}
          />
          <Header />
          {this.state.announcement ? (
            <div className="container" style={{ padding: "6rem" }}>
              <div className="container py-5">
                <Link
                  className="back-btn-container"
                  style={{
                    display: "flex",
                    columnGap: ".5rem",
                    alignItems: "center",
                    fontSize: "11pt",
                    cursor: "pointer",
                  }}
                  to="home.html"
                >
                  <Icon icon="akar-icons:arrow-left" />
                  <span>Kembali</span>
                </Link>
                <div
                  className="content mt-5"
                  data-aos="zoom-in-up"
                  data-aos-duration="1100"
                  data-aos-delay="200"
                >
                  {this.isJSON(this.state.announcement.announcement_content) ? <Wysiwyg
                    readOnly={true}
                    init={JSON.parse(this.state.announcement.announcement_content)}
                  /> : <div dangerouslySetInnerHTML={{ __html: this.state.announcement.announcement_content != null ? this.state.announcement.announcement_content : this.state.announcement.announcement_title }} />}
                  
                  <hr />
                  {this.state.announcement.attachment_uri ? (
                    <a
                      className="announcement-attachment"
                      style={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "black",
                        fontWeight: "500"
                      }}
                      href={this.state.announcement.attachment_uri}
                    >
                      <Icon icon="bx:bxs-file-pdf" color="#CD1818" width={24} />
                      <span>{this.state.announcement.attachment_name}</span>
                    </a>
                  ) : (
                    <div
                      className="no-attachment-container"
                      style={{
                        display: "flex",
                        columnGap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        icon="uit:exclamation-triangle"
                        color="#888"
                        width={16}
                      />
                      <span style={{ fontStyle: "italic", color: "#888" }}>
                        Tidak ada lampiran
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <FooterSection noSubscription={true} />
      </>
    );
  }
}

export default withRouter(IndexView);

import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import PortalServiceRepository from "../Repositories/PortalServiceRepository";

class PortalServiceBloc {
  listChannel = new Subject();
  keywordChannel = new Subject();
  roughChannel = new Subject()
  repository = new PortalServiceRepository();

  constructor() {
    this.listChannel.next({ status: apiResponse.INITIAL });
    this.keywordChannel.next({ status: apiResponse.INITIAL });
  }

  fetchList = async (query, cancelToken) => {
    this.listChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchList(query, cancelToken)
        .then((result) => {
          this.listChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.listChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchKeyword = async (query, cancelToken) => {
    this.keywordChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchKeyword(query, cancelToken)
        .then((result) => {
          this.keywordChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.keywordChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchRoughs = async (query, cancelToken) => {
    this.roughChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchRoughs(query, cancelToken)
        .then((result) => {
          this.roughChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.roughChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };
}

export default PortalServiceBloc;

import { BehaviorSubject, Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import VerifyPdfRepository from "../Repositories/VerifyPdfRepository";

class AttendanceBloc {
  verifyPdfChannel = new BehaviorSubject();
  repository = new VerifyPdfRepository();

  constructor() {
    this.verifyPdfChannel.next({ status: apiResponse.INITIAL });
  }

  fetchVerify = async (query) => {
    this.verifyPdfChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchVerify(query)
      .then((result) => {
        this.verifyPdfChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.verifyPdfChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default AttendanceBloc;

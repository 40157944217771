import React, { createContext, useEffect, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // states
  const [source, setSource] = useState();
  const [dataForm, setDataForm] = useState({
    agenda_participants: [],
  });
  const [agendas, setAgendas] = useState();
  const [activeNav, setActiveNav] = useState();

  // side effects
  useEffect(() => {
    console.log("dataForm: ", dataForm);
  }, [dataForm]);

  return (
    <GlobalContext.Provider
      value={{
        dataForm,
        setDataForm,
        source,
        setSource,
        agendas,
        setAgendas,
        activeNav,
        setActiveNav,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import {
  apiRequest,
  attendanceInt,
} from "../../../../services/adapters/base";

class AttendanceRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      attendanceInt,
      {
        body: query,
      },
      true
    );
  };
}

export default AttendanceRepository;

import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";

// import MinioClient from "../../../../services/minioClient";
import "./Wysiwyg.scss";

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const timestamp = new Date();
  });
}

const Wysiwyg = ({
  changeValue,
  changeValueHtml,
  readOnly = false,
  defaultValue = null
}) => {
  const defaultValueData = defaultValue ? EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(defaultValue)
    )
  ) : null;
  const [editorState, setEditorState] = useState(defaultValueData);
  const [rawContent, setRawContent] = useState("");
  const [htmlState, setHtmlState] = useState("");

  const readOnlyStyle = {
    border: "none",
    padding: 0,
  };

  const handleContentChange = (state) => {
    if (!readOnly) {
      const content = state.getCurrentContent();
      setEditorState(state);
      setRawContent(convertToRaw(content));
      setHtmlState(draftToHtml(convertToRaw(content)));      

      // state update untuk component parent menggunakan callback
      changeValue(JSON.stringify(rawContent), htmlState);
    }
  };

  return (
    <div className="rdw-storybook-root">
      <Editor
        toolbarClassName={`rdw-storybook-toolbar ${readOnly ? "hidden" : ""}`}
        wrapperClassName="rdw-storybook-wrapper"
        editorClassName={`rdw-storybook-editor h-24 ${readOnly ? "" : "px-2"} ${
          readOnly ? "border-none" : "border-1-gray"
        }`}
        onEditorStateChange={handleContentChange}
        defaultEditorState={editorState}
        readOnly={readOnly}
        editorStyle={readOnly ? readOnlyStyle : null}
        wrapperStyle={readOnly ? readOnlyStyle : null}
      />
    </div>
  );
};

export default Wysiwyg;

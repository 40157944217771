const apiResponse = {
  INITIAL: "initial",
  LOADING: "loading",
  COMPLETED: "completed",
  ERROR: "error",
  CALLBACK: "callback",
};

export default apiResponse;


import { Redirect } from "react-router-dom";

import Logout from "../pages/Authentication/Logout";
import Portal from "../pages/Portal/RouteView";
import PortalNew from "../pages/PortalNew/RouteView";
import AttendanceInternal from "../pages/Portal/Attendance/View";
import AttendanceExternal from "../pages/Attendance/View";
import VerifyPdf from "../pages/PortalNew/VerifyPdf/View";

//Admin
import Agenda from "../pages/Agenda/RouteView";
import Helpdesk from "../pages/Helpdesk/RouteView";
import Announcement from "../pages/Announcement/RouteView";
import Notification from "../pages/Home/Notification/RouteView";
import MeetingRegistration from "../pages/MeetingRegistration/RouteView";

//NLP Services
import bookingMroomService from "../pages/PortalNew/Services/BookingRoom/View";
import ProfileService from "../pages/PortalNew/Services/Profile/View";
import RiwayatJabatanService from "../pages/PortalNew/Services/RiwayatJabatan/View";
import RiwayatPangkatService from "../pages/PortalNew/Services/RiwayatPangkat/View";
import DataDiklatService from "../pages/PortalNew/Services/DataDiklat/View";
import RiwayatPendidikanService from "../pages/PortalNew/Services/RiwayatPendidikan/View";
import HukumanDisiplinService from "../pages/PortalNew/Services/RiwayatHukumanDisiplin/View";
import PeminjamanPeralatanService from "../pages/PortalNew/Services/PeminjamanPeralatan/View";
import SaldoCutiService from "../pages/PortalNew/Services/SaldoCuti/View";
import PengajuanCutiService from "../pages/PortalNew/Services/PengajuanCuti/View";
import PeminjamanKendaraanDinasService from "../pages/PortalNew/Services/PeminjamanKendaraanDinas/View";
import PengajuanSlipGajiService from "../pages/PortalNew/Services/PengajuanSlipGaji/View";
import PresensiPegawaiService from "../pages/PortalNew/Services/PresensiPegawai/View";
import LegalisirDokumenService from "../pages/PortalNew/Services/LegalisirDokumen/View";

const userRoutes = [
  //base
  { path: "/", exact: true, component: () => <Redirect to="/home_new.html" /> },
];

const authRoutes = [
  { path: "/logout.html", component: Logout, authRequired: true },
  { path: "/home.html", component: PortalNew, authRequired: true },
  { path: "/home_new.html", component: PortalNew, authRequired: true },
  { path: "/attendance_int.html", component: AttendanceInternal, authRequired: true },
  { path: "/attendance_ext.html", component: AttendanceExternal, authRequired: false },
  { path: "/verify_pdf.html", component: VerifyPdf, authRequired: true },
  { path: "/service_booking_mroom.html", component: bookingMroomService, authRequired: true },
  { path: "/service_profile.html", component: ProfileService, authRequired: true },
  { path: "/service_riwayat_jabatan.html", component: RiwayatJabatanService, authRequired: true },
  { path: "/service_riwayat_pangkat.html", component: RiwayatPangkatService, authRequired: true },
  { path: "/service_data_diklat.html", component: DataDiklatService, authRequired: true },
  { path: "/service_riwayat_pendidikan.html", component: RiwayatPendidikanService, authRequired: true },
  { path: "/service_hukuman_disiplin.html", component: HukumanDisiplinService, authRequired: true },
  { path: "/service_peminjaman_barang.html", component: PeminjamanPeralatanService, authRequired: true },
  { path: "/service_saldo_cuti.html", component: SaldoCutiService, authRequired: true },
  { path: "/service_pengajuan_cuti.html", component: PengajuanCutiService, authRequired: true },
  { path: "/service_peminjaman_kendaraan_dinas.html", component: PeminjamanKendaraanDinasService, authRequired: true },
  { path: "/service_pengajuan_slip_gaji.html", component: PengajuanSlipGajiService, authRequired: true },
  { path: "/service_presensi_pegawai.html", component: PresensiPegawaiService, authRequired: true },
  { path: "/service_legalisir_dokumen.html", component: LegalisirDokumenService, authRequired: true },
];

const adminRoutes = [
  { path: "/admin/agenda.html", component: Agenda },
	{ path: "/admin/helpdesk.html", component: Helpdesk },
	{ path: "/admin/pengumuman.html", component: Announcement },
	{ path: "/admin/meeting_registration.html", component: MeetingRegistration },
  { path: "/admin/notification.html", component: Notification },
]

export { userRoutes, authRoutes, adminRoutes };

import classnames from "classnames";
import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { accessRole } from "../../../helpers/access_role";
import TableActiveComponent from "./Components/TableActiveComponent";
import "./style.scss";

const IndexView = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const activeRef = useRef();

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const renderTableView = () => {
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">
                <span>Open tickets </span>
                <div className="custom-badge bg-primary">20</div>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">
                <span>Assigned to me </span>
                <div className="custom-badge bg-warning">3</div>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "3",
              })}
              onClick={() => {
                toggle("3");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">
                <span>Assigned to others </span>
                <span className="custom-badge bg-secondary">41</span>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "4",
              })}
              onClick={() => {
                toggle("4");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">
                <span>Unassigned </span>
                <span className="custom-badge bg-danger">2</span>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "5",
              })}
              onClick={() => {
                toggle("5");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Akses</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted">
          <TabPane tabId="1">
            <TableActiveComponent ref={activeRef} />
          </TabPane>
          <TabPane tabId="2">
            <TableActiveComponent ref={activeRef} />
          </TabPane>
          <TabPane tabId="3">
            <TableActiveComponent ref={activeRef} />
          </TabPane>
          <TabPane tabId="4">
            <TableActiveComponent ref={activeRef} />
          </TabPane>
          <TabPane tabId="5">
            Akses:
            <div className="button-items">
              <Button
                disabled={!accessRole("create")}
                color="primary"
                className="btn btn-sm btn-info waves-effect waves-light"
              >
                Create
              </Button>
              <Button
                disabled={!accessRole("read")}
                color="primary"
                className="btn btn-sm btn-info waves-effect waves-light"
              >
                Read
              </Button>
              <Button
                disabled={!accessRole("update")}
                color="primary"
                className="btn btn-sm btn-info waves-effect waves-light"
              >
                Update
              </Button>
              <Button
                disabled={!accessRole("delete")}
                color="primary"
                className="btn btn-sm btn-info waves-effect waves-light"
              >
                Delete
              </Button>
              <Button
                disabled={!accessRole("approve")}
                color="primary"
                className="btn btn-sm btn-info waves-effect waves-light"
              >
                Approve
              </Button>
              <Button
                disabled={!accessRole("print")}
                color="primary"
                className="btn btn-sm btn-info waves-effect waves-light"
              >
                Print
              </Button>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  };

  const renderView = () => {
    return renderTableView();
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="d-flex" style={{ columnGap: "1rem" }}>
                    {accessRole("create") ? (
                      <Link to="agenda.html?create">
                        <Button color="primary">Buat Tiket</Button>
                      </Link>
                    ) : null}
                  </div>
                </div>
                <CardTitle>Tiket</CardTitle>
                <CardSubtitle className="mb-3">Data - data tiket</CardSubtitle>
                <hr />
                {renderView()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IndexView;

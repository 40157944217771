import {
    apiRequest,
    helpdeskServiceList,
  } from "../../../../services/adapters/base";
  
  class HelpdeskRepository {
    fetchList = async (query, cancelToken) => {
      return await apiRequest(
        "get",
        helpdeskServiceList,
        {
          cancelToken: cancelToken,
          params: query,
        },
        true
      );
    };
  }
  
  export default HelpdeskRepository;
  
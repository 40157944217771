import { Col, Row } from "reactstrap";
import micIcon from '../../../../../assets/icons/mic-2-fill.png';
import { useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import { Stemmer } from 'sastrawijs';
import FuzzySet from 'fuzzyset';
import Joyride, { STATUS } from 'react-joyride';
import SearchBloc from "../Search/Blocs/SearchBloc";
import "./style.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ConfirmAlert } from "../../../../../helpers/ui/alert";

function SearchInput(props) {
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const navigate = useHistory();
  const stemmer = new Stemmer();
  const searchBloc = new SearchBloc();
  const keywords = props.keywords;
  const candidates = keywords.map((item) => item.nlp_keyword_value.split(" "));
  const indonesianWords = [].concat(...candidates);
  const fuzzy = FuzzySet(indonesianWords);
  const [suggestions, setSuggestions] = useState([]);
  const [inputSentence, setInputSentence] = useState(null);
  const [loadedModel, setLoadedModel] = useState(null);
  const [delayDebounceFn, setDelayDebounceFn] = useState(null);
  const [isSupportSpeechRecognition, setIsSupportSpeechRecognition] = useState(true);
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [clickedSuggestions, setClickedSuggestions] = useState({});

  const steps = [
    {
      target: '.search-input',
      content: 'Gunakan fitur pencarian untuk mengakses layanan tertentu pada portal',
    },
    {
      target: '.btn-voice',
      content: 'Tekan tombol diatas untuk mengakses fitur AI voice input JARPIS',
    },
  ];

  // useEffect(() => {
  //     localStorage.setItem("clickedSuggestions", JSON.stringify(clickedSuggestions));
  //     console.log('aaaa', clickedSuggestions)
  // }, [clickedSuggestions]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputSentence(newValue);
    setSuggestions([]);
    if (newValue !== "" && newValue !== null) {
      handlePredict();
    }

    if (delayDebounceFn) {
      clearTimeout(delayDebounceFn);
    }

    setDelayDebounceFn(
      setTimeout(() => {
        saveKeyword(newValue);
        props.searchRough(newValue);
      }, 2000)
    );
  };

  const saveKeyword = (keyword) => {
    searchBloc.fetchCreate({
      "nlp_keyword_value": keyword,
      "nlp_keyword_type": 1,
      "nlp_keyword_redirect_link": null,
      "nlp_keyword_status": 0
    });
  };

  useEffect(() => {
    return () => {
      if (delayDebounceFn) {
        clearTimeout(delayDebounceFn);
      }
    };
  }, [delayDebounceFn]);

  const handleChangeListening = () => {
    if (!browserSupportsSpeechRecognition) {
      setIsSupportSpeechRecognition(false);
    } else {
      props.changeListening();
    }
  };

  useEffect(() => {
    const loadModel = async () => {
      const modelPath = '/model.json';
      const model = await tf.loadLayersModel(modelPath);
      setLoadedModel(model);
    };

    loadModel();

    const storedClicks = JSON.parse(localStorage.getItem("clickedSuggestions")) || {};
    console.log(('stored clicks', storedClicks))
    setClickedSuggestions(storedClicks);

    return () => {
      tf.dispose();
    };
  }, []);

  const handlePredict = () => {
    if (!loadedModel || !inputSentence) return;

    const reference = stemmer.stem(inputSentence)
        .split(' ').map((word) => fetchSuggestions(word));

    // Calculate BLEU scores for all candidates
    const bleuScores = candidates.map(candidate => calculateSmoothedBLEU(candidate, reference));

    // Get the indices of the top three candidates based on BLEU scores
    const topThreeIndices = bleuScores
        .map((score, index) => ({ score, index }))
        .sort((a, b) => b.score - a.score) // Sort descending
        .slice(0, 3) // Get top three
        .map(item => item.index); // Extract the indices

    // Adjust BLEU scores based on clicked suggestions for top three candidates
    const adjustedScores = candidates.map((candidate, index) => {
        const keyword = candidate.join(" ");
        const clickCount = clickedSuggestions[keyword] || 0; // Default to 0 if not found
        // Only adjust scores for top three candidates
        return topThreeIndices.includes(index)
            ? bleuScores[index] + clickCount * 0.1 // Adjust weight as necessary
            : bleuScores[index]; // Keep original score for others
    });

    const maxBleuScore = Math.max(...adjustedScores);
    const normalizedBleuScores = maxBleuScore === 0 
        ? new Array(candidates.length).fill(0) 
        : adjustedScores.map(score => score / maxBleuScore);

    // Continue with prediction logic...
    const tensorInput = tf.tensor(normalizedBleuScores);
    const predictedProbabilityTensor = loadedModel.predict(tensorInput);
    const predictedProbability = predictedProbabilityTensor.dataSync()[0];

    const predictedBestCandidateIndex = predictedProbability > 0.5
        ? normalizedBleuScores.indexOf(Math.max(...normalizedBleuScores))
        : -1;

    const predictedBestCandidate = predictedBestCandidateIndex !== -1
        ? candidates[predictedBestCandidateIndex]
        : "None";

    if (predictedBestCandidate.join(" ") === "kata kunci tidak ditemukan") {
        const suggestion = processSelectedValue(predictedBestCandidate.join(" "));
        setSuggestions([suggestion]);
    } else {
        const sortedCandidates = candidates.slice().sort((a, b) => {
            const scoreA = adjustedScores[candidates.indexOf(a)];
            const scoreB = adjustedScores[candidates.indexOf(b)];
            return scoreB - scoreA;
        });

        const top3Candidates = sortedCandidates.slice(0, 3);
        let suggestion = [];
        top3Candidates.forEach(element => {
            if (element.join(" ") !== "kata kunci tidak ditemukan") {
                suggestion.push(processSelectedValue(element.join(" ")));
            }
        });
        setSuggestions(suggestion);
    }
};



  // Calculate unigram (1-gram) precision with frequency consideration
  function calculateUnigramPrecision(referenceTokens, candidateTokens) {
    const referenceCount = referenceTokens.reduce((acc, token) => {
      acc[token] = (acc[token] || 0) + 1;
      return acc;
    }, {});

    const candidateCount = candidateTokens.reduce((acc, token) => {
      acc[token] = (acc[token] || 0) + 1;
      return acc;
    }, {});

    const commonUnigrams = Object.keys(candidateCount).reduce((sum, token) => {
      return sum + Math.min(candidateCount[token] || 0, referenceCount[token] || 0);
    }, 0);

    const precision = commonUnigrams / candidateTokens.length;
    return precision;
  }

  // Calculate smoothed BLEU score
  function calculateSmoothedBLEU(candidateTokens, referenceTokens, weights = [1]) {
    const precisions = weights.map(() => calculateUnigramPrecision(referenceTokens, candidateTokens));
    const geometricMean = precisions.reduce((product, precision) => product * precision, 1) ** (1 / precisions.length);
    const brevityPenalty = calculateBrevityPenalty(referenceTokens.length, candidateTokens.length);
    return geometricMean * brevityPenalty;
  }

  // Calculate brevity penalty
  function calculateBrevityPenalty(referenceLength, candidateLength) {
    return candidateLength >= referenceLength ? 1 : Math.exp(1 - referenceLength / candidateLength);
  }

  const processSelectedValue = (data) => {
    const bestData = keywords.find((item) => item.nlp_keyword_value === data);
    return bestData["nlp_keyword_value"];
  };

  const fetchSuggestions = (word) => {
    if (word != null) {
      const fuzzyResults = fuzzy.get(word);
      const autocorrectSuggestions = fuzzyResults ? (fuzzyResults[0][0] > 0.5 ? fuzzyResults[0][1] : word) : word;
      return autocorrectSuggestions;
    }
  };

  const processSelectedData = (data) => {
    const bestData = keywords.find((item) => item.nlp_keyword_value === data);

    if (bestData) {
        const keyword = bestData.nlp_keyword_value;
        const newClickedSugenstions = {
          ...clickedSuggestions,
          [keyword]: (clickedSuggestions[keyword] || 0) + 1,
        }

        setClickedSuggestions(newClickedSugenstions)
        localStorage.setItem("clickedSuggestions", JSON.stringify(newClickedSugenstions));

        if (bestData.nlp_keyword_redirect_link) {
            setInputSentence("");
            if (bestData.nlp_keyword_type === 2) {
                window.open(bestData.nlp_keyword_redirect_link, "_blank");
            } else if (bestData.nlp_keyword_type === 1) {
                navigate.push(bestData.nlp_keyword_redirect_link);
            }
        }
    }
};

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === STATUS.FINISHED) {
      localStorage.setItem("showTour", false);
      props.endTour();
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        continuous={true}
        run={localStorage.getItem("showTour") === "true"}
        callback={handleJoyrideCallback}
        showSkipButton={true}
        disableScrolling={true}
        disableScrollParentFix={true}
        scrollOffset={0}
        locale={{ back: 'Kembali', close: 'Tutup', last: 'Selesai', next: 'Lanjut', open: 'Lihat tutorial', skip: 'Skip' }}
        styles={{
          options: {
            primaryColor: "var(--The-Blue, #3E70D1)",
          }
        }}
      />

      <Row className="centered-horizontal" style={{ position: "relative", zIndex: 10, marginTop: 50 }}>
        <div className="custom-input" style={{ position: "relative", width: "643px", height: "50px", backgroundColor: "white", border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingLeft: "20px", paddingRight: "20px", filter: "drop-shadow(0px 13px 24px rgba(61, 59, 165, 0.06))" }}>
          <div className="search-input" style={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Peminjaman ruang rapat..."
              style={{ fontSize: "18px", width: "100%", border: "none", outline: "none", borderRadius: "60px", paddingLeft: "20px", paddingRight: "60px" }}
              onChange={handleInputChange}
            />
          </div>
          {suggestions.length > 0 && (
            <div style={{ paddingTop: 24, paddingBottom: 24, borderRadius: "8px", border: "1px solid #DEDEDE", background: "linear-gradient(80deg, #F3F2F2 -180.51%, #FFF 126.12%)" }}>
              <p style={{ margin: "0px 24px 5px 24px", color: "var(--Default-Grey, #575757)", fontFamily: "IBM Plex Sans", fontSize: "12px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }}>Suggested Search</p>
              {suggestions.map((element, index) => (
                <div
                  key={index}
                  style={{
                    padding: "5px 24px 5px 24px",
                    color: "var(--Default-Grey, #575757)",
                    cursor: "pointer",
                    fontFamily: "IBM Plex Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal"
                  }}
                  className="search-suggestion"
                  onClick={() => {
                    processSelectedData(element);
                  }}
                >
                  {element}
                </div>
              ))}
            </div>
          )}

          <div style={{ height: "100%", display: "flex", alignItems: "center", position: "absolute", top: 0, right: "70px" }}>
            <div style={{
              borderLeft: "1px solid grey",
              height: "60%",
            }}>
            </div>
          </div>

          <div className="btn-voice" style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}>
            <div onClick={handleChangeListening} className="centered"
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "#2D3A90",
                zIndex: 99,
                borderRadius: "60px"
              }}>
              <img src={micIcon} style={{ height: "1.5rem" }} />
            </div>
          </div>
        </div>
      </Row>

      <ConfirmAlert
        failedTitle="Pemberitahuan"
        failedInfo="Browser yang anda gunakan tidak support speech recognation"
        showFailed={!isSupportSpeechRecognition}
        response={() => {
          setIsSupportSpeechRecognition(true);
        }}
      />
    </>
  );
}

export default SearchInput;
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { ConfirmAlert } from "../../../helpers/ui/alert";
import CustomSelect from "../../../helpers/ui/CustomSelect"
import DatePicker from "../../../helpers/ui/DatePicker"
import apiResponse from "../../../services/apiResponse";
import EmployeeSelect from "../Components/EmployeeSelect";
import { MeetingContext } from "../Context/MeetingContext";
import CreateBloc from "./Blocs/CreateBloc";

class IndexView extends Component {
  static contextType = MeetingContext;
  createBloc = new CreateBloc();

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {},
      meetingType: null,
      meetingTypeOtions: [
        {
          label: "Online",
          value: "1"
        },
        {
          label: "Offline",
          value: "2"
        }
      ],
      externalParticipants: [
        {
          name: null,
          email: null
        }
      ]
    };
  }

  submitData = async () => {
    const { dataForm } = this.context;
    const submitForm = {
      ...dataForm,
      meeting_participants_ext: this.state.externalParticipants,
    }

    await this.createBloc.fetchCreate(submitForm);
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;

    e.preventDefault();
    setDataForm({
      ...dataForm,
      ...values,
    });
    this.setState({
      confirm: true,
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.createBloc.createChannel.unsubscribe();
  }

  renderEmployeeSelect = (internal) => {
    return <EmployeeSelect internal={internal} />;
  };

  addParticipant = () => {
    this.setState({
      externalParticipants: [
        ...this.state.externalParticipants, {
          name: null,
          email: null
        }
      ],
    });
  }

  removeParticipant = (indexToRemove) => {
    this.setState((prevState) => {
      const updatedParticipants = prevState.externalParticipants.filter(
        (_, index) => index !== indexToRemove
      );
      return { externalParticipants: updatedParticipants };
    });
  };

  updateParticipantName = (index, newName) => {
    this.setState((prevState) => {
      const updatedParticipants = [...prevState.externalParticipants]; // Create a copy of the array
      updatedParticipants[index] = {
        ...updatedParticipants[index],
        name: newName, // Modify the name property for the specific index
      };
      return { externalParticipants: updatedParticipants };
    });
  };

  updateParticipantEmail = (index, newName) => {
    this.setState((prevState) => {
      const updatedParticipants = [...prevState.externalParticipants]; // Create a copy of the array
      updatedParticipants[index] = {
        ...updatedParticipants[index],
        email: newName, // Modify the name property for the specific index
      };
      return { externalParticipants: updatedParticipants };
    });
  };

  renderCardForm = () => {
    return (
      <Card>
      <CardBody>
        <CardTitle>Registrasi Rapat</CardTitle>
        <CardSubtitle className="mb-3">
          Registrasi rapat dengan mengisi form di bawah ini
        </CardSubtitle>
        <hr />
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
          onKeyPress={this.preventEnter}
        >
          <Row className="mb-3">
            <label className="col-md-3 col-form-label">
              Nama Rapat <span className="text-danger">*</span>
            </label>
            <Col md="9">
              <AvField
                name="meeting_registration_name"
                placeholder="Nama Rapat"
                type="text"
                errorMessage="Masukkan nama rapat"
                className="form-control"
                validate={{ required: { value: true } }}
                id="meeting_registration_name"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <label
              className="col-md-3 col-form-label"
            >
              Tanggal Rapat <span className="text-danger">*</span>
            </label>
            <Col md="3">
                <AvField
                  name="meeting_registration_date"
                  type="date"
                  errorMessage="Masukkan tanggal rapat"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="meeting_registration_date"
                />
            </Col>
          </Row>
          <Row className="mb-3">
            <label
              className="col-md-3 col-form-label"
            >
              Jam Mulai <span className="text-danger">*</span>
            </label>
            <Col md="3">
              <AvField
                  name="meeting_registration_start_time"
                  type="time"
                  errorMessage="Masukkan jam mulai rapat"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="meeting_registration_start_time"
                /> 
            </Col>
            <label
              className="col-md-2 col-form-label"
            >
              Jam Selesai <span className="text-danger">*</span>
            </label>
            <Col md="3">
              <AvField
                  name="meeting_registration_end_time"
                  type="time"
                  errorMessage="Masukkan jam selesai rapat"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="meeting_registration_end_time"
                /> 
            </Col>
          </Row>
          <Row className="mb-3">
            <label
              className="col-md-3 col-form-label"
            >
              Jenis Rapat <span className="text-danger">*</span>
            </label>
            <Col md="9">
              <CustomSelect
                name="meeting_registration_type"
                id="meeting_registration_type"
                errorMessage="Silahkan pilih jenis rapat"
                options={this.state.meetingTypeOtions}
                onChange={(o) => this.setState({ meetingType: o.value})}
                validate={{ required: { value: true } }}
                placeholder="Pilih tipe rapat" />
            </Col>
          </Row>
         
         { this.state.meetingType == "2" ? (
          <Row className="mb-3">
          <label
            htmlFor="agenda_location"
            className="col-md-3 col-form-label"
          >
            Lokasi Rapat <span className="text-danger">*</span>
          </label>
          <Col md="9">
            <AvField
              name="meeting_registration_place"
              placeholder="Lokasi Rapat"
              type="text"
              errorMessage="Masukkan lokasi rapat"
              className="form-control"
              validate={{ required: { value: true } }}
              id="meeting_registration_place"
            />
          </Col>
        </Row>   
         ) : null}
          
        { this.state.meetingType == "1" ? (
          <Row className="mb-3">
            <label
              htmlFor="agenda_location"
              className="col-md-3 col-form-label"
            >
              Link Rapat <span className="text-danger">*</span>
            </label>
            <Col md="9">
              <AvField
                name="meeting_registration_link"
                placeholder="Link Rapat"
                type="text"
                errorMessage="Masukkan link rapat"
                className="form-control"
                validate={{ required: { value: true } }}
                id="meeting_registration_link"
              />
            </Col>
          </Row>  
        ) : null }

          <Row className="mb-3">
            <label
              htmlFor="agenda_location"
              className="col-md-3 col-form-label"
            >
              Partisipan (Internal)
            </label>
            <Col md="9">{this.renderEmployeeSelect(true)}</Col>
          </Row>

          <Row className="mb-3">
            <label
              htmlFor="agenda_participants_ext"
              className="col-md-3 col-form-label"
            >
              Partisipan (External)
            </label>
            {/* <Col md="9">{this.renderEmployeeSelect(false)} <p style={{ color:"red" }}>*Masukan email partisipan</p></Col> */}
            <Col md="9">
              {this.state.externalParticipants.map((participant, index) => {
                return <Row>
                <Col md="5">
                  <AvField
                    name={"meeting_participant_name_"+ index}
                    placeholder="Nama Partisipan"
                    type="text"
                    value={participant["name"]}
                    // errorMessage="Masukkan nama partisipan"
                    className="form-control"
                    // validate={{ required: { value: true } }}
                    id={"meeting_participant_name_"+ index}
                    onChange={(event) => this.updateParticipantName(index, event.target.value)}
                  />
                </Col>
                <Col md="5">
                  <AvField
                    name={"meeting_participant_email_"+ index}
                    placeholder="Email Partisipan"
                    type="email"
                    value={participant["email"]}
                    // errorMessage="Masukkan email partisipan"
                    className="form-control"
                    validate={{
                      // required: { value: true, errorMessage: 'Masukkan email partisipan' },
                      email: {
                        value: true,
                        errorMessage: 'Format email tidak valid', // Custom error message for invalid email format
                      },
                    }}
                    id={"meeting_participant_email_"+ index}
                    onChange={(event) => this.updateParticipantEmail(index, event.target.value)}
                  />
                </Col>
                {index == 0 ? (
                  <Col md="2">
                    <Button color="success" onClick={() => this.addParticipant() }>
                      <i className="uil-plus"></i>
                    </Button>
                  </Col>
                ) : (
                  <Col md="2">
                    <Button color="danger" onClick={() => this.removeParticipant(index) }>
                      <i className="uil-trash"></i>
                    </Button>
                  </Col>
                )}
              </Row>
              })}
            </Col>
          </Row>
          <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <div className="form-check form-switch mb-3">
                  <AvCheckboxGroup
                    name="checkboxExample"
                    required
                    errorMessage="Agreement harus di centang"
                  >
                    <AvCheckbox
                      label="Saya yakin dan bertanggung jawab atas data ini"
                      value="1"
                    />
                  </AvCheckboxGroup>
                </div>
              </Col>
            </Row>
          <Row className="mb-3">
            <Col md="3"></Col>
            <Col md="9">
              <Link to="/admin/meeting_registration.html">
                <Button color="danger">
                  <i className="uil-arrow-left"></i> Kembali
                </Button>
              </Link>{" "}
              <Button color="success" type="submit">
                <i className="uil-save"></i> Simpan
              </Button>
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card>
    );
  };

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col className="col-12">{this.renderCardForm()}</Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.redirect ? <Redirect to="/admin/meeting_registration.html" /> : null}
      </>
    );
  }
}

export default IndexView;

import moment from "moment";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import apiResponse from "../../../services/apiResponse";
import DetailBloc from "./Blocs/DetailBloc";
import Wysiwyg from "./Components/Wysiwyg";
import MinioClient from "../../../services/minioClient";
import { Icon } from "@iconify/react";

class IndexView extends Component {
  detailBloc = new DetailBloc();
  minio = new MinioClient();
  userdata = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchAttachment = async (path) => {
    const attachment = await this.minio.get(path).then(
      (res) => res,
      (err) => err
    );

    this.setState({
      detail: {
        ...this.state.detail,
        attachment_uri: attachment,
      },
    });
  };

  renderAttachment = (uri) => {
    const fileFormat = this.state.detail.attachment_name
      ? this.state.detail.attachment_name.split(".")[1]
      : null;
    let icon, size, mod;

    switch (fileFormat) {
      case "docx" || "doc":
        icon = "vscode-icons:file-type-word";
        break;
      case "pdf":
        icon = "vscode-icons:file-type-pdf";
        break;
      case "xls" || "xlsx":
        icon = "vscode-icons:file-type-excel";
        break;
      case "txt":
        icon = "vscode-icons:file-type-text";
        break;
      default:
        break;
    }

    if (this.state.detail.attachment_size) {
      size = parseInt(this.state.detail.attachment_size);
      switch (true) {
        case size > 900 && size < 899999:
          size /= 1000;
          mod = "KBytes";
          break;
        case size > 900000 && size < 899999999:
          size /= 1000000;
          mod = "MBytes";
          break;
        case size > 900000000:
          size /= 1000000000;
          mod = "GBytes";
          break;
        default:
          mod = "Bytes";
          break;
      }
    }

    return (
      <>
        {typeof uri === "string" ? (
          <a
            href={uri}
            target="_blank"
            rel="noreferrer"
            className="d-flex"
            style={{
              columnGap: ".75rem",
              width: "fit-content",
              padding: ".5rem",
              border: "1px solid #ddd",
              borderRadius: "6px",
            }}
          >
            <Icon icon={icon} style={{ width: "2rem", height: "auto" }} />
            <div
              style={{
                width: "fit-content",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>{this.state.detail.attachment_name}</div>
              <div style={{ color: "#999" }}>{`${size.toFixed(1)} ${mod}`}</div>
            </div>
          </a>
        ) : (
          <span
            className="d-flex"
            style={{
              columnGap: ".75rem",
              width: "fit-content",
              padding: ".5rem",
              border: "1px solid #ddd",
              borderRadius: "6px",
            }}
          >
            <em>Tidak ada lampiran.</em>
          </span>
        )}{" "}
      </>
    );
  };

  componentDidMount() {
    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: response,
            });
            this.fetchAttachment(this.state.detail.attachment_path);
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ uuid: queryParams.get("uuid"), employee_uuid: this.userdata.employee_uuid});
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Detail Pengumuman</CardTitle>
                  <CardSubtitle className="mb-3">
                    Keterangan detil mengenai pengumuman
                  </CardSubtitle>
                  <hr />

                  <Row className="mb-3">
                    <label className="col-md-3">Dibuat pada</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.created_at !== ""
                            ? moment(this.state.detail.created_at).format(
                                "LLLL"
                              )
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "40%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Diperbarui pada</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.updated_at !== ""
                            ? moment(this.state.detail.updated_at).format(
                                "LLLL"
                              )
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Dibuat oleh</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.announcement_created_by_name !== ""
                            ? this.state.detail.announcement_created_by_name
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Judul Pengumuman</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.announcement_title !== "" ? (
                            <strong>
                              {this.state.detail.announcement_title}
                            </strong>
                          ) : (
                            "-"
                          )}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: 68, height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Isi pengumuman</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <Wysiwyg
                          readOnly={true}
                          defaultValue={this.state.detail.announcement_content_html}
                        />
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Lampiran</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.attachment_path !== ""
                            ? this.renderAttachment(
                                this.state.detail.attachment_uri
                              )
                            : this.renderAttachment(false)}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="3"></Col>
                    <Col md="9">
                      <Link to="pengumuman.html" className="waves-effect">
                        <Button color="danger">
                          <i className="uil-arrow-left"></i> Kembali
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

//export default IndexView
export default withRouter(IndexView);

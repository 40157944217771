import React, { useState, useEffect } from "react";
import avatarMale from "../../../../../assets/images/avatar_male.png";
import avatarFemale from "../../../../../assets/images/avatar_female.png";
import { Col} from "reactstrap";
import iconBell from '../../../../../assets/icons/bell.png'
import { authContext } from "../../../../../services/adalConfig";
import ProfileDropdown from "./Components/ProfileDropdown";
import NotificationDropDown from "./Components/NotificationDropDown";

function Profile() {

    const userData = JSON.parse(localStorage.getItem("userdata"));

    return (
        <div className="centered-vertical" style={{ width: "auto", height: "36px", borderRadius: "35px", background: "#FFFFFF", margin: "30px",  position: "fixed", top: 0, right: 0,  boxShadow: "15px 14px 28px -3px rgba(48, 142, 209, 0.17)", backdropFilter: "blur(7.5px)", zIndex: 99 }}>
          <Col>
                <NotificationDropDown/>
                {/* <img src={iconBell} style={{ width: "16px", height: "16px", marginRight: "17px"}} />   */}
                <ProfileDropdown/>
          </Col>
        </div>
    )
}

export default Profile;
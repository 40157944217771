import { createRef } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import idLocale from "@fullcalendar/core/locales/id";
import moment from "moment";
import { isValidHttpUrl } from "../../../../../helpers/utility";
import { Link } from "react-router-dom";
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import React, { Component } from "react";
import Lottie from "react-lottie";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Modal,
  Row,
  Container,
} from "reactstrap";
import dataLoad from "../../../../../assets/lotties/spinner.json";
import { config } from "../../../../../helpers/config_global";
import { ConfirmAlert } from "../../../../../helpers/ui/alert";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import EmployeeSelect from "../EmployeeSelect";
import AgendaBloc from "../../Blocs/AgendaBloc";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { Icon } from "@iconify/react";
import {
  FileInput,
  generateFormData,
} from "../../../../../helpers/ui/FileInput";
import MinioClient from "../../../../../services/minioClient";
import "./style.scss";

class AgendasCalendar extends Component {
  static contextType = GlobalContext;

  agendaBloc = new AgendaBloc();
  userData = JSON.parse(localStorage.getItem("userdata"));
  minio = new MinioClient();

  source;
  defaultOrder = "agenda_start_date";
  defaultSize = 10;
  defaultSort = "desc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;

    e.preventDefault();
    setDataForm({
      agenda_uuid: this.state.selectedAgendaUuid,
      ...dataForm,
      ...values,
    });

    this.setState({
      confirm: true,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      loadingAgendas: true,
      filter: {
        filter_value: "",
        page_number: 1,
        page_size: this.defaultSize,
        sort_order: {
          column: this.defaultOrder,
          order: this.defaultSort,
        },
      },
      agendas: [],
      dataForm: {},
      detail: {},
      showModalPreview: false,
      participants: [],
      participants_ext: [],
    };

    this.calendarRef = createRef(null);
  }

  toggleModalPreview = () => {
    this.setState({
      showModalPreview: !this.state.showModalPreview,
    });
  };

  handleEventClick = (arg) => {
    this.toggleModalPreview();
    this.setState({
      ...this.state,
      selectedAgendaUuid: arg.event.id,
    });
    this.loadAgenda(arg.event.id);

    this.hidePopover()
  };

  hidePopover = () => {
    const popovers = document.querySelectorAll('.fc-popover');
    popovers.forEach(popover => {
      popover.style.display = 'none';
    });
  }

  fetchAttachment = async (path) => {
    const attachment = await this.minio.get(path).then(
      (res) => res,
      (err) => err
    );

    this.setState({
      detail: {
        ...this.state.detail,
        attachment_uri: attachment,
      },
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    const query = {
      search: null,
      limit: this.state.filter.page_size,
      offset: null,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      nip: this.userData.employee_nip,
      status: 1,
    };
    await this.agendaBloc.fetchList(query, this.source.token);
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;

    e.preventDefault();
    setDataForm({
      agenda_uuid: this.state.selectedAgendaUuid,
      ...dataForm,
      ...values,
    });

    this.setState({
      confirm: true,
    });
  };

  submitData = async () => {
    const { dataForm } = this.context;
    const formData = generateFormData(dataForm, {
      field: "attachment_detail",
      name: "agendaAttachments/attachment",
    });
    // for testing
    // await this.minioClient.put("Agenda/attachment_detail", this.state.dataForm["attachment_detail"]);

    // Create new row for the attachment
    await this.agendaBloc.fetchNotulensi(formData);

    // If success creating new attachment then bind to `agendas` table `agenda_attachment` column
    // if (this.state.detail && this.state.detail.agenda_uuid) {
    //   await this.updateBloc.fetchUpdate({
    //     agenda_uuid: this.state.detail.agenda_uuid,
    //     agenda_attachment: this.state.detail.agenda_uuid,
    //   });
    // }
  };

  refetchCalendar = async () => {
    const calendarApi = this.calendarRef.current.getApi();

    this.setTokenAPI();
    calendarApi.refetchEvents();
  };

  componentDidMount() {
    this.setTokenAPI();

    this.agendaBloc.listChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingAgendas: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingAgendas: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            totalAgendas: response.total,
            agendas: response.result,
            calendarAgendas: response.result.map((agenda) => {
              return {
                id: agenda.agenda_uuid,
                title: agenda.agenda_name,
                start: `${agenda.agenda_start_date}T${agenda.agenda_start_time}`,
                end: `${agenda.agenda_end_date}T${agenda.agenda_end_time}`,
              };
            }),
            loadingAgendas: false,
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.agendaBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: response,
              participants: response.agenda_participants.map((participant) => {
                return {
                  label: participant.employee_name,
                  value: participant.employee_nip,
                };
              }),
              participants_ext: response.agenda_participants_ext.map(
                (participant) => {
                  return {
                    value: participant.ext_participant_name,
                  };
                }
              ),
            });
            this.fetchAttachment(this.state.detail.attachment_path);
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.agendaBloc.notulensiChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            loading: false,
            success: true,
            detail: {
              ...this.state.detail,
              agenda_attachment: response.attachment_uuid,
            },
          });
          setTimeout(() => {
            this.setState({
              ...this.state,
              showModalPreview: false,
            });
            this.setTokenAPI();
          }, 1500);

          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.refresh !== prevProps.refresh){
      this.refetchCalendar();
    }
  }

  componentWillUnmount() {
    this.agendaBloc.listChannel.unsubscribe();
    this.agendaBloc.detailChannel.unsubscribe();
    this.agendaBloc.notulensiChannel.unsubscribe();
  }

  loadAgenda = (agenda_uuid) => {
    this.agendaBloc.fetchDetail({ uuid: agenda_uuid });
  };

  toggleModalPreview = () => {
    this.setState({
      showModalPreview: !this.state.showModalPreview,
      submitType: !this.state.showModalPreview ? "update" : "",
    });
  };

  renderCardPreview = () => {
    return (
      <div className="p-4" style={{ maxHeight: "85vh", overflowY: "auto" }}>
        <Row>
          <Col className="col-12">
            <CardTitle>Detail Agenda</CardTitle>
            <CardSubtitle className="mb-3">
              Keterangan detail mengenai agenda
            </CardSubtitle>
            <hr />
            <Row className="mb-3">
              <label className="col-md-3">Nama Agenda</label>
              <Col md="9">
                {this.state.detail ? (
                  <>
                    {this.state.detail.agenda_name !== ""
                      ? this.state.detail.agenda_name
                      : "-"}
                  </>
                ) : (
                  <Shimmer>
                    <div style={{ width: 68, height: 15 }}></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label className="col-md-3">Mulai</label>
              <Col md="9">
                {this.state.detail ? (
                  <>
                    {this.state.detail.agenda_start_date
                      ? `${moment(this.state.detail.agenda_start_date).format("dddd, D MMMM YYYY")} pukul ${
                          this.state.detail.agenda_start_time ? moment(`${this.state.detail.agenda_start_date}T${this.state.detail.agenda_start_time}`).format("HH:mm") : "-"
                        }`
                      : "-"}
                  </>
                ) : (
                  <Shimmer>
                    <div style={{ width: "40%", height: 15 }}></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label className="col-md-3">Selesai</label>
              <Col md="9">
                {this.state.detail ? (
                  <>
                    {this.state.detail.agenda_end_date
                      ? `${moment(this.state.detail.agenda_end_date).format("dddd, D MMMM YYYY")} pukul ${
                          this.state.detail.agenda_end_time ? moment(`${this.state.detail.agenda_end_date}T${this.state.detail.agenda_end_time}`).format("HH:mm") : "-"
                        }`
                      : "-"}
                  </>
                ) : (
                  <Shimmer>
                    <div style={{ width: "80%", height: 15 }}></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label className="col-md-3">Keterangan</label>
              <Col md="9">
                {this.state.detail ? (
                  <>
                    {this.state.detail.agenda_description !== ""
                      ? this.state.detail.agenda_description
                      : "-"}
                  </>
                ) : (
                  <Shimmer>
                    <div style={{ width: "80%", height: 15 }}></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <label className="col-md-3">Lokasi / URL</label>
              <Col md="9">
                {this.state.detail ? (
                  <>
                    {this.state.detail.agenda_location !== "" ? (
                      isValidHttpUrl(this.state.detail.agenda_location) ? (
                        <a
                          href={this.state.detail.agenda_location}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {this.state.detail.agenda_location}
                        </a>
                      ) : (
                        this.state.detail.agenda_location
                      )
                    ) : (
                      "-"
                    )}
                  </>
                ) : (
                  <Shimmer>
                    <div style={{ width: "80%", height: 15 }}></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              {this.state.participants.length > 0 ? (
                <>
                  <label className="col-md-3">Partisipan (Internal)</label>
                  <Col md="9">
                    {this.state.detail && this.state.participants ? (
                      <>
                        <ul
                          className="list-group"
                          style={{
                            maxHeight: "9rem",
                            overflowY: "auto",
                          }}
                        >
                          {this.state.participants.length > 0 &&
                            this.state.participants.map((participant, idx) => {
                              return (
                                <li
                                  className="list-group-item party-item"
                                  key={`party-${idx}`}
                                >
                                  <span className="mr-2">{`${idx + 1}. ${
                                    participant.label
                                  }`}</span>
                                  <p className="text-secondary m-0">
                                    {participant.value}
                                  </p>
                                </li>
                              );
                            })}
                        </ul>
                      </>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "80%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </>
              ) : null}
            </Row>
            <Row className="mb-3">
              {this.state.participants_ext.length > 0 ? (
                <>
                  <label className="col-md-3">Partisipan (External)</label>
                  <Col md="9">
                    {this.state.detail && this.state.participants_ext ? (
                      <>
                        <ul
                          className="list-group"
                          style={{
                            maxHeight: "9rem",
                            overflowY: "auto",
                          }}
                        >
                          {this.state.participants_ext.length > 0 &&
                            this.state.participants_ext.map(
                              (participant, idx) => {
                                return (
                                  <li
                                    className="list-group-item party-item"
                                    key={`party-${idx}`}
                                  >
                                    <span className="mr-2">{`${idx + 1}. ${
                                      participant.value
                                    }`}</span>
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </>
                    ) : (
                      <Shimmer>
                        <div style={{ width: "80%", height: 15 }}></div>
                      </Shimmer>
                    )}
                  </Col>
                </>
              ) : null}
            </Row>
            <Row className="mb-3">
              <label className="col-md-3">Notulensi</label>
              <Col md="9">
                {this.state.detail ? (
                  <>
                    {this.state.detail.attachment_path !== ""
                      ? this.renderAttachment(this.state.detail.attachment_uri)
                      : this.renderAttachment(false)}
                  </>
                ) : (
                  <Shimmer>
                    <div style={{ width: "80%", height: 15 }}></div>
                  </Shimmer>
                )}
              </Col>
            </Row>
            {this.state.detail ? (
              <>
                {this.state.detail.attachment_path ? null : (
                  <AvForm
                    onValidSubmit={(e, values) =>
                      this.handlerOnSubmit(e, values)
                    }
                  >
                    <Row>
                      <label className="col-md-3">Tambah Notulensi</label>
                      <Col md={9}>
                        <FileInput
                          name="attachment_detail"
                          accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        md="12"
                        className="p-2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          columnGap: "0.5rem",
                        }}
                      >
                        <Button
                          color="danger"
                          onClick={this.toggleModalPreview}
                        >
                          <i className="uil-arrow-left"></i> Kembali
                        </Button>
                        <Button color="success" type="submit">
                          <i className="uil-save"></i> Simpan Perubahan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                )}
              </>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };

  renderAttachment = (uri) => {
    const fileFormat = this.state.detail.attachment_name
      ? this.state.detail.attachment_name.split(".")[1]
      : null;
    let icon, size, mod;

    switch (fileFormat) {
      case "docx" || "doc":
        icon = "vscode-icons:file-type-word";
        break;
      case "pdf":
        icon = "vscode-icons:file-type-pdf";
        break;
      case "xls" || "xlsx":
        icon = "vscode-icons:file-type-excel";
        break;
      case "txt":
        icon = "vscode-icons:file-type-text";
        break;
      default:
        break;
    }

    if (this.state.detail.attachment_size) {
      size = parseInt(this.state.detail.attachment_size);
      switch (true) {
        case size > 900 && size < 899999:
          size /= 1000;
          mod = "KBytes";
          break;
        case size > 900000 && size < 899999999:
          size /= 1000000;
          mod = "MBytes";
          break;
        case size > 900000000:
          size /= 1000000000;
          mod = "GBytes";
          break;
        default:
          mod = "Bytes";
          break;
      }
    }

    return (
      <>
        {typeof uri === "string" ? (
          <a
            href={uri}
            target="_blank"
            rel="noreferrer"
            className="d-flex attachment-container"
            style={{
              columnGap: ".75rem",
              width: "fit-content",
              padding: ".5rem",
              border: "1px solid #ddd",
              borderRadius: "6px",
            }}
          >
            <Icon icon={icon} style={{ width: "2rem", height: "auto" }} />
            <div
              style={{
                width: "fit-content",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>{this.state.detail.attachment_name}</div>
              <div style={{ color: "#999" }}>{`${size.toFixed(1)} ${mod}`}</div>
            </div>
          </a>
        ) : (
          <span
            className="d-flex"
            style={{
              columnGap: ".75rem",
              width: "fit-content",
              padding: ".5rem",
              border: "1px solid #ddd",
              borderRadius: "6px",
            }}
          >
            <em style={{ fontStyle: "italic" }}>Tidak ada notulensi.</em>
          </span>
        )}{" "}
      </>
    );
  };

  renderLoading = () => {
    return (
      <div
        style={{
          minWidth: "100%",
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "65vh",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: dataLoad,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={120}
            width={120}
          />
          <span>Sedang mengambil data...</span>
        </div>
      </div>
    );
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  renderEventContent = (eventInfo) => {
    console.log(eventInfo)
    var eventStartTime = eventInfo.event.start;
    var eventEndTime = eventInfo.event.end;
    var formattedStartTime = null;
    var formattedEndTime = null

    if (eventStartTime != null) {
      formattedStartTime = eventStartTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});
    }

    if (eventEndTime != null) {
      formattedEndTime = eventEndTime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false});    
    }
  
    return (
      <>
        <b className="me-1">{formattedStartTime ?? "- "} sampai {formattedEndTime ?? "- "} </b>
        <i>({eventInfo.event.title})</i>
      </>
    )
  }

  renderMoreLinkContent = (arg) => {
    return (
      <div style={{ 
        display: "inline-block",
        padding: "0.25em 0.5em",
        fontSize: "1em",
        fontWeight: "bold",
        lineHeight: "1",
        color: "#fff",
        backgroundColor: "#007bff",
        borderRadius: "0.25rem"
       }}>
        {arg.num}
      </div>
    )
  }

  render() {
    return (
      <>
        {/* <button
          type="button"
          className="btn btn-primary mb-3 d-flex mt-4"
          onClick={this.refetchCalendar}
          style={{ alignItems: "center", columnGap: ".5rem" }}
        >
          <Icon icon="icon-park-outline:refresh" />
          <span>Refresh</span>
        </button> */}
        {this.state.calendarAgendas ? (
          <Card className="p-3 mt-4">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              dateClick={this.handleDateClick}
              events={this.state.calendarAgendas}
              eventClick={this.handleEventClick}
              select={this.handleDateSelect}
              selectable={true}
              locale={idLocale}
              ref={this.calendarRef}
              height="30rem"
              eventContent={this.renderEventContent}
              moreLinkContent={this.renderMoreLinkContent}
              dayMaxEvents={true}
              displayEventEnd={true}
              displayEventTime={true}
            />
          </Card>
        ) : (
          this.renderLoading()
        )}

        <Modal
          centered
          size="lg"
          toggle={this.toggleModalPreview}
          isOpen={this.state.showModalPreview}
          backdrop={true}
        >
          {this.state.showModalPreview
            ? this.renderCardPreview()
            : this.renderLoading()}
        </Modal>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
      </>
    );
  }
}

export default AgendasCalendar;

import React, { useState, useEffect } from "react";
import "./style.scss";
import listenGif from "../../../../../assets/videos/listening.gif"
import logoFull from '../../../../../assets/images/logo-full.png'
import { Col, Row } from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Profile from "../Profile/Profile";
import FuzzySet from 'fuzzyset';
import * as tf from "@tensorflow/tfjs";
import { Stemmer } from 'sastrawijs';
import Lottie from 'lottie-web';
import listeningAnimation from "../../../../../assets/lotties/listening.json";
import {useHistory} from 'react-router-dom';
import SearchBloc from "./Blocs/SearchBloc";

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
/>

function SearchView(props) {

  const searchBloc = new SearchBloc;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [inputSentence, setInputSentence] = useState('');
  const [loadedModel, setLoadedModel] = useState(null);
  const stemmer = new Stemmer();
  const [isListening, setIsListening] = useState(false);

  const keywords = props.keywords;
  const candidates = keywords.map((item) => item.nlp_keyword_value.split(" "));
  const indonesianWords = [].concat(...candidates);
  const fuzzy = FuzzySet(indonesianWords);

  const navigate = useHistory();

  const animationContainer = document.getElementById('lottie-container'); // Replace with your container element
  const anim = Lottie.loadAnimation({
    container: animationContainer,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: listeningAnimation,
  });

  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const fetchSuggestions = (word) => {
    if (word != null) {
      const fuzzyResults = fuzzy.get(word);
      const autocorrectSuggestions = fuzzyResults ? (fuzzyResults[0][0] > 0.5 ? fuzzyResults[0][1] : word) : word;
      return autocorrectSuggestions;
    }
  };

  useEffect(() => {
    // Load the trained model
    async function loadModel() {
      const modelPath = '/model.json';
      const model = await tf.loadLayersModel(modelPath);
      setLoadedModel(model);
    }
    loadModel();
    // greeting()
  }, []);

  const greeting = () => {
    const speech = new SpeechSynthesisUtterance(`Hai ${userData.employee_name.split(",")[0]}, ada yang bisa saya bantu?`); 
    speech.lang = 'id';
    window.speechSynthesis.speak(speech);
  }

  const handlePredict = () => {
    if (!loadedModel) return;

    const reference = stemmer.stem(inputSentence)
      .split(' ').map((word) => fetchSuggestions(word));

    const bleuScores = candidates.map(candidate => {
      const candidateString = candidate.join(" ");
      const candidateTokens = candidateString.split(' ');
      const precision = calculateUnigramPrecision(reference, candidateTokens);
      const brevityPenalty = calculateBrevityPenalty(reference.length, candidateTokens.length);
      const bleu = precision * brevityPenalty;
      return bleu;
    });

    console.log("bleu_score: "+ bleuScores)

    const maxBleuScore = Math.max(...bleuScores);
    console.log("max: "+ maxBleuScore)
    const normalizedBleuScores = maxBleuScore === 0 ? new Array(candidates.length).fill(0) : bleuScores.map(score => score / maxBleuScore);
    console.log("normalizedBleuScores: "+normalizedBleuScores)

    // Predict probabilities using the loaded model
    const tensorInput = tf.tensor(normalizedBleuScores);
    const predictedProbabilityTensor = loadedModel.predict(tensorInput);
    console.log("predictedProbabilityTensor: "+predictedProbabilityTensor)



    const predictedProbability = predictedProbabilityTensor.dataSync()[0];
    console.log("predictedProbability: "+predictedProbability)
    const predictedBestCandidateIndex = predictedProbability > 0.5
      ? normalizedBleuScores.indexOf(Math.max(...normalizedBleuScores))
      : -1;

    console.log("predictedBestCandidateIndex: "+ predictedBestCandidateIndex)

    const predictedBestCandidate = predictedBestCandidateIndex !== -1
      ? candidates[predictedBestCandidateIndex]
      : "None";

    console.log("predictedBestCandidate: "+ predictedBestCandidate)

    processSelectedValue(predictedBestCandidate.join(" "));
    props.searchRough(inputSentence)
  };

  // Calculate unigram (1-gram) precision
  function calculateUnigramPrecision(referenceTokens, candidateTokens) {
    const commonUnigrams = candidateTokens.filter(token => referenceTokens.includes(token));
    const precision = commonUnigrams.length / candidateTokens.length;
    return precision;
  }

  // Calculate brevity penalty
  function calculateBrevityPenalty(referenceLength, candidateLength) {
    if (candidateLength >= referenceLength) {
      return 1;
    } else {
      return Math.exp(1 - referenceLength / candidateLength);
    }
  }

  const processSelectedValue = (data) => {
    const bestData = keywords.find((item) => item.nlp_keyword_value === data);
    
    if (bestData == null) {
      // props.changeListening()
    }

    console.log(bestData["nlp_keyword_redirect_link"])
    if (bestData["nlp_keyword_redirect_link"] != null && bestData["nlp_keyword_redirect_link"] != "") {
      if (bestData["nlp_keyword_type"] == 2) {
        setInputSentence("")
        resetTranscript()

        props.changeListening()
        window.open(bestData["nlp_keyword_redirect_link"], "_blank");
      }else if (bestData["nlp_keyword_type"] == 1){
        setInputSentence("")
        resetTranscript()
        navigate.push(bestData["nlp_keyword_redirect_link"])
      }
    }
    
  }

  useEffect(() => {
    setInputSentence(transcript)
  }, [transcript]);

  useEffect(() => {
    setInputSentence(finalTranscript)
    if (finalTranscript != null && finalTranscript != "") {
      handlePredict()

      props.searchRough(finalTranscript)

      searchBloc.fetchCreate({
        "nlp_keyword_value": finalTranscript,
        "nlp_keyword_type": 1,
        "nlp_keyword_redirect_link": null,
        "nlp_keyword_status": 0
      })
    } else {
      // props.changeListening()
    }
  }, [finalTranscript]);

  useEffect(() => {
    startListen()
  }, [])


  const startListen = () => {
    SpeechRecognition.startListening({ language: 'id'})
  }

  SpeechRecognition.stop = () => {
    console.log('Recognition stopped');
  };

  useEffect(() => {

    if (isListening == true && listening == false && inputSentence == "") {
      setIsListening(false)
      // props.changeListening()
    }

    if(listening) {
      setIsListening(true)
    }
  }, [listening]);
  
  return (
    <>
      <div
        className="main"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >

        <div
          onClick={() => props.changeListening()}
          className="btn btn-primary d-flex align-items-center position-fixed"
          style={{
            height: "40px",
            borderRadius: "20px",
            margin: "20px",
            top: "20px",
            left: "20px",
            zIndex: 99,
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          <i className="fas fa-arrow-left me-2"></i> {/* Back arrow icon */}
          Kembali
        </div>

        <Profile/>

        <div className="centered" style={{ minHeight: "100vh", paddingTop: "5rem", paddingBottom: "5rem", paddingLeft: "2rem", paddingRight: "2rem"}}>
          <Col>
            <Row className="centered-horizontal">
              <img src={logoFull} style={{ width: "25rem", marginBottom: "5rem", opacity: 0.4}} />  
            </Row>
            {listening && (
              <Row className="centered-horizontal">
                <img src={listenGif} style={{ width: "15rem", marginBottom: "2rem"}} />  
              </Row>
            )}
            <Row  className="centered-horizontal" style={{ fontSize: "20px", fontStyle: "normal", fontWeight: 400 }}>
              Hi {userData.employee_name}, <br/>
              How can i help you?
            </Row>
            <div className="centered-horizontal" style={{ marginTop: "50px" }}>
              <div className="pt-2" style={{ width: "824px", height: "50px", borderBottom: "2px solid #DCDCDC"  }}>
                <input
                  type="text"
                  value={inputSentence}
                  onChange={(event)=>{}}  
                  style={{textAlign: "center", fontSize: "18px", width: "95%",  border: "none", outline: "none", borderRadius: "60px", marginBottom: "3rem", paddingLeft: "20px", paddingRight: "20px"}}
                />
                {!listening && (
                  <i className="fas fa-sync-alt" style={{ fontSize: '24px' }} onClick={startListen}/>
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </>
  );
}

export default SearchView;

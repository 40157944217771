import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import EmployeeRepository from "../Repositories/EmployeeRepository";

class EmployeeBloc {
  employeeChannel = new Subject();
  finishTourChannel = new Subject();
  repository = new EmployeeRepository();

  constructor() {
    this.employeeChannel.next({ status: apiResponse.INITIAL });
    this.finishTourChannel.next({ status: apiResponse.INITIAL });
  }

  fetchSearch = async (query, cancelToken, cb = null) => {
    this.employeeChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchSearch(query, cancelToken)
        .then((result) => {
          this.employeeChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
          if (cb) cb(result);
        })
        .catch((error) => {
          this.employeeChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchFinishTour = async (query) => {
    this.finishTourChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchFinishTour(query)
      .then((result) => {
        this.finishTourChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.finishTourChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default EmployeeBloc;

import { apiRequest, meetingRegistrationList, meetingRegistrationCancel } from "../../../../services/adapters/base";

class DataTableRepository {
  fetchList = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      meetingRegistrationList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  cancelMeetingRegistration = async (query, cancelToken) => {
    return await apiRequest(
			"post",
			meetingRegistrationCancel,
			{
				body: query,
			},
			true
		);
  };
}

export default DataTableRepository;

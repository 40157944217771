import React from "react"
import { Container, Row, Col } from "reactstrap"
import { config } from '../../helpers/config_global'

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © {config('appOwner').parent} / {config('appOwner').child}</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {config('appName').long}
              </div>
            </Col>
          </Row>
          {/* <Row>
              <Col xs={12} sm={12} md={12}>
                <p
                  className="pb-0 mb-0"
                >
                  Copyrights © 2024.
                  <a
                    href="/#"
                    style={{ fontWeight: 600 }}
                  >
                    {" "}
                    KEMENTERIAN INVESTASI DAN HILIRISASI/BKPM
                  </a>
                </p>
              </Col>
          </Row> */}
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer

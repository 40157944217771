import {
  apiRequest,
  ticketFetchPriority,
} from "../../../../services/adapters/base";

class PriorityRepository {
  fetchPriority = async (query) => {
    return await apiRequest(
      "post",
      ticketFetchPriority,
      {
        body: query,
      },
      true
    );
  };
}

export default PriorityRepository;

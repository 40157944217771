import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import AgendaRepository from "../Repositories/AgendaRepositories";

class AgendaBloc {
  listChannel = new Subject();
  detailChannel = new Subject();
  createChannel = new Subject();
  notulensiChannel = new Subject();
  repository = new AgendaRepository();

  constructor() {
    this.listChannel.next({ status: apiResponse.INITIAL });
    this.detailChannel.next({ status: apiResponse.INITIAL });
    this.createChannel.next({ status: apiResponse.INITIAL });
    this.notulensiChannel.next({ status: apiResponse.INITIAL });
  }

  fetchList = async (query, cancelToken) => {
    this.listChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchList(query, cancelToken)
        .then((result) => {
          this.listChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.listChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchCreate = async (query) => {
    this.createChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchCreate(query)
      .then((result) => {
        this.createChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.createChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchDetail = async (query) => {
    this.detailChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchDetail(query)
      .then((result) => {
        this.detailChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.detailChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchNotulensi = async (query) => {
    this.notulensiChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchNotulensi(query)
      .then((result) => {
        this.notulensiChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.notulensiChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default AgendaBloc;

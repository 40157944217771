import {
    apiRequest,
    keywordCreate
  } from "../../../../../../services/adapters/base";
  
  class SearchRepository {
   
    fetchCreate = async (query) => {
      return await apiRequest(
        "post",
        keywordCreate,
        {
          body: query,
        },
        true
      );
    };
  }
  
  export default SearchRepository;
  